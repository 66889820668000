import React from 'react';
import MaxAssessorsMessage from './MaxAssessorsMessage';
import ReleaseMessage from './ReleaseMessage';

const DashboardMessagesSection: React.FC = () => {
  return (
    <div className="p-mt-4 p-mb-4">
      <MaxAssessorsMessage />
      <ReleaseMessage />
    </div>
  );
};

export default DashboardMessagesSection;

import ErrorHandler from './errorResponseHandler';
import { axios } from './AuthApi';

class TenantApi {
  /** Fetch all data tenant */
  static getAllTenant() {
    return axios
      .get('/Tenant')
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  /** Fetch all data tenant names */
  static getAllTenantName() {
    return axios
      .get('/Tenant/names')
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  /** Get detail tenant */
  static getTenant(id) {
    return axios
      .get(`/Tenant/${id}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  /**
   *
   * @param {string} tenantId This is the tenantId
   * @param {string} key This is the key name to fetch
   * @returns
   */
  static getTenantKeys(tenantId, key) {
    return axios
      .get(`/Tenant/${tenantId}/tenantkeys?key=${key}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  /** Check Active Assessor */
  static checkActiveAssessor() {
    return axios
      .get(`/Tenant/checkActiveAssessor`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  /** Create new tenant */
  static createTenant(data) {
    const body = {
      tenantCode: data.tenantCode,
      tenantName: data.name,
      tenantEmail: data.email,
      tenantPhone: data.phone,
      // tenantLogo: data.logo,
      tenantLogo: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      contactPerson: data.contact,
    };

    return axios
      .post(`/Tenant`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /**
   * Create tenant key
   * @param {object} data This is the payload to send to the api
   * @returns
   */
  static createTenantKey(data) {
    const body = {
      key: data.key,
      tenantId: data.tenantId,
    };

    return axios
      .post(`/Tenant/${data.tenantId}/tenantkeys/${data.key}`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /**
   * Disable tenant key
   * @param {object} data This is the payload to send to the api
   * @returns
   */
  static disableTenantKey(data) {
    const body = {
      key: data.key,
      tenantId: data.tenantId,
      tenantKeyUuid: data.tenantKeyUuid,
    };

    return axios
      .post(`/Tenant/${data.tenantId}/tenantkeys/${data.tenantKeyUuid}/disable`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /**
   * Delete tenant key
   * @param {object} data This is the tenat of the key
   * @returns
   */
  static deleteTenantKey(data) {
    return axios
      .delete(`/Tenant/${data.tenantId}/tenantkeys/${data.tenantKeyUuid}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Update data Tenant
     * body params: 
        {
        "tenantName": "tenant 1",
        "tenantEmail": "tenant@tenant.com",
        "tenantPhone": "1234567890",
        "tenantLogo": "http://image/path",
        "contactPerson": "contact person"
        "maxActiveAssessors": "100"
        }
    */
  static updateTenant(id, data) {
    const body = {
      tenantId: data.tenantId,
      tenantCode: data.tenantCode,
      tenantName: data.name,
      tenantEmail: data.email,
      tenantPhone: data.phone,
      // tenantLogo: data.logo,
      tenantLogo: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      contactPerson: data.contact,
      isIntegration: data.isIntegration,
      isPublicAPIEnabled: data.isPublicAPIEnabled,
      maxActiveAssessors: parseInt(data.maxActiveAssessors),
    };

    return axios
      .put(`/Tenant/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** verify tenant data */
  static verifyTenant(id, token) {
    return axios
      .post(`/Tenant/${id}/verify/${token}`, {})
      .then((res) => {
        if (res.status === 200) {
          return {
            verified: true,
          };
        } else {
          return {
            verified: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Enabled tenant data
   *  params enable: boolean
   */
  static enableTenant(id, enable) {
    return axios
      .post(`/Tenant/${id}/${enable ? 'enable' : 'disable'}`, {})
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Get tenant configuration */
  static getTenantConfiguration(id) {
    return axios
      .get(`/Tenant/${id}/config`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  static updateTenantConfiguration(id, data) {
    const body = {
      tenantId: data.tenantId,
      assessmentExpiryPeriod: parseInt(data.assessmentExpiryPeriod),
      autoSaveDuration: parseInt(data.autoSaveDuration),
      apiKey: data.apiKey,
      inxIntegrationHostName: data.tenantHostname,
      onboardingInvitationExpiryPeriod: parseInt(data.onboardingInvitationExpiryPeriod),
      onboardingInvitationEmailSubject: data.onboardingInvitationEmailSubject,
      onboardingInvitationEmailBody: data.onboardingInvitationEmailBody,
      onboardingINXIntegrationHostName: data.onboardingINXIntegrationHostName,
      onboardingINXIntegrationApiKey: data.onboardingINXIntegrationApiKey,
      isOnboardingIntegration: data.isOnboardingIntegration,
      tenantCode: data.tenantCode,
      profileReportURLPrefix: data.profileReportURLPrefix,
    };
    return axios
      .put(`/Tenant/${id}/config`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateTenantWPMDailyEmailConfiguration(id, data) {
    const body = {
      wpmDailyEmailSubject: data.wpmDailyEmailSubject,
      wpmDailyEmailBody: data.wpmDailyEmailBody,
    };
    return axios
      .put(`/Tenant/${id}/wpmdailyemailconfig`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Fetch tenant sync person failed history data */
  static getSyncPersonFailedHistory() {
    return axios
      .get('/Tenant/failedSync')
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }
}

export default TenantApi;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../app/store/storeHooks';
//import { RouteChildrenProps } from 'react-router-dom';
import * as authActions from '../auth/actions';
import DashboardCardSection from './DashboardCardSection';
import Page from 'components/pages/Page';
import DashboardMessagesSection from './DashboardMessagesSection';
import withRouter from '../../util/withRouter';

interface IDashboardPageProps {
  history: any;
  location: any;
}

const DashboardPage: React.FC<IDashboardPageProps> = ({ history }) => {
  const isLogout = useAppSelector<any>((state) => state.auth.isLogout);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLogout) {
      dispatch(authActions.authCheckState());
    }
  }, [isLogout, dispatch]);

  const breadcrumbs = [
    {
      label: 'Dashboard',
      command: (event: any) => {
        if (event) history('/');
      },
    },
  ];

  return (
    <Page breadcrumbProps={breadcrumbs}>
      <DashboardMessagesSection />
      <DashboardCardSection />
    </Page>
  );
};

export default withRouter(DashboardPage);

import classNames from 'classnames';
import React, { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

interface IDashboardCardProps {
  id: string;
  title: string;
  detail: string;
  numbers?: string | number;
  onClick: (arg: any) => void;
  loading?: boolean;
}

const CardProgressSpinner = () => (
  <div style={{ width: '100%', textAlign: 'center' }}>
    <ProgressSpinner style={{ width: '80%' }} strokeWidth="2" />
  </div>
);

const DashboardCard: React.FC<IDashboardCardProps> = ({ id, loading, title, detail, numbers, onClick }) => {
  const [isActive, setIsActive] = useState(false);

  const cardClassname = classNames('summary', { card: !isActive, selectedCard: isActive });

  if (loading) {
    return <CardProgressSpinner />;
  }

  return (
    <div
      id={id}
      className={cardClassname}
      onClick={() => onClick({ id })}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      style={{ width: '100%' }}
    >
      <span className="title">{title}</span>
      <span className="detail">{detail}</span>
      <span className="numbers">{numbers}</span>
    </div>
  );
};

export default DashboardCard;

import React from 'react';
import packageJson from '../../../package.json';
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) {
      continue;
    }
    // console.log(`Condition While semverGreaterThan ${a > b || isNaN(b)}`);
    // console.log(`Condition 1 While semverGreaterThan ${a > b}`);
    // console.log(`Condition 2 While semverGreaterThan ${isNaN(b)}`);
    return a > b || isNaN(b);
  }
  return false;
};

/**
 * Concept
 *  - We want the site to load from the cache if there is no new version
 *  - We want to refresh the cache every time a new version of the app is deployed
 *  - We use npm package versioning to version our app and each deploy will be versioned incrementally
 *  - We generate a meta.json file along with every build in the public dir and it will act as a REST endpoint (won't be cached in browser)
 *  - We refresh cache (window.location.reload(true)) in the browser whenever a new version is released
 */
class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
          console.log('Cache variable: ', caches);
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            console.log('Cache names: ', names);
            for (let name of names) caches.delete(name);
          });
        }

        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          // console.log(`Current version - ${currentVersion}.`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          // console.log(`Current version - ${currentVersion}.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;

import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as tenantActions from '../../tenants/actions';
import Roles from '../../../domain/roles';
import { Message } from 'primereact/message';

const MaxAssessorsMessage: React.FC = () => {
  const activeAssessor: any = useSelector<any>((state) => state.tenant.activeAssessor);
  const tenantData: any = useSelector<any>((state) => state.tenant.tenant);

  const dispatch = useDispatch();
  const tenantAction: any = useMemo(() => bindActionCreators(tenantActions, dispatch), [dispatch]);

  const [userRoles] = useState(localStorage.getItem('roles')?.split(',') ?? []);
  const [tenantId] = useState(localStorage.getItem('tenantId'));
  const [activeAssessorsFetched, setActiveAssessorsFetched] = useState(false);

  const isAdmin = userRoles.includes(Roles.TrainingAdmin) || userRoles.includes(Roles.TenantAdmin);

  // Fetch tenant details to obtain the max assessor count
  useEffect(() => {
    if (isAdmin) {
      tenantAction.fetchActiveAssessor().then(() => {
        setActiveAssessorsFetched(true);
        tenantAction.fetchTenantDetail(tenantId);
      });
    }
  }, [isAdmin, tenantId, tenantAction]);

  const maxActiveAssessors = isAdmin && tenantData?.maxActiveAssessors ? tenantData.maxActiveAssessors : '-';

  return isAdmin && activeAssessorsFetched && activeAssessor < 1 ? (
    <Message text={`You have reached the maximum number of ${maxActiveAssessors} assessor accounts`} severity="warn" />
  ) : null;
};

export default MaxAssessorsMessage;

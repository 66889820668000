import asyncComponent from '../AsyncComponent';

// Lazy load Auth
export const AsyncLogin = asyncComponent(() => {
  return import('../../../features/auth/Login');
});

export const AsyncForgotPassword = asyncComponent(() => {
  return import('../../../features/auth-password/ForgotPassword');
});

export const AsyncResetPassword = asyncComponent(() => {
  return import('../../../features/auth-password/ResetPassword');
});

const itemLocal = {
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  expiresIn: localStorage.getItem('expiresIn'),
  username: localStorage.getItem('username'),
  tenant: localStorage.getItem('tenant'),
  tenantId: localStorage.getItem('tenantId'),
};

export default itemLocal;

import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';

interface IPageBreadCrumb {
  items: MenuItem[];
}

const PageBreadCrumb: React.FC<IPageBreadCrumb> = ({ items }) => {
  const navigate = useNavigate();

  return (
    <BreadCrumb
      model={items}
      home={{
        icon: 'pi pi-home',
        command: (event: any) => {
          if (event) navigate('/');
        },
      }}
      style={{
        marginBottom: '10px',
        border: '0px',
        backgroundColor: 'unset',
        padding: '5px 0',
      }}
    />
  );
};

export default PageBreadCrumb;

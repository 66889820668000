import axiosInstance from 'axios';
import AuthApi from './AuthApi';
import store from '../store';
import { authSuccess, authLogoutSuccess, authLogoutFail } from '../../features/auth/actions';

const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled ? false : true;
};

const CancelToken = axiosInstance.CancelToken;
let cancel;

const numbers = /[0-9]/g;

/** Error interceptor handler */
const errorHandler = (error, instance) => {
  if (isHandlerEnabled(error.config)) {
    // Eliminate property 'data' and 'headers' for privacy concern
    const stackDetail = error.response ? error.stack : error.request ? error.request._response : error.stack;
    const statusCode = error.response ? error.response.status : error.request ? error.request.status : 500;
    const newEndpointText = getEndpoints(error.config.url);

    console.error('Failed to executed action', {
      Message: error.message,
      Stack: stackDetail,
      StatusCode: statusCode,
      RequestPath: error.config.url,
      BaseUrl: error.config.baseURL,
      Endpoints: newEndpointText,
      Method: error.config.method.toUpperCase(),
    });

    // There is an infinite loop sometimes happening here with the 401, so this _retry attempt is failing.
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = store.getState().auth.token;
        if (!token) {
          localStorage.clear();

          cancel(); // cancel request
          return AuthApi.logOut()
            .then(() => {
              return store.dispatch(authLogoutSuccess());
            })
            .catch((err) => {
              return store.dispatch(authLogoutFail(err));
            });
        } else {
          return AuthApi.refreshToken()
            .then((res) => {
              if (res.token) {
                // Fullfill new token payload
                localStorage.setItem('expiresIn', res.expiresIn);
                localStorage.setItem('username', res.name);
                localStorage.setItem('tenant', res.tenant);
                localStorage.setItem('tenantId', res.tenantId);

                //store token
                store.dispatch(authSuccess(res.token, res.name, res.tenant, res.tenantId));

                // Change Authorization header
                const token = res.token;
                instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                //return previous request
                return instance(originalRequest);
              } else {
                localStorage.clear();
                return AuthApi.logOut()
                  .then(() => {
                    return store.dispatch(authLogoutSuccess());
                  })
                  .catch((err) => {
                    return store.dispatch(authLogoutFail(err));
                  });
              }
            })
            .catch((err) => {
              return Promise.reject({ ...err });
            });
        }
      }
    } else {
      return Promise.reject({ ...error });
    }
  }
  return Promise.reject({ ...error });
};

/**
 * Request Handler to all axios connection
 * Could put header authorization
 */
const requestHandler = (request) => {
  // start initial execution time on meta
  request.meta = request.meta || {};
  request.meta.requestStartedAt = new Date().getTime();
  // set cancel token request
  request.cancelToken = new CancelToken(function executor(c) {
    cancel = c;
  });
  const token = store.getState().auth.token;

  if (!request.headers['Content-Type']) {
    request.headers['Content-Type'] = 'application/json';
  }

  // if authenticated then add token to Bearer
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
    // request.headers["Access-Control-Allow-Headers"] =
    //   "Origin, X-Requested-With, Content-Type, Accept";
  }

  return request;
};

const instanceInterceptor = {
  responseInterceptor: (instance) => {
    /** Enabling response Interceptors handler */
    instance.interceptors.response.use(
      (response) => response,
      (error) => errorHandler(error, instance)
    );
  },
  requestInterceptor: (instance) => {
    instance.interceptors.request.use((request) => requestHandler(request));
  },
};

const getEndpoints = (url) => {
  const endpointText = url.split('?')[0];
  const splitEndpoint = endpointText.split('/');
  const newEndpointText = splitEndpoint
    .filter((txt) => txt !== ',')
    .map((param) => {
      if (param.match(numbers)) {
        return '{id}';
      } else {
        return param;
      }
    })
    .join('/');
  return newEndpointText;
};

export default instanceInterceptor;

import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

const PageSpinner: React.FC = () => {
  return (
    <Dialog
      showHeader={false}
      visible={true}
      modal={true}
      contentStyle={{ backgroundColor: 'transparent', border: '0px' }}
      style={{ boxShadow: '0 0 0px 0 rgba(0, 0, 0, 0)', width: '150px' }}
      className="loadingDialog"
      onHide={() => {}}
    >
      <div style={{ width: '100%', textAlign: 'center' }}>
        <ProgressSpinner style={{ width: '80%' }} strokeWidth="2" />
      </div>
    </Dialog>
  );
};

export default PageSpinner;

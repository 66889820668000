import * as actionTypes from '../../app/store/actionTypes';

import TenantApi from '../../app/api-client/TenantApi';
import TraineeApi from '../../app/api-client/TraineeApi';
import UserApi from '../../app/api-client/UserApi';
import competencyAPI from '../../app/api-client/FormBuilderApi';

// Fetch Data Tenants
export const fetchTenantsSuccess = (tenantsData) => {
  return {
    type: actionTypes.TENANT_FETCH_SUCCESS,
    tenantsData: tenantsData,
  };
};
export const fetchTenantsFail = (error) => {
  return {
    type: actionTypes.TENANT_FETCH_FAIL,
    error: error,
  };
};
export const fetchTenantsStart = () => {
  return {
    type: actionTypes.TENANT_FETCH_START,
  };
};

export const fetchTenants = () => {
  return (dispatch) => {
    dispatch(fetchTenantsStart());
    return TenantApi.getAllTenant()
      .then((response) => {
        dispatch(fetchTenantsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchTenantsFail(error));
      });
  };
};

// Tenant Detail
export const fetchTenantSuccess = (tenantData) => {
  return {
    type: actionTypes.TENANT_GET_DETAIL_SUCCESS,
    tenantData: tenantData,
  };
};
export const fetchTenantFail = (error) => {
  return {
    type: actionTypes.TENANT_GET_DETAIL_FAIL,
    error: error,
  };
};
export const fetchTenantStart = () => {
  return {
    type: actionTypes.TENANT_GET_DETAIL_START,
  };
};

export const fetchTenantDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchTenantStart());
    return TenantApi.getTenant(id)
      .then((response) => {
        return dispatch(fetchTenantSuccess(response));
      })
      .catch((error) => {
        return dispatch(fetchTenantFail(error));
      });
  };
};

//Get Tenant keys
export const fetchTenantKeysSuccess = (tenantKeys) => {
  return {
    type: actionTypes.TENANT_GET_KEYS_SUCCESS,
    tenantKeys: tenantKeys,
  };
};

export const fetchTenantKeysFail = (error) => {
  return {
    type: actionTypes.TENANT_GET_KEYS_FAIL,
    error: error,
  };
};

export const fetchTenantKeysStart = () => {
  return {
    type: actionTypes.TENANT_GET_KEYS_START,
  };
};

export const fetchTenantKeys = (tenantId, key) => {
  return (dispatch) => {
    dispatch(fetchTenantKeysStart());
    return TenantApi.getTenantKeys(tenantId, key)
      .then((response) => {
        return dispatch(fetchTenantKeysSuccess(response));
      })
      .catch((error) => {
        return dispatch(fetchTenantKeysFail(error));
      });
  };
};

//Create Tenant keys
export const createTenantKeySuccess = (tenantData) => {
  return {
    type: actionTypes.TENANT_KEY_ADD_NEW_SUCCESS,
    tenantData: tenantData,
  };
};

export const createTenantKeyFail = (error) => {
  return {
    type: actionTypes.TENANT_KEY_ADD_NEW_FAIL,
    error: error,
  };
};

export const createTenantKeyStart = () => {
  return {
    type: actionTypes.TENANT_KEY_ADD_NEW_START,
  };
};

export const createTenantKey = (data) => {
  return (dispatch) => {
    dispatch(createTenantKeyStart());
    return TenantApi.createTenantKey(data)
      .then((response) => {
        dispatch(createTenantKeySuccess(response));
        dispatch(fetchTenantKeys(data.tenantId, data.key));
        return { data: response };
      })
      .catch((error) => {
        dispatch(createTenantKeyFail(error));
      });
  };
};

//Disable tenant key
export const disableTenantKeySuccess = (tenantData) => {
  return {
    type: actionTypes.TENANT_KEY_DISABLE_SUCCESS,
    tenantData: tenantData,
  };
};

export const disableTenantKeyFail = (error) => {
  return {
    type: actionTypes.TENANT_KEY_DISABLE_FAIL,
    error: error,
  };
};

export const disableTenantKeyStart = () => {
  return {
    type: actionTypes.TENANT_KEY_DISABLE_START,
  };
};

export const disableTenantKey = (data) => {
  return (dispatch) => {
    dispatch(disableTenantKeyStart());
    return TenantApi.disableTenantKey(data)
      .then((response) => {
        dispatch(disableTenantKeySuccess(response));
        dispatch(fetchTenantKeys(data.tenantId, data.key));
        return { data: response };
      })
      .catch((error) => {
        dispatch(disableTenantKeyFail(error));
      });
  };
};

//Delete tenant key
export const deleteTenantKeySuccess = (tenantData) => {
  return {
    type: actionTypes.TENANT_KEY_DELETE_SUCCESS,
    tenantData: tenantData,
  };
};

export const deleteTenantKeyFail = (error) => {
  return {
    type: actionTypes.TENANT_KEY_DELETE_FAIL,
    error: error,
  };
};

export const deleteTenantKeyStart = () => {
  return {
    type: actionTypes.TENANT_KEY_DELETE_START,
  };
};

export const deleteTenantKey = (data) => {
  return (dispatch) => {
    dispatch(deleteTenantKeyStart());
    return TenantApi.deleteTenantKey(data)
      .then((response) => {
        dispatch(deleteTenantKeySuccess(response));
        dispatch(fetchTenantKeys(data.tenantId, data.key));
        return { data: response };
      })
      .catch((error) => {
        dispatch(deleteTenantKeyFail(error));
      });
  };
};

// Active Assessor
export const fetchActiveAsessorSuccess = (activeAssessor) => {
  return {
    type: actionTypes.TENANT_GET_ACTIVE_ASSESSOR_SUCCESS,
    activeAssessor: activeAssessor,
  };
};
export const fetchActiveAsessorFail = (error) => {
  return {
    type: actionTypes.TENANT_GET_ACTIVE_ASSESSOR_FAIL,
    error: error,
  };
};
export const fetchActiveAsessorStart = () => {
  return {
    type: actionTypes.TENANT_GET_ACTIVE_ASSESSOR_START,
  };
};

export const fetchActiveAssessor = () => {
  return (dispatch) => {
    dispatch(fetchActiveAsessorStart());
    return TenantApi.checkActiveAssessor()
      .then((response) => {
        return dispatch(fetchActiveAsessorSuccess(response));
      })
      .catch((error) => {
        return dispatch(fetchActiveAsessorFail(error));
      });
  };
};

// Create New Tenant
export const createTenantSuccess = (tenantData) => {
  return {
    type: actionTypes.TENANT_ADD_NEW_SUCCESS,
    tenantData: tenantData,
  };
};
export const createTenantFail = (error) => {
  return {
    type: actionTypes.TENANT_ADD_NEW_FAIL,
    error: error,
  };
};
export const createTenantStart = () => {
  return {
    type: actionTypes.TENANT_ADD_NEW_START,
  };
};

export const createNewTenant = (data) => {
  return (dispatch) => {
    dispatch(createTenantStart());
    return TenantApi.createTenant(data)
      .then((response) => {
        dispatch(createTenantSuccess(response));
        return { data: response };
      })
      .catch((error) => {
        dispatch(createTenantFail(error));
      });
  };
};

// Verify New Tenant
export const verifyTenantSuccess = () => {
  return {
    type: actionTypes.TENANT_VERIFY_SUCCESS,
  };
};
export const verifyTenantFail = (error) => {
  return {
    type: actionTypes.TENANT_VERIFY_FAIL,
    error: error,
  };
};
export const verifyTenantStart = () => {
  return {
    type: actionTypes.TENANT_VERIFY_START,
  };
};

export const verifyTenant = (id, token) => {
  return (dispatch) => {
    dispatch(verifyTenantStart());
    return TenantApi.verifyTenant(id, token)
      .then((response) => {
        if (response.verified) {
          dispatch(verifyTenantSuccess());
        } else {
          throw Error('Failed to verify tenant!');
        }
      })
      .catch((error) => {
        dispatch(verifyTenantFail(error));
      });
  };
};

// Update Tenant
export const updateTenantSuccess = () => {
  return {
    type: actionTypes.TENANT_EDIT_SUCCESS,
  };
};
export const updateTenantFail = (error) => {
  return {
    type: actionTypes.TENANT_EDIT_FAIL,
    error: error,
  };
};
export const updateTenantStart = () => {
  return {
    type: actionTypes.TENANT_EDIT_START,
  };
};

export const updateTenant = (id, data) => {
  return (dispatch) => {
    dispatch(updateTenantStart());
    return TenantApi.updateTenant(id, data)
      .then((response) => {
        dispatch(updateTenantSuccess(response));
        if (response.updated) {
          return { message: 'update' };
        } else {
          throw Error('Something went wrong on server!');
        }
      })
      .catch((error) => {
        dispatch(updateTenantFail(error));
      });
  };
};

// Enable/Disable Tenant
export const enableTenant = (id, enable) => {
  return (dispatch) => {
    dispatch(updateTenantStart());
    return TenantApi.enableTenant(id, enable)
      .then((response) => {
        dispatch(updateTenantSuccess(response));
        if (response.updated) {
          return { message: 'update' };
        } else {
          throw Error('Something went wrong on server!');
        }
      })
      .catch((error) => {
        dispatch(updateTenantFail(error));
        return error;
      });
  };
};

// Tenant Configuration
export const fetchTenantConfigurationSuccess = (confData) => {
  return {
    type: actionTypes.TENANT_CONFIGURATION_FETCH_SUCCESS,
    confData: confData,
  };
};
export const fetchTenantConfigurationFail = (error) => {
  return {
    type: actionTypes.TENANT_CONFIGURATION_FETCH_FAIL,
    error: error,
  };
};
export const fetchTenantConfigurationStart = () => {
  return {
    type: actionTypes.TENANT_CONFIGURATION_FETCH_START,
  };
};

export const fetchTenantConfiguration = (id) => {
  return (dispatch) => {
    dispatch(fetchTenantConfigurationStart());
    return TenantApi.getTenantConfiguration(id)
      .then((response) => {
        return dispatch(fetchTenantConfigurationSuccess(response));
      })
      .catch((error) => {
        return dispatch(fetchTenantConfigurationFail(error));
      });
  };
};

// Update Tenant Configuration
export const updateTenantConfigurationSuccess = () => {
  return {
    type: actionTypes.TENANT_CONFIGURATION_UPDATE_SUCCESS,
  };
};
export const updateTenantConfigurationFail = (error) => {
  return {
    type: actionTypes.TENANT_CONFIGURATION_UPDATE_FAIL,
    error: error,
  };
};
export const updateTenantConfigurationStart = () => {
  return {
    type: actionTypes.TENANT_CONFIGURATION_UPDATE_START,
  };
};

export const updateTenantConfiguration = (id, data) => {
  return (dispatch) => {
    dispatch(updateTenantConfigurationStart());
    return TenantApi.updateTenantConfiguration(id, data)
      .then((response) => {
        dispatch(updateTenantConfigurationSuccess(response));
        if (response.updated) {
          if (data.isIntegration == true) {
            localStorage.setItem('IsIntegrationTenant', 'True');
          } else {
            localStorage.setItem('IsIntegrationTenant', 'False');
          }
          return { message: 'update' };
        } else {
          throw Error('Something went wrong on server!');
        }
      })
      .catch((error) => {
        dispatch(updateTenantConfigurationFail(error));
        throw error;
      });
  };
};

// Update Tenant WPM Daily Email Configuration
export const updateTenantWPMDailyEmailConfigurationSuccess = () => {
  return {
    type: actionTypes.TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_SUCCESS,
  };
};
export const updateTenantWPMDailyEmailConfigurationFail = (error) => {
  return {
    type: actionTypes.TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_FAIL,
    error: error,
  };
};
export const updateTenantWPMDailyEmailConfigurationStart = () => {
  return {
    type: actionTypes.TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_START,
  };
};

export const updateTenantWPMDailyEmailConfiguration = (id, data) => {
  return (dispatch) => {
    dispatch(updateTenantWPMDailyEmailConfigurationStart());
    return TenantApi.updateTenantWPMDailyEmailConfiguration(id, data)
      .then((response) => {
        dispatch(updateTenantWPMDailyEmailConfigurationSuccess(response));
        if (response.updated) {
          return { message: 'update' };
        } else {
          throw Error('Something went wrong on server!');
        }
      })
      .catch((error) => {
        dispatch(updateTenantWPMDailyEmailConfigurationFail(error));
        return error;
      });
  };
};

// Tenant Sync
export const tenantSyncAssessorSuccess = (syncedAssessors) => {
  return {
    type: actionTypes.TENANT_SYNC_ASSESSOR_SUCCESS,
    syncedAssessors: syncedAssessors,
  };
};

export const tenantSyncStatusAssessorSuccess = (statusSyncedAssessor) => {
  return {
    type: actionTypes.TENANT_SYNC_STATUS_ASSESSOR_SUCCESS,
    statusSyncedAssessor: statusSyncedAssessor,
  };
};

export const tenantSyncTraineeSuccess = (syncedTrainees) => {
  return {
    type: actionTypes.TENANT_SYNC_TRAINEE_SUCCESS,
    syncedTrainees: syncedTrainees,
  };
};

export const tenantSyncStatusTraineeSuccess = (statusSyncedTrainee) => {
  return {
    type: actionTypes.TENANT_SYNC_STATUS_TRAINEE_SUCCESS,
    statusSyncedTrainee: statusSyncedTrainee,
  };
};

export const tenantSyncCompetencySuccess = (syncedCompetencies) => {
  return {
    type: actionTypes.TENANT_SYNC_COMPETENCY_SUCCESS,
    syncedCompetencies: syncedCompetencies,
  };
};

export const tenantSyncStatusCompetencySuccess = (statusSyncedCompetency) => {
  return {
    type: actionTypes.TENANT_SYNC_STATUS_COMPETENCY_SUCCESS,
    statusSyncedCompetency: statusSyncedCompetency,
  };
};

export const tenantSyncFail = (error) => {
  return {
    type: actionTypes.TENANT_SYNC_FAIL,
    error: error,
  };
};

export const tenantSyncStatusFail = (error) => {
  return {
    type: actionTypes.TENANT_SYNC_STATUS_FAIL,
    error: error,
  };
};

export const tenantSyncStart = () => {
  return {
    type: actionTypes.TENANT_SYNC_START,
  };
};

export const tenantSyncStatusStart = () => {
  return {
    type: actionTypes.TENANT_SYNC_STATUS_START,
  };
};

export const tenantAssessorSynchronization = () => {
  return (dispatch) => {
    dispatch(tenantSyncStatusStart());
    return UserApi.getSyncAssessorStatus()
      .then((assessorStatusResponse) => {
        // if (assessorStatusResponse.status !== 'In Progress') {
        // }
        dispatch(tenantSyncStart());
        return UserApi.syncAssessor()
          .then((assessorResponse) => {
            dispatch(tenantSyncAssessorSuccess(assessorResponse));
          })
          .catch((error) => {
            dispatch(tenantSyncFail(error));
          });
      })
      .catch((error) => {
        dispatch(tenantSyncStatusFail(error));
      });
  };
};

export const tenantPersonSynchronization = () => {
  return (dispatch) => {
    dispatch(tenantSyncStatusStart());
    return TraineeApi.getSyncTraineeStatus()
      .then((traineeStatusResponse) => {
        // if (traineeStatusResponse.status !== 'In Progress') {
        // }
        return TraineeApi.syncTrainee()
          .then((traineeResponse) => {
            dispatch(tenantSyncTraineeSuccess(traineeResponse));
          })
          .catch((error) => {
            dispatch(tenantSyncFail(error));
          });
      })
      .catch((error) => {
        dispatch(tenantSyncStatusFail(error));
      });
  };
};

export const tenantCompetencySynchronization = () => {
  return (dispatch) => {
    dispatch(tenantSyncStatusStart());
    return competencyAPI
      .getSyncCompetencyStatus()
      .then((competencyStatusResponse) => {
        // if (competencyStatusResponse.status !== 'In Progress') {
        // }
        return competencyAPI
          .syncCompetency()
          .then((competencyResponse) => {
            dispatch(tenantSyncCompetencySuccess(competencyResponse));
          })
          .catch((error) => {
            dispatch(tenantSyncFail(error));
          });
      })
      .catch((error) => {
        dispatch(tenantSyncFail(error));
      });
  };
};

export const tenantSynchronizationStatus = () => {
  return (dispatch) => {
    dispatch(tenantSyncStatusStart());
    return UserApi.getSyncAssessorStatus()
      .then((assessorStatusResponse) => {
        dispatch(tenantSyncStatusAssessorSuccess(assessorStatusResponse));
        return TraineeApi.getSyncTraineeStatus()
          .then((traineeStatusResponse) => {
            dispatch(tenantSyncStatusTraineeSuccess(traineeStatusResponse));
            return competencyAPI
              .getSyncCompetencyStatus()
              .then((competencyStatusResponse) => {
                dispatch(tenantSyncStatusCompetencySuccess(competencyStatusResponse));
              })
              .catch((error) => {
                dispatch(tenantSyncStatusFail(error));
                return error;
              });
          })
          .catch((error) => {
            dispatch(tenantSyncStatusFail(error));
            return error;
          });
      })
      .catch((error) => {
        dispatch(tenantSyncStatusFail(error));
        return error;
      });
  };
};

// Fetch Data Tenant Sync Person Failed History
export const fetchTenantSyncPersonFailedHistorySuccess = (historyData) => {
  return {
    type: actionTypes.TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_SUCCESS,
    historyData: historyData,
  };
};
export const fetchTenantSyncPersonFailedHistoryFail = (error) => {
  return {
    type: actionTypes.TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_FAIL,
    error: error,
  };
};
export const fetchTenantSyncPersonFailedHistoryStart = () => {
  return {
    type: actionTypes.TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_START,
  };
};

export const fetchTenantSyncPersonFailedHistory = () => {
  return (dispatch) => {
    dispatch(fetchTenantSyncPersonFailedHistoryStart());
    return TenantApi.getSyncPersonFailedHistory()
      .then((response) => {
        dispatch(fetchTenantSyncPersonFailedHistorySuccess(response));
      })
      .catch((error) => {
        dispatch(fetchTenantSyncPersonFailedHistoryFail(error));
      });
  };
};

import * as actionTypes from '../../app/store/actionTypes';
import { updateState } from '../../util/updateState';
import _ from 'lodash';
const initialState = {
  forms: [],
  formsCount: 0,
  form: {},
  lockedStatus: {},
  formNumber: 0,
  loading: false,
  loadingBackground: false,
  formData: {},
  competencyList: [],
  formDashboard: {},
  forceStop: false,
  fullTextSearchShowResult: true,
  error: null,
  isUploadingImages: false,
  uploadingImages: [],
  pagination: false,
};

const turnOnPagination = (state, action) => {
  return updateState(state, { pagination: true });
};

const turnOffPagination = (state, action) => {
  return updateState(state, { pagination: false });
};

const fetchFormsCountSuccess = (state, action) => {
  return updateState(state, {
    formsCount: action.formsCount,
  });
};

const fetchFormsStart = (state, action) => {
  return updateState(state, { loading: true });
};
const fetchFormsSuccess = (state, action) => {
  return updateState(state, {
    forms: action.formsData,
    loading: false,
    error: null,
  });
};
const fetchFormsFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

//dynamic get forms
const fetchFormsDynamicStart = (state, action) => {
  var result = [];
  if (state.pagination) {
    //continue with previous fetching
    result = state.forms;
    return updateState(state, {
      forms: result,
      loading: true,
      loadingBackground: true,
      fullTextSearchShowResult: false,
      pagination: false,
      forceStop: false,
    });
  }
  return updateState(state, {
    forms: [],
    loading: true,
    loadingBackground: false,
    fullTextSearchShowResult: false,
    forceStop: false,
    error: null,
    pagination: false,
  });
};

const fetchFormsDynamicSuccess = (state, action) => {
  var updatedList = [];
  if (!state.forceStop) {
    updatedList = _.concat(state.forms, action.formsData);
    updatedList = _.uniqBy(updatedList, (e) => {
      return e.formUuid;
    });
  }

  return updateState(state, {
    forms: updatedList,
    loadingBackground: false,
    forceStop: false,
    error: null,
    loading: false,
    pagination: false,
  });
};

const fetchFormsDynamicForceStop = (state, action) => {
  return updateState(state, {
    forceStop: true,
  });
};

const fetchFormsDynamicPartialSuccess = (state, action) => {
  var updatedList = _.concat(state.forms, action.formsData);
  return updateState(state, {
    forms: updatedList,
    error: null,
  });
};

const fetchFormsDynamicFail = (state, action) => {
  return updateState(state, { error: action.error, loadingBackground: false });
};

//full text search get forms
const fetchFormsFullTextSearchStart = (state, action) => {
  var result = [];
  if (state.pagination) {
    result = state.forms;
  }
  return updateState(state, {
    forms: result,
    loading: true,
    loadingBackground: false,
    fullTextSearchShowResult: true,
    pagination: false,
  });
};

const fetchFormsFullTextSearchSuccess = (state, action) => {
  var updatedList = _.concat(state.forms, action.formsData);
  updatedList = _.uniqBy(updatedList, (e) => {
    return e.formUuid;
  });
  if (!state.fullTextSearchShowResult) {
    return updateState(state, {
      forms: [],
      loading: false,
      loadingBackground: false,
      forceStop: false,
      error: null,
    });
  }
  return updateState(state, {
    forms: updatedList,
    loading: false,
    loadingBackground: false,
    forceStop: false,
    error: null,
  });
};

const fetchFormsFullTextSearchFail = (state, action) => {
  return updateState(state, { error: action.error, loadingBackground: false });
};

// get detail form
const getFormDetailStart = (state, action) => {
  return updateState(state, { loading: true });
};
const getFormDetailSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const getFormDetailFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// get form number
const getFormNumberStart = (state, action) => {
  return updateState(state, { loading: true });
};
const getFormNumberSuccess = (state, action) => {
  return updateState(state, {
    formNumber: action.formNumber,
    loading: false,
    error: null,
  });
};
const getFormNumberFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// create form
const createFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const createFormSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const createFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// edit form
const editFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const editFormSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const editFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// clone form
const cloneFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const cloneFormSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const cloneFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// publish form
const publishFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const publishFormSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const publishFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// activate form
const activateFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const activateFormSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const activateFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// delete form
const deleteFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const deleteFormSuccess = (state, action) => {
  return updateState(state, {
    form: action.formData,
    loading: false,
    error: null,
  });
};
const deleteFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// locked form
const lockedFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const lockedFormSuccess = (state, action) => {
  return updateState(state, {
    lockedStatus: action.lockedStatus,
    loading: false,
    error: null,
  });
};
const lockedFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// update locked form
const updateLockedFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const updateLockedFormSuccess = (state, action) => {
  return updateState(state, {
    lockedStatus: action.lockedStatus,
    loading: false,
    error: null,
  });
};
const updateLockedFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// delete locked form
const deleteLockedFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const deleteLockedFormSuccess = (state, action) => {
  return updateState(state, {
    lockedStatus: action.lockedStatus,
    loading: false,
    error: null,
  });
};
const deleteLockedFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// delete locked form
const getFormDataStart = (state, action) => {
  return updateState(state, { loading: true });
};
const getFormDataSuccess = (state, action) => {
  return updateState(state, {
    formData: action.formData,
    loading: false,
    error: null,
  });
};
const getFormDataFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// competency
const getCompetencyListStart = (state, action) => {
  return updateState(state, { loading: true });
};
const getCompetencyListSuccess = (state, action) => {
  return updateState(state, {
    competencyList: action.competencyList,
    loading: false,
    error: null,
  });
};
const getComptencyListFail = (state, action) => {
  // return updateState(state, {loading: false});
  return updateState(state, {
    error: action.error,
    competencyList: null,
    loading: false,
  });
};

const insertCompetencyListStart = (state, action) => {
  return updateState(state, { loading: true });
};
const insertCompetencyListSuccess = (state, action) => {
  return updateState(state, {
    competencyList: action.competencyList,
    loading: false,
    error: null,
  });
};
const insertComptencyListFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// form dashboard
const dashboardFormStart = (state, action) => {
  return updateState(state, { loading: true });
};
const dashboardFormSuccess = (state, action) => {
  return updateState(state, {
    formDashboard: action.formDashboard,
    loading: false,
    error: null,
  });
};
const dashboardFormFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// upload form image
const uploadFormImageStart = (state, action) => {
  let newUploadingImages = [...state.uploadingImages];
  const index = state.uploadingImages.findIndex(
    (image) => image.formId === action.formId && image.formDataId === action.formDataId
  );

  if (index >= 0) {
    newUploadingImages[index].isUploading = true;
    newUploadingImages[index].success = null;
    newUploadingImages[index].error = null;
  } else {
    newUploadingImages.push({
      formId: action.formId,
      formDataId: action.formDataId,
      isUploading: true,
      success: null,
    });
  }

  return updateState(state, {
    uploadingImages: newUploadingImages,
  });
};
const uploadFormImageSuccess = (state, action) => {
  const newUploadingImages = [...state.uploadingImages];
  const index = state.uploadingImages.findIndex(
    (image) => image.formId === action.formId && image.formDataId === action.formDataId
  );

  if (index >= 0) {
    newUploadingImages[index].isUploading = false;
    newUploadingImages[index].success = true;
  }

  return updateState(state, {
    uploadingImages: newUploadingImages,
    error: null,
  });
};

const uploadFormImageFail = (state, action) => {
  const newUploadingImages = [...state.uploadingImages];
  const index = state.uploadingImages.findIndex(
    (image) => image.formId === action.formId && image.formDataId === action.formDataId
  );

  if (index >= 0) {
    newUploadingImages[index].isUploading = false;
    newUploadingImages[index].success = false;
    newUploadingImages[index].error = action.error;
  }

  return updateState(state, {
    error: action.error,
    uploadingImages: newUploadingImages,
  });
};

const uploadFormImageClear = (state, action) => {
  let newUploadingImages = [...state.uploadingImages];
  const index = state.uploadingImages.findIndex(
    (image) => image.formId === action.formId && image.formDataId === action.formDataId
  );

  if (index > -1) {
    newUploadingImages.splice(index, 1);
  }

  return updateState(state, {
    uploadingImages: newUploadingImages,
  });
};

// upload form images
const uploadFormImagesStart = (state, action) => {
  return updateState(state, { isUploadingImages: true });
};
const uploadFormImagesSuccess = (state, action) => {
  console.log('All images has been uploaded.');
  return updateState(state, {
    isUploadingImages: false,
    error: null,
  });
};
const uploadFormImagesFail = (state, action) => {
  return updateState(state, { error: action.error, isUploadingImages: false });
};

// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORMS_GET_COUNT_SUCCESS:
      return fetchFormsCountSuccess(state, action);
    case actionTypes.FORMS_PAGINATION_ON:
      return turnOnPagination(state, action);
    case actionTypes.FORMS_PAGINATION_OFF:
      return turnOffPagination(state, action);
    case actionTypes.FORMS_GET_START:
      return fetchFormsStart(state, action);
    case actionTypes.FORMS_GET_SUCCESS:
      return fetchFormsSuccess(state, action);
    case actionTypes.FORMS_GET_FAIL:
      return fetchFormsFail(state, action);
    case actionTypes.FORMS_DYNAMIC_GET_START:
      return fetchFormsDynamicStart(state, action);
    case actionTypes.FORMS_DYNAMIC_GET_SUCCESS:
      return fetchFormsDynamicSuccess(state, action);
    case actionTypes.FORMS_DYNAMIC_GET_FORCE_STOP:
      return fetchFormsDynamicForceStop(state, action);
    case actionTypes.FORMS_DYNAMIC_GET_PARTIAL_SUCCESS:
      return fetchFormsDynamicPartialSuccess(state, action);
    case actionTypes.FORMS_DYNAMIC_GET_FAIL:
      return fetchFormsDynamicFail(state, action);
    case actionTypes.FORMS_FULLTEXTSEARCH_GET_START:
      return fetchFormsFullTextSearchStart(state, action);
    case actionTypes.FORMS_FULLTEXTSEARCH_GET_SUCCESS:
      return fetchFormsFullTextSearchSuccess(state, action);
    case actionTypes.FORMS_FULLTEXTSEARCH_GET_FAIL:
      return fetchFormsFullTextSearchFail(state, action);
    case actionTypes.FORMS_GET_DETAIL_START:
      return getFormDetailStart(state, action);
    case actionTypes.FORMS_GET_DETAIL_SUCCESS:
      return getFormDetailSuccess(state, action);
    case actionTypes.FORMS_GET_DETAIL_FAIL:
      return getFormDetailFail(state, action);
    case actionTypes.FORMS_ADD_NEW_START:
      return createFormStart(state, action);
    case actionTypes.FORMS_ADD_NEW_SUCCESS:
      return createFormSuccess(state, action);
    case actionTypes.FORMS_ADD_NEW_FAIL:
      return createFormFail(state, action);
    case actionTypes.FORMS_EDIT_START:
      return editFormStart(state, action);
    case actionTypes.FORMS_EDIT_SUCCESS:
      return editFormSuccess(state, action);
    case actionTypes.FORMS_EDIT_FAIL:
      return editFormFail(state, action);
    case actionTypes.FORMS_CLONE_START:
      return cloneFormStart(state, action);
    case actionTypes.FORMS_CLONE_SUCCESS:
      return cloneFormSuccess(state, action);
    case actionTypes.FORMS_CLONE_FAIL:
      return cloneFormFail(state, action);
    case actionTypes.FORMS_PUBLISH_START:
      return publishFormStart(state, action);
    case actionTypes.FORMS_PUBLISH_SUCCESS:
      return publishFormSuccess(state, action);
    case actionTypes.FORMS_PUBLISH_FAIL:
      return publishFormFail(state, action);
    case actionTypes.FORMS_ACTIVATE_START:
      return activateFormStart(state, action);
    case actionTypes.FORMS_ACTIVATE_SUCCESS:
      return activateFormSuccess(state, action);
    case actionTypes.FORMS_ACTIVATE_FAIL:
      return activateFormFail(state, action);
    case actionTypes.FORMS_DELETE_START:
      return deleteFormStart(state, action);
    case actionTypes.FORMS_DELETE_SUCCESS:
      return deleteFormSuccess(state, action);
    case actionTypes.FORMS_DELETE_FAIL:
      return deleteFormFail(state, action);
    case actionTypes.FORMS_GET_FORM_NUMBER_START:
      return getFormNumberStart(state, action);
    case actionTypes.FORMS_GET_FORM_NUMBER_SUCCESS:
      return getFormNumberSuccess(state, action);
    case actionTypes.FORMS_GET_FORM_NUMBER_FAIL:
      return getFormNumberFail(state, action);
    case actionTypes.FORMS_LOCKED_START:
      return lockedFormStart(state, action);
    case actionTypes.FORMS_LOCKED_SUCCESS:
      return lockedFormSuccess(state, action);
    case actionTypes.FORMS_LOCKED_FAIL:
      return lockedFormFail(state, action);
    case actionTypes.FORMS_UPDATE_LOCKED_START:
      return updateLockedFormStart(state, action);
    case actionTypes.FORMS_UPDATE_LOCKED_SUCCESS:
      return updateLockedFormSuccess(state, action);
    case actionTypes.FORMS_UPDATE_LOCKED_FAIL:
      return updateLockedFormFail(state, action);
    case actionTypes.FORMS_DELETE_LOCKED_START:
      return deleteLockedFormStart(state, action);
    case actionTypes.FORMS_DELETE_LOCKED_SUCCESS:
      return deleteLockedFormSuccess(state, action);
    case actionTypes.FORMS_DELETE_LOCKED_FAIL:
      return deleteLockedFormFail(state, action);
    case actionTypes.FORMS_GET_FORM_DATA_START:
      return getFormDataStart(state, action);
    case actionTypes.FORMS_GET_FORM_DATA_SUCCESS:
      return getFormDataSuccess(state, action);
    case actionTypes.FORMS_GET_FORM_DATA_FAIL:
      return getFormDataFail(state, action);
    case actionTypes.COMPETENCY_GET_ALL_START:
      return getCompetencyListStart(state, action);
    case actionTypes.COMPETENCY_GET_ALL_SUCCESS:
      return getCompetencyListSuccess(state, action);
    case actionTypes.COMPETENCY_GET_ALL_FAIL:
      return getComptencyListFail(state, action);
    case actionTypes.COMPETENCY_BULK_INSERT_START:
      return insertCompetencyListStart(state, action);
    case actionTypes.COMPETENCY_BULK_INSERT_SUCCESS:
      return insertCompetencyListSuccess(state, action);
    case actionTypes.COMPETENCY_BULK_INSERT_FAIL:
      return insertComptencyListFail(state, action);
    case actionTypes.DASHBOARD_FORM_START:
      return dashboardFormStart(state, action);
    case actionTypes.DASHBOARD_FORM_SUCCESS:
      return dashboardFormSuccess(state, action);
    case actionTypes.DASHBOARD_FORM_FAIL:
      return dashboardFormFail(state, action);
    case actionTypes.FORMS_IMAGE_UPLOAD_START:
      return uploadFormImageStart(state, action);
    case actionTypes.FORMS_IMAGE_UPLOAD_SUCCESS:
      return uploadFormImageSuccess(state, action);
    case actionTypes.FORMS_IMAGE_UPLOAD_FAIL:
      return uploadFormImageFail(state, action);
    case actionTypes.FORMS_IMAGE_UPLOAD_CLEAR:
      return uploadFormImageClear(state, action);
    case actionTypes.FORMS_IMAGES_UPLOAD_START:
      return uploadFormImagesStart(state, action);
    case actionTypes.FORMS_IMAGES_UPLOAD_SUCCESS:
      return uploadFormImagesSuccess(state, action);
    case actionTypes.FORMS_IMAGES_UPLOAD_FAIL:
      return uploadFormImagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;

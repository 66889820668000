import * as actionTypes from '../../app/store/actionTypes';

import AssessmentApi from '../../app/api-client/AssessmentApi';

export const fetchAssessmentsSuccess = (assessmentsData) => {
  return {
    type: actionTypes.ASSESSMENT_GET_SUCCESS,
    assessmentsData: assessmentsData,
  };
};
export const fetchAssessmentsFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_GET_FAIL,
    error: error,
  };
};
export const fetchAssessmentsStart = () => {
  return {
    type: actionTypes.ASSESSMENT_GET_START,
  };
};

export const fetchAssessments = (role) => {
  return (dispatch) => {
    dispatch(fetchAssessmentsStart());
    if (role === 'WPM') {
      return AssessmentApi.getAllCompletedAssessments()
        .then((response) => {
          dispatch(fetchAssessmentsSuccess(response));
        })
        .catch((error) => {
          dispatch(fetchAssessmentsFail(error));
        });
    } else {
      return AssessmentApi.getAllAssessments()
        .then((response) => {
          dispatch(fetchAssessmentsSuccess(response));
        })
        .catch((error) => {
          dispatch(fetchAssessmentsFail(error));
        });
    }
  };
};

export const fetchFilteredAssessments = (dateFilter, startDate, endDate, status, needWpmAssigned) => {
  return (dispatch) => {
    dispatch(fetchAssessmentsStart());
    return AssessmentApi.getFilteredAllAssessments(dateFilter, startDate, endDate, status, needWpmAssigned)
      .then((response) => {
        dispatch(fetchAssessmentsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchAssessmentsFail(error));
      });
  };
};

// Fetch Data Assessments (Dynamic)
export const fetchAssessmentsDynamicSuccess = (assessmentsData) => {
  return {
    type: actionTypes.ASSESSMENTS_DYNAMIC_GET_SUCCESS,
    assessmentsData: assessmentsData,
  };
};

export const fetchCountSuccess = (assessmentsCount) => {
  return {
    type: actionTypes.ASSESSMENTS_GET_COUNT_SUCCESS,
    assessmentsCount: assessmentsCount,
  };
};

export const fetchAssessmentsDynamicPartialSuccess = (assessmentsData) => {
  return {
    type: actionTypes.ASSESSMENTS_DYNAMIC_GET_PARTIAL_SUCCESS,
    assessmentsData: assessmentsData,
  };
};
export const fetchAssessmentsDynamicFail = (error) => {
  return {
    type: actionTypes.ASSESSMENTS_DYNAMIC_GET_FAIL,
    error: error,
  };
};
export const fetchAssessmentsDynamicStart = () => {
  return {
    type: actionTypes.ASSESSMENTS_DYNAMIC_GET_START,
  };
};
export const fetchAssessmentsDynamicStop = () => {
  return {
    type: actionTypes.ASSESSMENTS_DYNAMIC_GET_FORCE_STOP,
  };
};

export const stopFetchAssessmentsDynamic = () => {
  return (dispatch) => {
    return dispatch(fetchAssessmentsDynamicStop());
  };
};

export const fetchAssessmentsDynamic = (
  dateFilter,
  startDate,
  endDate,
  status,
  textSearch,
  skip,
  limit,
  showAllCompletedData,
  needWpmAssigned
) => {
  return (dispatch) => {
    dispatch(fetchAssessmentsDynamicStart());
    return AssessmentApi.getAllAssessmentsDynamic(
      dateFilter,
      startDate,
      endDate,
      status,
      textSearch,
      skip,
      limit,
      showAllCompletedData,
      needWpmAssigned
    )
      .then((response) => {
        if (response !== null && response !== undefined && response !== '' && response.length > 0) {
          //dispatch(fetchAssessmentsDynamicPartialSuccess(response));
          //the line below has been used to turn the pagination off. In case to turn the pagination on, delete the line below and uncomment line above
          dispatch(fetchAssessmentsDynamicSuccess(response));
          AssessmentApi.getAllAssessmentsDynamicCount(
            dateFilter,
            startDate,
            endDate,
            status,
            textSearch,
            showAllCompletedData,
            needWpmAssigned
          ).then((assessmentsCount) => {
            dispatch(fetchCountSuccess(assessmentsCount));
          });
        } else {
          dispatch(fetchAssessmentsDynamicSuccess(response));
          dispatch(fetchCountSuccess(0));
        }
      })
      .catch((error) => {
        dispatch(fetchAssessmentsDynamicFail(error));
      });
  };
};

export const turnOnPagination = () => {
  return {
    type: actionTypes.ASSESSMENT_PAGINATION_ON,
  };
};

export const turnOffPagination = () => {
  return {
    type: actionTypes.ASSESSMENT_PAGINATION_OFF,
  };
};

// Fetch assessments by text search
export const fetchAssessmentsTextSearchFail = (error) => {
  return {
    type: actionTypes.ASSESSMENTS_FULLTEXTSEARCH_GET_FAIL,
    error: error,
  };
};
export const fetchAssessmentsTextSearchStart = () => {
  return {
    type: actionTypes.ASSESSMENTS_FULLTEXTSEARCH_GET_START,
  };
};

export const fetchAssessmentsTextSearchSuccess = (assessmentsData) => {
  return {
    type: actionTypes.ASSESSMENTS_FULLTEXTSEARCH_GET_SUCCESS,
    assessmentsData: assessmentsData,
  };
};

/*
 * fetch assessments from from the full text search end point
 *
 * @param {string} textSearch This is the text sent to the full text search end point
 */
export const fetchAssessmentsByTextSearch = (textSearch, skip, limit) => {
  return (dispatch) => {
    dispatch(fetchAssessmentsTextSearchStart());
    return AssessmentApi.getAssessmentsByTextSearch(textSearch, skip, limit)
      .then((response) => {
        dispatch(fetchAssessmentsTextSearchSuccess(response));
        AssessmentApi.getAssessmentsByTextSearchCount(textSearch).then((assessmentsCount) => {
          dispatch(fetchCountSuccess(assessmentsCount));
        });
      })
      .catch((error) => {
        dispatch(fetchAssessmentsTextSearchFail(error));
      });
  };
};

// Get Assessment Detail
export const getAssessmentDetailSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_GET_DETAIL_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const getAssessmentDetailFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_GET_DETAIL_FAIL,
    error: error,
  };
};
export const getAssessmentDetailStart = () => {
  return {
    type: actionTypes.ASSESSMENT_GET_DETAIL_START,
  };
};

export const getDetailAssessment = (assessmentUuid, separateAssessmentDataImages) => {
  return (dispatch) => {
    dispatch(getAssessmentDetailStart());
    return AssessmentApi.getAssessment(assessmentUuid, separateAssessmentDataImages)
      .then((response) => {
        dispatch(getAssessmentDetailSuccess(response));
      })
      .catch((error) => {
        dispatch(getAssessmentDetailFail(error));
      });
  };
};

// Create Assessment
export const createAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_ADD_NEW_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const createAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_ADD_NEW_FAIL,
    error: error,
  };
};
export const createAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_ADD_NEW_START,
  };
};

export const createAssessment = (data) => {
  return (dispatch) => {
    dispatch(createAssessmentStart());
    return AssessmentApi.createAssessment(data)
      .then((response) => {
        dispatch(createAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(createAssessmentFail(error));
      });
  };
};

// Edit Assessment
export const editAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_EDIT_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const editAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_EDIT_FAIL,
    error: error,
  };
};
export const editAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_EDIT_START,
  };
};

export const editAssessment = (id, data) => {
  return (dispatch) => {
    dispatch(editAssessmentStart());
    return AssessmentApi.editAssessment(id, data)
      .then((response) => {
        dispatch(editAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(editAssessmentFail(error));
      });
  };
};

export const deleteAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_DELETE_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const deleteAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_DELETE_FAIL,
    error: error,
  };
};
export const deleteAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_DELETE_START,
  };
};

export const deleteAssessment = (assessmentUuid, data) => {
  return (dispatch) => {
    dispatch(deleteAssessmentStart());
    return AssessmentApi.deleteAssessment(assessmentUuid, data)
      .then((response) => {
        dispatch(deleteAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(deleteAssessmentFail(error));
      });
  };
};

export const cancelAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_CANCEL_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const cancelAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_CANCEL_FAIL,
    error: error,
  };
};
export const cancelAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_CANCEL_START,
  };
};

export const cancelAssessment = (assessmentUuid, data) => {
  return (dispatch) => {
    dispatch(cancelAssessmentStart());
    return AssessmentApi.cancelAssessment(assessmentUuid, data)
      .then((response) => {
        dispatch(cancelAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(cancelAssessmentFail(error));
      });
  };
};

// Approve Assessment
export const approveAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_APPROVE_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const approveAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_APPROVE_FAIL,
    error: error,
  };
};
export const approveAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_APPROVE_START,
  };
};

export const approveAssessment = (assessmentUuid, data) => {
  return (dispatch) => {
    dispatch(approveAssessmentStart());
    return AssessmentApi.approveAssessment(assessmentUuid, data)
      .then((response) => {
        dispatch(approveAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(approveAssessmentFail(error));
      });
  };
};

// Send Assessment to INX
export const sendAssessmentToINXSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_SEND_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const sendAssessmentToINXFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_SEND_FAIL,
    error: error,
  };
};
export const sendAssessmentToINXStart = () => {
  return {
    type: actionTypes.ASSESSMENT_SEND_START,
  };
};

export const sendAssessmentToINX = (assessmentUuid) => {
  return (dispatch) => {
    dispatch(sendAssessmentToINXStart());
    return AssessmentApi.sendAssessmentToINX(assessmentUuid)
      .then((response) => {
        dispatch(sendAssessmentToINXSuccess(response));
      })
      .catch((error) => {
        dispatch(sendAssessmentToINXFail(error));
      });
  };
};

// Reject Assessment
export const rejectAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_REJECT_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const rejectAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_REJECT_FAIL,
    error: error,
  };
};
export const rejectAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_REJECT_START,
  };
};

export const rejectAssessment = (assessmentUuid, data) => {
  return (dispatch) => {
    dispatch(rejectAssessmentStart());
    return AssessmentApi.rejectAssessment(assessmentUuid, data)
      .then((response) => {
        dispatch(rejectAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(rejectAssessmentFail(error));
      });
  };
};

// Revise Assessment
export const reviseAssessmentSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_REVISE_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const reviseAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_REVISE_FAIL,
    error: error,
  };
};
export const reviseAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_REVISE_START,
  };
};

export const reviseAssessment = (id, data) => {
  return (dispatch) => {
    dispatch(reviseAssessmentStart());
    return AssessmentApi.reviseAssessment(id, data)
      .then((response) => {
        dispatch(reviseAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(reviseAssessmentFail(error));
      });
  };
};

// Change Assessment WPM
export const changeAssessmentWPMSuccess = (assessmentData) => {
  return {
    type: actionTypes.ASSESSMENT_CHANGE_WPM_SUCCESS,
    assessmentData: assessmentData,
  };
};
export const changeAssessmentWPMFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_CHANGE_WPM_FAIL,
    error: error,
  };
};
export const changeAssessmentWPMStart = () => {
  return {
    type: actionTypes.ASSESSMENT_CHANGE_WPM_START,
  };
};

export const changeAssessmentWPM = (id, data) => {
  return (dispatch) => {
    dispatch(changeAssessmentWPMStart());
    return AssessmentApi.changeAssessmentWPM(id, data)
      .then((response) => {
        dispatch(changeAssessmentWPMSuccess(response));
      })
      .catch((error) => {
        dispatch(changeAssessmentWPMFail(error));
      });
  };
};

// Get Assessment Coment
export const getAllAssessmentCommentSuccess = (commentsData) => {
  return {
    type: actionTypes.ASSESSMENT_GET_ALL_COMMENT_SUCCESS,
    commentsData: commentsData,
  };
};
export const getAllAssessmentCommentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_GET_ALL_COMMENT_FAIL,
    error: error,
  };
};
export const getAllAssessmentCommentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_GET_ALL_COMMENT_START,
  };
};

export const getAllAssessmentComment = (assessmentUuid) => {
  return (dispatch) => {
    dispatch(getAllAssessmentCommentStart());
    return AssessmentApi.getAllAssessmentComment(assessmentUuid)
      .then((response) => {
        dispatch(getAllAssessmentCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(getAllAssessmentCommentFail(error));
      });
  };
};

// Add Assessment Coment
export const addAssessmentCommentSuccess = (commentData) => {
  return {
    type: actionTypes.ASSESSMENT_ADD_COMMENT_SUCCESS,
    commentData: commentData,
  };
};
export const addAssessmentCommentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_ADD_COMMENT_FAIL,
    error: error,
  };
};
export const addAssessmentCommentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_ADD_COMMENT_START,
  };
};

export const addAssessmentComment = (data) => {
  return (dispatch) => {
    dispatch(addAssessmentCommentStart());
    return AssessmentApi.addComment(data)
      .then((response) => {
        dispatch(addAssessmentCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(addAssessmentCommentFail(error));
      });
  };
};

// Edit Assessment Coment
export const editAssessmentCommentSuccess = (commentData) => {
  return {
    type: actionTypes.ASSESSMENT_EDIT_COMMENT_SUCCESS,
    commentData: commentData,
  };
};
export const editAssessmentCommentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_EDIT_COMMENT_FAIL,
    error: error,
  };
};
export const editAssessmentCommentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_EDIT_COMMENT_START,
  };
};

export const editAssessmentComment = (data) => {
  return (dispatch) => {
    dispatch(editAssessmentCommentStart());
    return AssessmentApi.editComment(data)
      .then((response) => {
        dispatch(editAssessmentCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(editAssessmentCommentFail(error));
      });
  };
};

// Delete Assessment Coment
export const deleteAssessmentCommentSuccess = (commentData) => {
  return {
    type: actionTypes.ASSESSMENT_DELETE_COMMENT_SUCCESS,
    commentData: commentData,
  };
};
export const deleteAssessmentCommentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_DELETE_COMMENT_FAIL,
    error: error,
  };
};
export const deleteAssessmentCommentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_DELETE_COMMENT_START,
  };
};

export const deleteAssessmentComment = (assessmentUuid, commentId) => {
  return (dispatch) => {
    dispatch(deleteAssessmentCommentStart());
    return AssessmentApi.deleteComment(assessmentUuid, commentId)
      .then((response) => {
        dispatch(deleteAssessmentCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(deleteAssessmentCommentFail(error));
      });
  };
};

// print pdf assessment
export const printPDFAssessmentSuccess = (printData) => {
  return {
    type: actionTypes.ASSESSMENT_PRINT_PDF_SUCCESS,
    printData: printData,
  };
};
export const printPDFAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_PRINT_PDF_FAIL,
    error: error,
  };
};
export const printPDFAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_PRINT_PDF_START,
  };
};

export const printPDFAssessment = (tenantId, assessmentUuid, locale) => {
  return (dispatch) => {
    dispatch(printPDFAssessmentStart());
    return AssessmentApi.printPDF(tenantId, assessmentUuid, locale)
      .then((response) => {
        dispatch(printPDFAssessmentSuccess(response));
      })
      .catch((error) => {
        dispatch(printPDFAssessmentFail(error));
      });
  };
};

// Asessment on dashboard
export const dashboardAssessmentsActioSuccess = (assessmentDashboard) => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_SUCCESS,
    assessmentDashboard: assessmentDashboard,
  };
};
export const dashboardAssessmentsActionFail = (error) => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_FAIL,
    error: error,
  };
};
export const dashboardAssessmentsActionStart = () => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_START,
  };
};

export const dashboardAssessmentsAction = (tenantId) => {
  return (dispatch) => {
    dispatch(dashboardAssessmentsActionStart());
    return AssessmentApi.getAssessmentDashboard(tenantId)
      .then((response) => {
        dispatch(dashboardAssessmentsActioSuccess(response));
      })
      .catch((error) => {
        dispatch(dashboardAssessmentsActionFail(error));
      });
  };
};

export const dashboardAssessmentsCompletedActioSuccess = (count) => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_COMPLETED_SUCCESS,
    count: count,
  };
};
export const dashboardAssessmentsCompletedActionFail = (error) => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_COMPLETED_FAIL,
    error: error,
  };
};
export const dashboardAssessmentsCompletedActionStart = () => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_COMPLETED_START,
  };
};

export const dashboardAssessmentsCompletedAction = () => {
  return (dispatch) => {
    dispatch(dashboardAssessmentsCompletedActionStart());
    return AssessmentApi.getAssessmentCompletedCount()
      .then((response) => {
        dispatch(dashboardAssessmentsCompletedActioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(dashboardAssessmentsCompletedActionFail(error));
      });
  };
};

export const dashboardAssessmentsRejectedActioSuccess = (count) => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_REJECTED_SUCCESS,
    count: count,
  };
};
export const dashboardAssessmentsRejectedActionFail = (error) => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_REJECTED_FAIL,
    error: error,
  };
};
export const dashboardAssessmentsRejectedActionStart = () => {
  return {
    type: actionTypes.DASHBOARD_ASSESSMENT_REJECTED_START,
  };
};

export const dashboardAssessmentsRejectedAction = () => {
  return (dispatch) => {
    dispatch(dashboardAssessmentsRejectedActionStart());
    return AssessmentApi.getAssessmentRejectedCount()
      .then((response) => {
        dispatch(dashboardAssessmentsRejectedActioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(dashboardAssessmentsRejectedActionFail(error));
      });
  };
};

// Resend Assessment
export const resendAssessmentSuccess = () => {
  return {
    type: actionTypes.ASSESSMENT_RESEND_SUCCESS,
  };
};
export const resendAssessmentFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_RESEND_FAIL,
    error: error,
  };
};
export const resendAssessmentStart = () => {
  return {
    type: actionTypes.ASSESSMENT_RESEND_START,
  };
};

export const resendAssessment = (role) => {
  return (dispatch) => {
    dispatch(resendAssessmentStart());
    return AssessmentApi.resend()
      .then((response) => {
        dispatch(resendAssessmentSuccess());
      })
      .catch((error) => {
        dispatch(resendAssessmentFail(error));
      });
  };
};

export const resendAssessmentBatch = (batch) => {
  return (dispatch) => {
    dispatch(resendAssessmentStart());
    return AssessmentApi.resendBatch(batch)
      .then((response) => {
        dispatch(resendAssessmentSuccess());
      })
      .catch((error) => {
        dispatch(resendAssessmentFail(error));
      });
  };
};

// Fetch Data Assessment Sync Errors
export const fetchAssessmentSyncErrorsPartialSuccess = (assessmentsData) => {
  return {
    type: actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_PARTIAL_SUCCESS,
    assessmentsData: assessmentsData,
  };
};
export const fetchAssessmentSyncErrorsSuccess = (payload) => {
  return {
    type: actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_SUCCESS,
    payload: payload,
  };
};
export const fetchAssessmentSyncErrorsFail = (error) => {
  return {
    type: actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_FAIL,
    error: error,
  };
};
export const fetchAssessmentSyncErrorsStart = () => {
  return {
    type: actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_START,
  };
};

export const fetchAssessmentSyncErrorsStop = () => {
  return {
    type: actionTypes.ASSESSMENT_SYNC_ERRORS_GET_FORCE_STOP,
  };
};

export const stopFetchAssessmentSyncErrors = () => {
  return (dispatch) => {
    return dispatch(() => {
      fetchAssessmentSyncErrorsStop();
    });
  };
};

// Fetch Assessment Sync Errors data from API
export const fetchAssessmentSyncErrors = (filters = {}, skip = 0, limit = 10) => {
  return (dispatch) => {
    dispatch(fetchAssessmentSyncErrorsStart());
    return AssessmentApi.getAssessmentSyncErrors(filters, skip, limit)
      .then((response) => {
        if (response !== null && response !== undefined && response !== '' && response.length > 0) {
          dispatch(fetchAssessmentSyncErrorsPartialSuccess(response));
        } else {
          dispatch(fetchAssessmentSyncErrorsSuccess(response));
        }
      })
      .catch((error) => {
        dispatch(fetchAssessmentSyncErrorsFail(error));
      });
  };
};

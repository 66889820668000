import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../../styles/login.scss';
import withRouter from 'util/withRouter';

class NotFound404Page extends Component {
  constructor(props) {
    super();
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.props.history) {
      this.props.history('/');
    } else {
      this.props.history(-1);
    }
  }

  render() {
    return (
      <div className="p-fluid">
        <div className="backgroundImage" />
        <div className="p-grid">
          <div className="p-col-12 p-lg-11 p-md-12 p-sm-11 loginContainer">
            <div className="p-col-12 p-lg-12 p-align-center loginDialog">
              <div className="brandingDivStyle">
                <span className="loginDivTitle">Not Found</span>
              </div>
              <div className="w3-row emptyRowStyle-big" />
              <div className="inputDivStyle">
                <button onClick={this.goBack}>Back</button>
                <p>404: Not Found Page</p>
                <Link to="/">Take me home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NotFound404Page);

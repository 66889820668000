import ErrorHandler from './errorResponseHandler';
import { axios } from './AuthApi';

class TraineeApi {
  /** Fetch all data Trainee */
  static getAllTrainee() {
    return axios
      .get('/Trainee/dynamicfilter?skip=0&limit=100')
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Export list trainee */
  static exportListTrainees() {
    return axios
      .get('/Trainee', {
        headers: {
          Accept: 'text/csv',
        },
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Progressive Fetch Trainee*/
  static getTraineeDynamicFilter(skip, limit) {
    return axios
      .get(`/Trainee/dynamicfilter?skip=${skip}&limit=${limit}`)
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Get Trainee detail */
  static getTrainee(id) {
    return axios
      .get(`/Trainee/${id}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Create new Trainee */
  static createTrainee(data) {
    const body = {
      traineeName: data.userName,
      email: data.email,
      traineePhoto: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    return axios
      .post(`/Trainee`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Insert Trainee */
  static insertTrainee(data) {
    const body = data;

    return axios
      .post(`/Trainee/Insert`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Update data Trainee */
  static updateTrainee(id, data) {
    const body = {
      traineeName: data.userName,
      email: data.email,
      traineePhoto: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      firstName: data.firstName,
      lastName: data.lastName,
      profileReportURL: data.profileReportURL,
    };

    return axios
      .put(`/Trainee/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Activate trainee status
   *  params active: boolean
   */
  static activateTrainee(id, active) {
    return axios
      .post(`/Trainee/${active ? 'activate' : 'deactivate'}/${id}`, {})
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static deleteTrainee(traineeId) {
    return axios
      .delete(`/Trainee/delete/${traineeId}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static syncTrainee() {
    return axios
      .post(`/ManualSync/person`, {})
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getSyncTraineeStatus() {
    return axios
      .get(`/ManualSync/person`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
}

export default TraineeApi;

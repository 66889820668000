import asyncComponent from '../AsyncComponent';

// Lazy Load Form Builder Management
export const AsyncTemplateManagementPage = asyncComponent(() => {
  return import('../../../features/templates/TemplateManagementPage');
});
export const AsyncTemplateDetailPage = asyncComponent(() => {
  return import('../../../features/templates/TemplateDetailPage');
});
export const AsyncTemplateCreatePage = asyncComponent(() => {
  return import('../../../features/templates/TemplateCreatePage');
});
export const AsyncTemplateEditPage = asyncComponent(() => {
  return import('../../../features/templates/TemplateEditPage');
});
export const AsyncCompetencyBulkInsertPage = asyncComponent(() => {
  return import('../../../features/templates/CompetencyBulkInsertPage');
});

import asyncComponent from '../AsyncComponent';

// Lazy Load Assessment Management
export const AsyncFormManagementPage = asyncComponent(() => {
  return import('../../../features/forms/FormManagementPage');
});

export const AsyncFormDetailPage = asyncComponent(() => {
  return import('../../../features/forms/FormDetailPage');
});

export const AsyncFormPrintPreview = asyncComponent(() => {
  return import('../../../features/forms/FormPrintPreviewPage');
});

export const AsyncAssessmentSyncErrorPage = asyncComponent(() => {
  return import('../../../features/forms/FormSyncErrorsPage');
});

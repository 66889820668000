import React, { Component } from 'react';
import '../../styles/styles.scss';
import * as actions from '../auth/actions';
import { Route, Routes } from 'react-router-dom';
//import { withRouter } from 'react-router-dom';
import withRouter from '../../util/withRouter';
import {
  AsyncFormDetailPage,
  AsyncFormManagementPage,
  AsyncFormPrintPreview,
  AsyncAssessmentSyncErrorPage,
} from '../../components/pages/routes/FormRoutes';
import {
  AsyncCompetencyBulkInsertPage,
  AsyncTemplateCreatePage,
  AsyncTemplateDetailPage,
  AsyncTemplateEditPage,
  AsyncTemplateManagementPage,
} from '../../components/pages/routes/TemplateRoutes';
import { AsyncForgotPassword, AsyncLogin, AsyncResetPassword } from '../../components/pages/routes/AuthRoutes';
import {
  AsyncTenantConfigurationPage,
  AsyncTenantCreatePage,
  AsyncTenantDetailPage,
  AsyncTenantEditPage,
  AsyncTenantManagementPage,
  AsyncPersonSyncErrorsPage,
  AsyncTenantVerifyPage,
} from '../../components/pages/routes/TenantRoutes';
import {
  AsyncTraineeCreatePage,
  AsyncTraineeDetailPage,
  AsyncTraineeEditPage,
  AsyncTraineeInsertPage,
  AsyncTraineeManagementPage,
} from '../../components/pages/routes/TraineeRoutes';
import {
  AsyncUserBulkInsertPage,
  AsyncUserChangePasswordPage,
  AsyncUserCreatePage,
  AsyncUserDetailPage,
  AsyncUserEditPage,
  AsyncUserManagementPage,
  AsyncUserProfileViewPage,
  AsyncUserRestrictedPage,
} from '../../components/pages/routes/UserRoutes';
import CacheBuster from '../../components/pages/CacheBuster';
import DashboardPage from '../dashboard/DashboardPage';
import LoginRoute from '../../components/pages/LoginRoute';
import Logout from '../auth/Logout';
import NotFound404Page from './NotFound404Page';
import { ProgressSpinner } from 'primereact/progressspinner';
import ProtectedRoute from '../../components/pages/ProtectedRoute';
import RestrictedPage from '../../components/pages/RestrictedPage';
import Roles from '../../domain/roles';
import { AsyncSendEmail } from '../../components/pages/routes/SendEmailRoutes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.props.authAction.loginCookiesCheckState().then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalCheck);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          this.intervalCheck = setInterval(() => {
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
          }, 1000);

          return (
            <div>
              {this.state.loading ? (
                LoadingSpinner()
              ) : (
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.All]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/" element={<DashboardPage />} />
                    <Route exact path="/dashboard" element={<DashboardPage />} />
                  </Route>

                  {/*User Routes*/}
                  <Route
                    exact
                    path="/users"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.SuperAdmin, Roles.TrainingAdmin, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                        exact
                      />
                    }
                  >
                    <Route exact path="/users" element={<AsyncUserManagementPage />} />
                    <Route exact path="/users/create" element={<AsyncUserCreatePage />} />
                    <Route exact path="/users/edit/:id" element={<AsyncUserEditPage />} />
                    <Route exact path="/users/view/:id" element={<AsyncUserDetailPage />} />
                    <Route exact path="/users/change-password/:id" element={<AsyncUserChangePasswordPage />} />
                  </Route>

                  <Route
                    exact
                    path="/users"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.All]}
                        stateRoles={this.props.stateRoles}
                        exact
                      />
                    }
                  >
                    <Route exact path="/users/profileview/:id" element={<AsyncUserProfileViewPage />} />
                    <Route exact path="/users/import" element={<AsyncUserBulkInsertPage />} />
                  </Route>

                  {/*Trainee Routes*/}
                  <Route
                    exact
                    path="/trainees"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.TrainingAdmin, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/trainees" element={<AsyncTraineeManagementPage />} />
                    <Route exact path="/trainees/create" element={<AsyncTraineeCreatePage />} />
                    <Route exact path="/trainees/edit/:id" element={<AsyncTraineeEditPage />} />
                    <Route exact path="/trainees/view/:id" element={<AsyncTraineeDetailPage />} />
                    <Route exact path="/trainees/import" element={<AsyncTraineeInsertPage />} />
                  </Route>

                  {/* Form builder Route */}
                  <Route
                    exact
                    path="/templates"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.TrainingAdmin, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/templates" element={<AsyncTemplateManagementPage />} />
                    <Route exact path="/templates/create" element={<AsyncTemplateCreatePage />} />
                    <Route exact path="/templates/edit/:id" element={<AsyncTemplateEditPage />} />
                    <Route exact path="/templates/competency-import" element={<AsyncCompetencyBulkInsertPage />} />
                  </Route>

                  <Route
                    exact
                    path="/templates"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.All]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/templates/view/:id" element={<AsyncTemplateDetailPage />} />
                  </Route>

                  {/* Assessment Route */}
                  <Route
                    exact
                    path="/forms"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[
                          Roles.TrainingAdmin,
                          Roles.WorkplaceManager,
                          Roles.TenantAdmin,
                          Roles.Assessor,
                          Roles.SubjectMatterExpert,
                        ]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/forms" element={<AsyncFormManagementPage />} />
                    <Route exact path="/forms/view/:id" element={<AsyncFormDetailPage />} />
                  </Route>

                  <Route
                    exact
                    path="/form-print-preview/:id"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[
                          Roles.TrainingAdmin,
                          Roles.WorkplaceManager,
                          Roles.TenantAdmin,
                          Roles.Assessor,
                          Roles.SubjectMatterExpert,
                        ]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/form-print-preview/:id" element={<AsyncFormPrintPreview />} />
                  </Route>

                  <Route
                    exact
                    path="/forms/edit/:id"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.TrainingAdmin, Roles.WorkplaceManager, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact notLayout={true} path="/forms/edit/:id" element={<RestrictedPage />} />
                  </Route>
                  {/* Tenant Route */}
                  <Route
                    exact
                    path="/tenants"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.SuperAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/tenants" element={<AsyncTenantManagementPage />} />
                    <Route exact path="/tenants/create" element={<AsyncTenantCreatePage />} />
                    <Route exact path="/tenants/edit/:id" element={<AsyncTenantEditPage />} />
                    <Route exact path="/tenants/view/:id" element={<AsyncTenantDetailPage />} />
                    <Route exact path="/tenants/:id/verify/:token" element={<AsyncTenantVerifyPage />} />
                  </Route>

                  <Route
                    exact
                    path="/tenant-configuration"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.SuperAdmin, Roles.TrainingAdmin, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/tenant-configuration" element={<AsyncTenantConfigurationPage />} />
                  </Route>

                  <Route
                    exact
                    path="/person-sync-alerts"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.SuperAdmin, Roles.TrainingAdmin, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/person-sync-alerts" element={<AsyncPersonSyncErrorsPage />} />
                  </Route>

                  <Route
                    exact
                    path="/form-sync-alerts"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.SuperAdmin, Roles.TrainingAdmin, Roles.TenantAdmin]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/form-sync-alerts" element={<AsyncAssessmentSyncErrorPage />} />
                  </Route>

                  {/* Auth Route */}
                  <Route exact path="/logout" element={<Logout />} />
                  <Route exact path="/login" isAuthenticated={this.props.isAuthenticated} element={<AsyncLogin />} />
                  <Route exact path="/forgot-password" element={<AsyncForgotPassword />} />
                  <Route exact path="/reset-password/:resetpasswordrequestId" element={<AsyncResetPassword />} />
                  <Route exact path="/user-restricted" element={<AsyncUserRestrictedPage />} />

                  {/* Not Found Route */}
                  <Route path="*" element={<NotFound404Page />} />

                  {/* Send test email */}
                  <Route
                    exact
                    path="/testsendemail"
                    element={
                      <ProtectedRoute
                        isAuthenticated={this.props.isAuthenticated}
                        roles={[Roles.All]}
                        stateRoles={this.props.stateRoles}
                      />
                    }
                  >
                    <Route exact path="/testsendemail" element={<AsyncSendEmail />} />
                  </Route>
                </Routes>
              )}
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

function LoadingSpinner() {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div className="p-col-12" style={{ position: 'fixed', left: '50%', top: '50%' }}>
            <h4>Loading ... </h4>
            <ProgressSpinner
              style={{ width: '80px', height: '70px' }}
              strokeWidth="3"
              fill="#EEEEEE"
              animationDuration=".5s"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    stateRoles: state.auth.roles,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authAction: bindActionCreators(actions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

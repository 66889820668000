import * as actionTypes from '../../app/store/actionTypes';

import { updateState } from '../../util/updateState';

const initialState = {
  resetPasswordRequest: null,
  error: null,
  loading: false,
};

// Fetch Reset Password Request
const fetchResetPasswordRequestStart = (state, action) => {
  return updateState(state, { loading: true });
};
const fetchResetPasswordRequestSuccess = (state, action) => {
  return updateState(state, {
    resetPasswordRequest: action.resetPasswordRequest,
    loading: false,
  });
};
const fetchResetPasswordRequestFail = (state, action) => {
  return updateState(state, { loading: false });
};

// Create Reset Password Request
const forgotPasswordStart = (state, action) => {
  return updateState(state, { error: null, loading: true });
};

const forgotPasswordSuccess = (state, action) => {
  return updateState(state, {
    error: false,
    loading: false,
  });
};

const forgotPasswordFail = (state, action) => {
  return updateState(state, {
    error: action.error,
    loading: false,
  });
};

// Process Reset Password Request
const resetPasswordStart = (state, action) => {
  return updateState(state, { error: null, loading: true });
};

const resetPasswordSuccess = (state, action) => {
  return updateState(state, {
    error: false,
    loading: false,
  });
};

const resetPasswordFail = (state, action) => {
  return updateState(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_REQUEST_FETCH_START:
      return fetchResetPasswordRequestStart(state, action);
    case actionTypes.RESET_PASSWORD_REQUEST_FETCH_SUCCESS:
      return fetchResetPasswordRequestSuccess(state, action);
    case actionTypes.RESET_PASSWORD_REQUEST_FETCH_FAIL:
      return fetchResetPasswordRequestFail(state, action);
    case actionTypes.FORGOT_PASSWORD_START:
      return forgotPasswordStart(state, action);
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_FAIL:
      return forgotPasswordFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

interface IAppTopbarProps {
  onToggleMenu: () => void;
}

const AppTopbar: React.FC<IAppTopbarProps> = ({ onToggleMenu }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <div className="app-topbar">
      <div className="app-topbar-menu">
        <button onClick={onToggleMenu}>
          <span className="pi pi-bars" />
        </button>
      </div>

      <div className="app-topbar-logout">
        <Button
          className="p-button-secondary p-button-sm"
          icon="pi pi-sign-out"
          label="Logout"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default AppTopbar;

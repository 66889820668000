import React from 'react';
import { Toolbar as PrToolbar } from 'primereact/toolbar';

interface IToolbarProps {
  buttons?: Array<React.ReactElement | null | undefined>;
  title: string;
}

const Toolbar: React.FC<IToolbarProps> = ({ buttons, title }) => {
  return <PrToolbar className="toolbar" left={<h4 className="toolbar-heading">{title}</h4>} right={buttons} />;
};

export type { IToolbarProps };
export default Toolbar;

import * as actionTypes from '../../app/store/actionTypes';

import { updateState } from '../../util/updateState';
import { fetchTenantKeys } from './actions';

const initialState = {
  tenants: [],
  tenant: {},
  confData: {},
  loading: false,
  isVerified: false,
  statusSyncedAssessor: {},
  statusSyncedCompetency: {},
  statusSyncedTrainee: {},
  syncedAssessors: [],
  syncedTrainees: [],
  syncedCompetencies: [],
  failedPersonSynceHistory: [],
  error: null,
  activeAssessor: null,
  tenantKeys: [],
};

// Fetch list tenant
const fetchTenantsStart = (state, action) => {
  return updateState(state, { loading: true });
};

const fetchTenantsSuccess = (state, action) => {
  return updateState(state, {
    tenants: action.tenantsData,
    tenant: {},
    loading: false,
  });
};

const fetchTenantsFail = (state, action) => {
  return updateState(state, { loading: false });
};

// Get Details
const fetchTenantStart = (state, action) => {
  return updateState(state, { loading: true, tenant: {} });
};

const fetchTenantSuccess = (state, action) => {
  return updateState(state, {
    tenant: action.tenantData,
    loading: false,
  });
};

const fetchTenantFail = (state, action) => {
  return updateState(state, { loading: false });
};

//Get tenant keys
const fetchTenantKeysStart = (state, action) => {
  return updateState(state, { loading: true });
};

const fetchTenantKeysSuccess = (state, action) => {
  return updateState(state, {
    tenantKeys: action.tenantKeys,
    loading: false,
  });
};

const fetchTenantKeysFail = (state, action) => {
  return updateState(state, { loading: false });
};

// Get Active Assessor
const fetchActiveAssessorStart = (state, action) => {
  return updateState(state, { loading: true, activeAssessor: null, error: null });
};

const fetchActiveAssessorSuccess = (state, action) => {
  return updateState(state, {
    activeAssessor: action.activeAssessor,
    loading: false,
    error: null,
  });
};

const fetchActiveAssessorFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// Create new Tenant
const createTenantStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};

const createTenantSuccess = (state, action) => {
  return updateState(state, {
    tenant: action.tenantData,
    loading: false,
    error: null,
  });
};

const createTenantFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// Create new Tenant Key
const createTenantKeyStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};

const createTenantKeySuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    error: null,
  });
};

const createTenantKeyFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// Disable Tenant Key
const disableTenantKeyStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};

const disableTenantKeySuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    error: null,
  });
};

const disableTenantKeyFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// Delete Tenant Key
const deleteTenantKeyStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};

const deleteTenantKeySuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    error: null,
  });
};

const deleteTenantKeyFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// Verified Tenant
const verifyTenantStart = (state, action) => {
  return updateState(state, { loading: true, isVerified: false });
};

const verifyTenantSuccess = (state, action) => {
  return updateState(state, { loading: false, isVerified: true });
};

const verifyTenantFail = (state, action) => {
  return updateState(state, { loading: false, isVerified: false });
};

// Update Tenant
const updateTenantStart = (state, action) => {
  return updateState(state, { loading: true });
};

const updateTenantSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    error: '',
  });
};

const updateTenantFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Update Tenant Configuration
const updateTenantConfigurationStart = (state, action) => {
  return updateState(state, { loading: true });
};

const updateTenantConfigurationSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
  });
};

const updateTenantConfigurationFail = (state, action) => {
  return updateState(state, { loading: false });
};

// Update Tenant WPM Daily Email Configuration
const updateTenantWPMDailyEmailConfigurationStart = (state, action) => {
  return updateState(state, { loading: true });
};

const updateTenantWPMDailyEmailConfigurationSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
  });
};

const updateTenantWPMDailyEmailConfigurationFail = (state, action) => {
  return updateState(state, { loading: false });
};

// Get Tenant Configuration
const fetchTenantConfigurationStart = (state, action) => {
  return updateState(state, { loading: true, confData: {} });
};

const fetchTenantConfigurationSuccess = (state, action) => {
  return updateState(state, {
    confData: action.confData,
    loading: false,
  });
};

const fetchTenantConfigurationFail = (state, action) => {
  return updateState(state, { loading: false });
};

// Tenant Synchronization
const tenantSyncStart = (state, action) => {
  return updateState(state, {
    loading: true,
    syncedAssessors: [],
    syncedCompetencies: [],
    syncedTrainees: [],
    error: null,
  });
};

const tenantSyncStatusStart = (state, action) => {
  return updateState(state, {
    loading: true,
    statusSyncedAssessor: {},
    statusSyncedCompetency: {},
    statusSyncedTrainee: {},
    error: null,
  });
};

const tenantSyncAssessorSuccess = (state, action) => {
  return updateState(state, {
    syncedAssessors: action.syncedAssessors,
  });
};

const tenantSyncStatusAssessorSuccess = (state, action) => {
  return updateState(state, {
    statusSyncedAssessor: action.statusSyncedAssessor,
  });
};

const tenantSyncCompetencySuccess = (state, action) => {
  return updateState(state, {
    syncedCompetencies: action.syncedCompetencies,
  });
};

const tenantSyncStatusCompetencySuccess = (state, action) => {
  return updateState(state, {
    statusSyncedCompetency: action.statusSyncedCompetency,
  });
};

const tenantSyncTraineeSuccess = (state, action) => {
  return updateState(state, {
    syncedTrainees: action.syncedTrainees,
  });
};

const tenantSyncStatusTraineeSuccess = (state, action) => {
  return updateState(state, {
    statusSyncedTrainee: action.statusSyncedTrainee,
  });
};

const tenantSyncFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

const tenantSyncStatusFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// Fetch Sync Person Failed History
const fetchTenantSyncPersonFailedHistoryStart = (state, action) => {
  return updateState(state, { loading: true, failedPersonSynceHistory: [] });
};
const fetchTenantSyncPersonFailedHistorySuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    failedPersonSynceHistory: action.historyData,
  });
};
const fetchTenantSyncPersonFailedHistoryFail = (state, action) => {
  return updateState(state, { loading: false, failedPersonSynceHistory: [] });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TENANT_FETCH_START:
      return fetchTenantsStart(state, action);
    case actionTypes.TENANT_FETCH_SUCCESS:
      return fetchTenantsSuccess(state, action);
    case actionTypes.TENANT_FETCH_FAIL:
      return fetchTenantsFail(state, action);
    case actionTypes.TENANT_GET_DETAIL_START:
      return fetchTenantStart(state, action);
    case actionTypes.TENANT_GET_DETAIL_SUCCESS:
      return fetchTenantSuccess(state, action);
    case actionTypes.TENANT_GET_DETAIL_FAIL:
      return fetchTenantFail(state, action);
    case actionTypes.TENANT_GET_KEYS_START:
      return fetchTenantKeysStart(state, action);
    case actionTypes.TENANT_GET_KEYS_SUCCESS:
      return fetchTenantKeysSuccess(state, action);
    case actionTypes.TENANT_GET_KEYS_FAIL:
      return fetchTenantKeysFail(state, action);
    case actionTypes.TENANT_GET_ACTIVE_ASSESSOR_START:
      return fetchActiveAssessorStart(state, action);
    case actionTypes.TENANT_GET_ACTIVE_ASSESSOR_SUCCESS:
      return fetchActiveAssessorSuccess(state, action);
    case actionTypes.TENANT_GET_ACTIVE_ASSESSOR_FAIL:
      return fetchActiveAssessorFail(state, action);
    case actionTypes.TENANT_ADD_NEW_START:
      return createTenantStart(state, action);
    case actionTypes.TENANT_ADD_NEW_SUCCESS:
      return createTenantSuccess(state, action);
    case actionTypes.TENANT_ADD_NEW_FAIL:
      return createTenantFail(state, action);
    case actionTypes.TENANT_KEY_ADD_NEW_START:
      return createTenantKeyStart(state, action);
    case actionTypes.TENANT_KEY_ADD_NEW_SUCCESS:
      return createTenantKeySuccess(state, action);
    case actionTypes.TENANT_KEY_ADD_NEW_FAIL:
      return createTenantKeyFail(state, action);
    case actionTypes.TENANT_KEY_DISABLE_START:
      return disableTenantKeyStart(state, action);
    case actionTypes.TENANT_KEY_DISABLE_SUCCESS:
      return disableTenantKeySuccess(state, action);
    case actionTypes.TENANT_KEY_DISABLE_FAIL:
      return disableTenantKeyFail(state, action);
    case actionTypes.TENANT_KEY_DELETE_START:
      return deleteTenantKeyStart(state, action);
    case actionTypes.TENANT_KEY_DELETE_SUCCESS:
      return deleteTenantKeySuccess(state, action);
    case actionTypes.TENANT_KEY_DELETE_FAIL:
      return deleteTenantKeyFail(state, action);
    case actionTypes.TENANT_VERIFY_START:
      return verifyTenantStart(state, action);
    case actionTypes.TENANT_VERIFY_SUCCESS:
      return verifyTenantSuccess(state, action);
    case actionTypes.TENANT_VERIFY_FAIL:
      return verifyTenantFail(state, action);
    case actionTypes.TENANT_EDIT_START:
      return updateTenantStart(state, action);
    case actionTypes.TENANT_EDIT_SUCCESS:
      return updateTenantSuccess(state, action);
    case actionTypes.TENANT_EDIT_FAIL:
      return updateTenantFail(state, action);
    case actionTypes.TENANT_CONFIGURATION_FETCH_START:
      return fetchTenantConfigurationStart(state, action);
    case actionTypes.TENANT_CONFIGURATION_FETCH_SUCCESS:
      return fetchTenantConfigurationSuccess(state, action);
    case actionTypes.TENANT_CONFIGURATION_FETCH_FAIL:
      return fetchTenantConfigurationFail(state, action);
    case actionTypes.TENANT_CONFIGURATION_UPDATE_START:
      return updateTenantConfigurationStart(state, action);
    case actionTypes.TENANT_CONFIGURATION_UPDATE_SUCCESS:
      return updateTenantConfigurationSuccess(state, action);
    case actionTypes.TENANT_CONFIGURATION_UPDATE_FAIL:
      return updateTenantConfigurationFail(state, action);
    case actionTypes.TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_START:
      return updateTenantWPMDailyEmailConfigurationStart(state, action);
    case actionTypes.TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_SUCCESS:
      return updateTenantWPMDailyEmailConfigurationSuccess(state, action);
    case actionTypes.TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_FAIL:
      return updateTenantWPMDailyEmailConfigurationFail(state, action);
    case actionTypes.TENANT_SYNC_START:
      return tenantSyncStart(state, action);
    case actionTypes.TENANT_SYNC_STATUS_START:
      return tenantSyncStatusStart(state, action);
    case actionTypes.TENANT_SYNC_FAIL:
      return tenantSyncFail(state, action);
    case actionTypes.TENANT_SYNC_STATUS_FAIL:
      return tenantSyncStatusFail(state, action);
    case actionTypes.TENANT_SYNC_ASSESSOR_SUCCESS:
      return tenantSyncAssessorSuccess(state, action);
    case actionTypes.TENANT_SYNC_COMPETENCY_SUCCESS:
      return tenantSyncCompetencySuccess(state, action);
    case actionTypes.TENANT_SYNC_TRAINEE_SUCCESS:
      return tenantSyncTraineeSuccess(state, action);
    case actionTypes.TENANT_SYNC_STATUS_ASSESSOR_SUCCESS:
      return tenantSyncStatusAssessorSuccess(state, action);
    case actionTypes.TENANT_SYNC_STATUS_COMPETENCY_SUCCESS:
      return tenantSyncStatusCompetencySuccess(state, action);
    case actionTypes.TENANT_SYNC_STATUS_TRAINEE_SUCCESS:
      return tenantSyncStatusTraineeSuccess(state, action);
    case actionTypes.TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_START:
      return fetchTenantSyncPersonFailedHistoryStart(state, action);
    case actionTypes.TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_SUCCESS:
      return fetchTenantSyncPersonFailedHistorySuccess(state, action);
    case actionTypes.TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_FAIL:
      return fetchTenantSyncPersonFailedHistoryFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import * as actionTypes from '../../app/store/actionTypes';

import { updateState } from '../../util/updateState';

const initialState = {
  loading: false,
  sent: false,
  error: null,
};

// Update Tenant
const sendEmailStart = (state, action) => {
  return updateState(state, { loading: true });
};
const sendEmailSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    sent: action.sent,
  });
};
const sendEmailFail = (state, action) => {
  return updateState(state, { loading: false, sent: false, error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_EMAIL_START:
      return sendEmailStart(state, action);
    case actionTypes.SEND_EMAIL_SUCCESS:
      return sendEmailSuccess(state, action);
    case actionTypes.SEND_EMAIL_FAIL:
      return sendEmailFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import asyncComponent from '../AsyncComponent';

// Lazy load User management
export const AsyncUserManagementPage = asyncComponent(() => {
  return import('../../../features/users/UserManagementPage');
});
export const AsyncUserCreatePage = asyncComponent(() => {
  return import('../../../features/users/UserCreatePage');
});
export const AsyncUserEditPage = asyncComponent(() => {
  return import('../../../features/users/UserEditPage');
});
export const AsyncUserDetailPage = asyncComponent(() => {
  return import('../../../features/users/UserDetailPage');
});
export const AsyncUserChangePasswordPage = asyncComponent(() => {
  return import('../../../features/users/UserChangePasswordPage');
});
export const AsyncUserProfileViewPage = asyncComponent(() => {
  return import('../../../features/users/UserProfileViewPage');
});
export const AsyncUserBulkInsertPage = asyncComponent(() => {
  return import('../../../features/users/UserBulkInsertPage');
});
export const AsyncUserRestrictedPage = asyncComponent(() => {
  return import('../UserRestrictedPage');
});

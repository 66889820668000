/*
react -router-dom does not provide withRouter anymore so this custom fucntion component was made to work with the components which are class components
*/

import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let history = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        location={location}
        history={history}
        params={params}
        router={{ location, history, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;

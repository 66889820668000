import Roles from '../../domain/roles';

export const menuNav = [
  {
    label: 'Dashboard',
    icon: 'pi pi-fw pi-home',
    to: '/',
    roles: [Roles.All],
  },
  {
    label: 'Templates',
    icon: 'pi pi-fw pi-file',
    to: '/templates',
    roles: [Roles.TrainingAdmin, Roles.TenantAdmin],
  },
  {
    label: 'Forms',
    icon: 'pi pi-fw pi-pencil',
    to: '/forms',
    roles: [Roles.TrainingAdmin, Roles.WorkplaceManager, Roles.TenantAdmin, Roles.Assessor, Roles.SubjectMatterExpert],
  },
  {
    label: 'Users',
    icon: 'pi pi-fw pi-users',
    to: '/users',
    roles: [Roles.SuperAdmin, Roles.TrainingAdmin, Roles.TenantAdmin],
  },
  {
    label: 'Trainees',
    icon: 'pi pi-fw pi-users',
    to: '/trainees',
    roles: [Roles.TrainingAdmin, Roles.TenantAdmin],
  },
  {
    label: 'Tenants',
    icon: 'pi pi-fw pi-sitemap',
    to: '/tenants',
    roles: [Roles.SuperAdmin],
  },
  {
    label: 'Tenant Configuration',
    icon: 'pi pi-fw pi-cog',
    to: '/tenant-configuration',
    roles: [Roles.SuperAdmin, Roles.TrainingAdmin, Roles.TenantAdmin],
  },
  {
    label: 'Person Sync Alerts',
    icon: 'pi pi-fw pi-exclamation-triangle',
    to: '/person-sync-alerts',
    roles: [Roles.SuperAdmin, Roles.TenantAdmin, Roles.TrainingAdmin],
  },
  {
    label: 'Form Sync Alerts',
    icon: 'pi pi-fw pi-exclamation-triangle',
    to: '/form-sync-alerts',
    roles: [Roles.SuperAdmin, Roles.TenantAdmin, Roles.TrainingAdmin],
  },
];

const Roles = {
  All: 'All',
  Assessor: 'Assessor',
  SubjectMatterExpert: 'SME',
  SuperAdmin: 'SuperAdmin',
  TenantAdmin: 'TenantAdmin',
  TrainingAdmin: 'TrainingAdmin',
  User: 'User',
  WorkplaceManager: 'WPM',
};

export default Roles;

import * as actionTypes from '../../app/store/actionTypes';

import AuthApi from '../../app/api-client/AuthApi';
import getTimeIn from '../../util/getTimeIn';
import { jwtDecode as jwt_decode } from 'jwt-decode';

const authObj = {
  type: null,
  token: null,
  username: null,
  tenant: null,
  tenantId: null,
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, username, tenant, tenantId) => {
  authObj.type = actionTypes.AUTH_SUCCESS;
  authObj.token = token;
  authObj.username = username;
  authObj.tenant = tenant;
  authObj.tenantId = tenantId;
  return {
    type: authObj.type,
    token: authObj.token,
    username: authObj.username,
    tenant: authObj.tenant,
    tenantId: authObj.tenantId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authLogoutStart = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_START,
  };
};

export const authLogoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_SUCCESS,
  };
};

export const authLogoutFail = (error) => {
  return {
    type: actionTypes.AUTH_LOGOUT_FAIL,
    error: error,
  };
};

export const checkAuthTimeout = (expiresIn) => {
  return (dispatch) => {
    const range = expiresIn - getTimeIn();

    setTimeout(() => {
      dispatch(authLogout());
    }, range * 1000);
  };
};

export const checkRoles = () => {
  return (dispatch) => {
    return new Promise(() => {
      const token = authObj.token;
      if (token) {
        const decodeToken = jwt_decode(token);
        dispatch(setRoles(decodeToken.Roles));
      }
    });
  };
};

export const authLogout = () => {
  return (dispatch) => {
    dispatch(authLogoutStart());
    return AuthApi.logOut()
      .then((res) => {
        dispatch(authLogoutSuccess());
      })
      .catch((err) => {
        dispatch(authLogoutFail(err));
      });
  };
};

export const auth = (tenant, userid, password) => {
  return (dispatch) => {
    dispatch(authStart());

    return AuthApi.login(tenant, userid, password)
      .then((res) => {
        if (res.token) {
          localStorage.setItem('expiresIn', res.expiresIn);
          localStorage.setItem('username', res.name);
          localStorage.setItem('tenant', res.tenant);
          localStorage.setItem('tenantId', res.tenantId);
          localStorage.setItem('roles', res.roles);
          localStorage.setItem('firstName', res.firstName);
          localStorage.setItem('lastName', res.lastName);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('IsIntegrationTenant', res.IsIntegrationTenant);

          dispatch(setRoles(res.roles));
          dispatch(authSuccess(res.token, res.name, res.tenant, res.tenantId));
          dispatch(resetTraineeList());
          // dispatch(checkAuthTimeout(res.expiresIn));
        } else {
          dispatch(authFail(res));
        }
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const setRoles = (roles) => {
  return {
    type: actionTypes.SET_ROLES,
    roles: roles,
  };
};

export const authCheckState = () => {
  return async (dispatch) => {
    dispatch(authStart());
    const expiresIn = localStorage.getItem('expiresIn');
    if (expiresIn < getTimeIn()) {
      try {
        const res = await AuthApi.refreshToken();
        if (res.token) {
          // Fullfill new token payload
          localStorage.setItem('expiresIn', res.expiresIn);
          localStorage.setItem('username', res.name);
          localStorage.setItem('tenant', res.tenant);
          localStorage.setItem('tenantId', res.tenantId);
          localStorage.setItem('roles', res.roles);
          localStorage.setItem('firstName', res.firstName);
          localStorage.setItem('lastName', res.lastName);
          localStorage.setItem('userId', res.userId);

          dispatch(setRoles(res.roles));
          dispatch(authSuccess(res.token, res.name, res.tenant, res.tenantId));
          dispatch(resetTraineeList());
          // dispatch(checkAuthTimeout(res.expiresIn));
        } else {
          dispatch(authLogout());
        }
      } catch (err) {
        dispatch(authLogout());
      }
    }
  };
};

export const resetTraineeList = () => {
  return {
    type: actionTypes.TRAINEES_RESET,
  };
};

export const loginCookiesCheckState = () => {
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const res = await AuthApi.refreshToken();
      if (res.token) {
        // Fullfill new token payload
        localStorage.setItem('expiresIn', res.expiresIn);
        localStorage.setItem('username', res.name);
        localStorage.setItem('tenant', res.tenant);
        localStorage.setItem('tenantId', res.tenantId);
        localStorage.setItem('roles', res.roles);
        localStorage.setItem('firstName', res.firstName);
        localStorage.setItem('lastName', res.lastName);
        localStorage.setItem('userId', res.userId);

        dispatch(setRoles(res.roles));
        dispatch(authSuccess(res.token, res.name, res.tenant, res.tenantId));
      } else {
        dispatch(authLogout());
      }
    } catch (err) {
      dispatch(authLogout());
    }
  };
};

import PageBreadCrumb from './PageBreadCrumb';
import PageSpinner from './PageSpinner';
import Toolbar, { IToolbarProps } from 'components/ui/Toolbar';
import { MenuItem } from 'primereact/menuitem';
import { Messages } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import React from 'react';

interface IPageProps {
  breadcrumbProps?: MenuItem[];
  toolbarProps?: IToolbarProps;
  toastRef?: React.Ref<any>;
  messagesRef?: React.Ref<any>;
  loading?: boolean;
  children?: any;
}

const Page: React.FC<IPageProps> = ({ children, breadcrumbProps, loading, messagesRef, toastRef, toolbarProps }) => {
  return (
    <main>
      {loading && <PageSpinner />}
      {breadcrumbProps && <PageBreadCrumb items={breadcrumbProps} />}
      {messagesRef && <Messages ref={messagesRef} />}
      {toastRef && <Toast ref={toastRef} />}
      {toolbarProps && <Toolbar {...toolbarProps} />}
      {children}
    </main>
  );
};

export default Page;

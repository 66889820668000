import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import Layout from './Layout';
import Roles from '../../domain/roles';
import { Outlet } from 'react-router-dom';
import withRouter from '../../util/withRouter';

/**
 * Route guard. Protect routes from unauthorised user roles.
 */
const ProtectedRoute = ({
  roles,
  stateRoles,
  notLayout,
  isAuthenticated,
  location,
  history,
  param,
  router,
  ...rest
}) => {
  const arrStateRoles = stateRoles?.split(',') ?? [];
  const allowed = roles.some((role) => arrStateRoles.some((role2) => role === role2));

  return !isAuthenticated ? (
    <Navigate to={{ pathname: '/login', state: { from: location.pathname } }} />
  ) : allowed || roles[0] === Roles.All ? (
    notLayout ? (
      <Outlet location history />
    ) : (
      <Layout>
        <Outlet location history />
      </Layout>
    )
  ) : (
    <Navigate to={{ pathname: '/', state: { from: location.pathname } }} />
  );
};

export default withRouter(ProtectedRoute);

import ErrorHandler from './errorResponseHandler';
import { axios } from './AuthApi';
import getLabelRole from '../../util/getLabelRole';
import itemLocal from '../../util/getItemLocal';

class UserApi {
  /** Fetch all data User */
  static getAllUser() {
    return axios
      .get('/User')
      .then((res) => {
        const response = res.data;
        const updateResponse = response.map((user) => {
          return {
            ...user,
            role: getLabelRole(user.userRoles),
            fullName: `${user.firstName ? user.firstName : ''}${user.lastName ? ' ' + user.lastName : ''}`,
          };
        });

        let sortedList = updateResponse.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static exportAllUsers() {
    return axios
      .get('/User', {
        headers: {
          Accept: 'text/csv',
        },
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAllWPMUser() {
    return axios
      .get('/User/wpm')
      .then((res) => {
        const response = res.data;
        const updateResponse = response.map((user) => {
          return {
            ...user,
            role: getLabelRole(user.userRoles),
            fullName: `${user.firstName ? user.firstName : ''}${user.lastName ? ' ' + user.lastName : ''}`,
          };
        });

        let sortedList = updateResponse.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Get User detail */
  static getUser(id) {
    return axios
      .get(`/User/${id}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Create new User */
  static createUser(data) {
    const body = {
      userName: data.userName,
      email: data.email,
      password: data.password,
      userRoles: data.userRoles,
      // userPhoto: data.userPhoto,
      userPhoto: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      firstName: data.firstName,
      lastName: data.lastName,
      tenant: itemLocal.tenant,
      tenantId: itemLocal.tenantId,
    };

    return axios
      .post(`/User`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static bulkInsertUser(tenantId, data) {
    const body = [];

    data.forEach((element) => {
      let reqData = {};
      reqData.userId = element.userId ? element.userId : '';
      reqData.userName = element.userName;
      reqData.email = element.email;
      reqData.password = element.password ? element.password : '';
      reqData.role = element.role;
      reqData.firstName = element.firstName;
      reqData.lastName = element.lastName;
      reqData.userPhoto = element.userPhoto ? element.userPhoto : '';
      reqData.isFromIntegration = false;
      reqData.isEnabled = true;
      body.push(reqData);
    });

    return axios
      .post(`/User/bulkinsert`, body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Update data user */
  static updateUser(id, data) {
    const body = {
      userName: data.userName,
      email: data.email,
      userRoles: data.userRoles,
      // userPhoto: data.userPhoto,
      userPhoto: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      firstName: data.firstName,
      lastName: data.lastName,
      tenant: itemLocal.tenant,
      tenantId: itemLocal.tenantId,
    };

    return axios
      .put(`/User/${id}`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Change password user */
  static changePasswordUser(data) {
    const body = {
      userId: data.userId,
      newPassword: data.newPassword,
    };

    return axios
      .post(`/User/changePassword`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Change password user */
  static changeMyPassword(data) {
    const body = {
      userId: data.userId,
      newPassword: data.newPassword,
    };

    return axios
      .post(`/User/me/changePassword`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Activate user status
   *  params active: boolean
   */
  static activateUser(id, active) {
    return axios
      .post(`/User/${active ? 'activate' : 'deactivate'}/${id}`, {})
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static syncAssessor() {
    return axios
      .post(`/ManualSync/assessor`, {})
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getSyncAssessorStatus() {
    return axios
      .get(`/ManualSync/assessor`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Get ny User detail
   *
   */
  static getMyUserDetail() {
    return axios
      .get(`/User/my`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /** Update my user detail*/
  static updateMyUserDetail(data) {
    const body = {
      userName: data.userName,
      email: data.email,
      // userPhoto: data.userPhoto,
      userPhoto: `${window.location.origin}/static/media/profile.7bc5f809.png`,
      firstName: data.firstName,
      lastName: data.lastName,
      tenant: itemLocal.tenant,
      tenantId: itemLocal.tenantId,
    };

    return axios
      .put(`/User/my`, body)
      .then((res) => {
        if (res.status === 200) {
          return {
            updated: true,
          };
        } else {
          return {
            updated: false,
          };
        }
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
}

export default UserApi;

import * as CONSTANTS from '../domain/constants';

/**
 *  Rewrite role value form server to use label instead
 * @param {*} role
 */
function getLabelRole(userRoles) {
  const results = userRoles.map((userRole) => {
    const result = CONSTANTS.USER_ROLES_SUPER_ADMIN.findIndex((r) => r.value === userRole.role);
    return CONSTANTS.USER_ROLES_SUPER_ADMIN[result].label;
  });
  return results.join(', ');
}

export default getLabelRole;

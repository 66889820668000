import axios from 'axios';
import instanceInterceptor from './interceptorService';

const assessmentAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

class instance {
  static assessmentInstance() {
    instanceInterceptor.responseInterceptor(assessmentAxiosInstance);
    instanceInterceptor.requestInterceptor(assessmentAxiosInstance);
    return assessmentAxiosInstance;
  }
}

export default instance;

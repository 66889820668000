import React, { Component } from 'react';
import './../../styles/login.scss';
import withRouter from 'util/withRouter';

class RestrictedPage extends Component {
  constructor(props) {
    super();
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history(-1);
  }

  render() {
    return (
      <div className="p-fluid">
        <div className="backgroundImage" />
        <div className="p-grid">
          <div className="p-col-12 p-lg-11 p-md-12 p-sm-11 loginContainer">
            <div className="p-col-12 p-lg-12 p-align-center loginDialog">
              <div className="w3-row emptyRowStyle-big" />
              <div className="inputDivStyle">
                <p>You are not allowed to access this page</p>
                <button onClick={this.goBack}>Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RestrictedPage);

import React from 'react';

interface IDashboardCardGroupProps {
  children?: React.ReactElement[];
  heading: string;
  visible?: boolean;
}

const DashboardCardGroup: React.FC<IDashboardCardGroupProps> = ({ children, heading, visible }) => {
  return visible ? (
    <div>
      <div className="grid">
        <div className="col-12 lg-4">
          <h4 className="primary-heading">{heading}</h4>
        </div>
      </div>

      <div className="grid fluid dashboard">
        {children?.map((child, i) => (
          <div key={i} className="col-fixed itemCard">
            {child}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default DashboardCardGroup;

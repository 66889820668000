import asyncComponent from '../AsyncComponent';

// Lazy Load Tenant Management
export const AsyncTenantManagementPage = asyncComponent(() => {
  return import('../../../features/tenants/TenantManagementPage');
});
export const AsyncTenantCreatePage = asyncComponent(() => {
  return import('../../../features/tenants/TenantCreatePage');
});
export const AsyncTenantEditPage = asyncComponent(() => {
  return import('../../../features/tenants/TenantEditPage');
});
export const AsyncTenantDetailPage = asyncComponent(() => {
  return import('../../../features/tenants/TenantDetailPage');
});
export const AsyncTenantVerifyPage = asyncComponent(() => {
  return import('../../../features/tenants/TenantVerifyPage');
});
export const AsyncTenantConfigurationPage = asyncComponent(() => {
  return import('../../../features/tenants/TenantConfigurationPage');
});
export const AsyncPersonSyncErrorsPage = asyncComponent(() => {
  return import('../../../features/tenants/PersonSyncErrorsPage');
});

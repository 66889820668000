import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { RouteChildrenProps, withRouter, RouteComponentProps } from 'react-router-dom';
import withRouter from '../../util/withRouter';
import { Toast } from 'primereact/toast';
import * as assessmentActions from '../forms/actions';
import * as CONSTANTS from '../../domain/constants';
import * as formActions from '../templates/actions';
import AsyncLocalStorage from '../../util/asyncLocalStorage';
import DashboardCard from './DashboardCard';
import DashboardCardGroup from './DashboardCardGroup';

interface IDashboardCardSectionProps {
  assessmentDashboard: any;
  assessmentDashboardCompletedCount: any;
  assessmentDashboardRejectedCount: any;
  assessmentError: any;
  formDashboard: any;
  formError: any;
  assessmentAction: any;
  formaction: any;
  history: any;
  location: any;
}

interface IDashboardCardSectionState {
  assessmentDashboardFinish: boolean;
  formDashboardFinish: boolean;
  role: string | null;
  userRoles: string[];
}

class DashboardCardSection extends Component<IDashboardCardSectionProps, IDashboardCardSectionState> {
  toast: any;

  constructor(props: IDashboardCardSectionProps) {
    super(props);

    this.state = {
      assessmentDashboardFinish: false,
      formDashboardFinish: false,
      role: '',
      userRoles: [],
    };
  }

  componentDidMount() {
    AsyncLocalStorage.getItem('tenantId').then((tenantId) => {
      AsyncLocalStorage.getItem('roles').then((roles) => {
        const userRoles = roles && roles !== '' ? roles.split(',') : [];
        this.setState({ role: roles, userRoles: userRoles });

        if (
          this.state.userRoles.includes('TrainingAdmin') ||
          this.state.userRoles.includes('TenantAdmin') ||
          this.state.userRoles.includes('SME') ||
          this.state.userRoles.includes('Assessor') ||
          this.state.userRoles.includes('WPM')
        ) {
          this.props.assessmentAction.dashboardAssessmentsAction(tenantId).then(() => {
            if (this.state.userRoles.includes('WPM')) {
              this.props.assessmentAction.dashboardAssessmentsCompletedAction().then(() => {
                this.setState({
                  assessmentDashboardFinish: true,
                });
                if (this.props.assessmentError) {
                  this.toast.show({
                    severity: 'error',
                    summary: 'Assessment Dashboard Error',
                    sticky: true,
                    detail: this.props.assessmentError.errStatus,
                    life: CONSTANTS.REACT_APP_MESSAGE_GROWL_DEFAULT_LIFE,
                  });
                }
              });
            } else {
              this.setState({
                assessmentDashboardFinish: true,
              });
              if (this.props.assessmentError) {
                this.toast.show({
                  severity: 'error',
                  summary: 'Assessment Dashboard Error',
                  sticky: true,
                  detail: this.props.assessmentError.errStatus,
                  life: CONSTANTS.REACT_APP_MESSAGE_GROWL_DEFAULT_LIFE,
                });
              }
            }
          });

          this.props.assessmentAction.dashboardAssessmentsRejectedAction().then(() => {
            this.setState({
              assessmentDashboardFinish: true,
            });
            if (this.props.assessmentError) {
              this.toast.show({
                severity: 'error',
                summary: 'Assessment Dashboard Error',
                sticky: true,
                detail: this.props.assessmentError.errStatus,
                life: CONSTANTS.REACT_APP_MESSAGE_GROWL_DEFAULT_LIFE,
              });
            }
          });
        }

        if (this.state.userRoles.includes('TrainingAdmin') || this.state.userRoles.includes('TenantAdmin')) {
          this.props.formaction.dashboardFormAction(tenantId).then(() => {
            this.setState({
              formDashboardFinish: true,
            });
            if (this.props.formError) {
              this.toast.show({
                severity: 'error',
                summary: 'Form Template Dashboard Error',
                sticky: true,
                detail: this.props.formError.errStatus,
                life: CONSTANTS.REACT_APP_MESSAGE_GROWL_DEFAULT_LIFE,
              });
            }
          });
        }
      });
    });
  }

  onTemplateCardClick = (event: any) => {
    this.props.history(`/templates?status=${event.id}`);
  };

  onFormCardClick = (event: any, needWpmAssigned?: boolean) => {
    if (needWpmAssigned) {
      this.props.history(`/forms?status=${event.id}&needWpmAssigned=${needWpmAssigned}`);
    } else {
      this.props.history(`/forms?status=${event.id}`);
    }
  };

  render() {
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />

        <DashboardCardGroup
          heading="For Action"
          visible={
            this.state.userRoles.includes('TrainingAdmin') ||
            this.state.userRoles.includes('TenantAdmin') ||
            this.state.userRoles.includes('SME') ||
            this.state.userRoles.includes('Assessor') ||
            this.state.userRoles.includes('WPM')
          }
        >
          <DashboardCard
            id="Completed"
            title="Need Approval"
            detail="All"
            numbers={
              this.props.assessmentError
                ? ''
                : this.state.userRoles.includes('WPM') &&
                  !this.state.userRoles.includes('TrainingAdmin') &&
                  !this.state.userRoles.includes('TenantAdmin')
                ? this.props.assessmentDashboardCompletedCount
                : this.props.assessmentDashboard.completed
            }
            onClick={this.onFormCardClick}
            loading={!this.state.assessmentDashboardFinish}
          />
          <DashboardCard
            id="Completed"
            title="Need WPM"
            detail="All"
            numbers={this.props.assessmentError ? '' : this.props.assessmentDashboard.needWpmAssigned}
            onClick={(e) => this.onFormCardClick(e, true)}
            loading={!this.state.assessmentDashboardFinish}
          />
          <DashboardCard
            id="Expiring"
            title="Expiring"
            detail="In the next 7 days"
            numbers={this.props.assessmentError ? '' : this.props.assessmentDashboard.expiring}
            onClick={this.onFormCardClick}
            loading={!this.state.assessmentDashboardFinish}
          />
          <DashboardCard
            id="Rejected"
            title="Rejected"
            detail="In the past 30 days"
            numbers={this.props.assessmentError ? '' : this.props.assessmentDashboardRejectedCount}
            onClick={this.onFormCardClick}
            loading={!this.state.assessmentDashboardFinish}
          />
        </DashboardCardGroup>

        <DashboardCardGroup
          heading="Forms"
          visible={
            this.state.userRoles.includes('TrainingAdmin') ||
            this.state.userRoles.includes('TenantAdmin') ||
            this.state.userRoles.includes('SME') ||
            this.state.userRoles.includes('Assessor') ||
            this.state.userRoles.includes('WPM')
          }
        >
          <DashboardCard
            id="In Progress"
            title="In Progress"
            detail="All"
            numbers={this.props.assessmentError ? '' : this.props.assessmentDashboard.inProgress}
            onClick={this.onFormCardClick}
            loading={!this.state.assessmentDashboardFinish}
          />
          <DashboardCard
            id="Approved"
            title="Approved"
            detail="In the past 30 days"
            numbers={this.props.assessmentError ? '' : this.props.assessmentDashboard.approved}
            onClick={this.onFormCardClick}
            loading={!this.state.assessmentDashboardFinish}
          />
          <DashboardCard
            id="Cancelled"
            title="Cancelled"
            detail="In the past 30 days"
            numbers={this.props.assessmentError ? '' : this.props.assessmentDashboard.canceled}
            onClick={this.onFormCardClick}
            loading={!this.state.assessmentDashboardFinish}
          />
        </DashboardCardGroup>

        <DashboardCardGroup
          heading="Templates"
          visible={this.state.userRoles.includes('TrainingAdmin') || this.state.userRoles.includes('TenantAdmin')}
        >
          <DashboardCard
            id="Draft"
            title="Draft"
            detail="All"
            numbers={this.props.formError ? '' : this.props.formDashboard.draft}
            onClick={this.onTemplateCardClick}
            loading={!this.state.formDashboardFinish}
          />
          <DashboardCard
            id="Published"
            title="Published"
            detail="All"
            numbers={this.props.formError ? '' : this.props.formDashboard.published}
            onClick={this.onTemplateCardClick}
            loading={!this.state.formDashboardFinish}
          />
          <DashboardCard
            id="Deactivated"
            title="Deactivated"
            detail="In the past 30 days"
            numbers={this.props.formError ? '' : this.props.formDashboard.deactivated}
            onClick={this.onTemplateCardClick}
            loading={!this.state.formDashboardFinish}
          />
        </DashboardCardGroup>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    assessmentDashboard: state.assessment.assessmentDashboard,
    assessmentDashboardCompletedCount: state.assessment.assessmentDashboardCompletedCount,
    assessmentDashboardRejectedCount: state.assessment.assessmentDashboardRejectedCount,
    assessmentError: state.assessment.error,
    formDashboard: state.formBuilder.formDashboard,
    formError: state.formBuilder.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    assessmentAction: bindActionCreators(assessmentActions, dispatch),
    formaction: bindActionCreators(formActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardCardSection));

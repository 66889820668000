import * as actionTypes from '../../app/store/actionTypes';

import FormBuilderApi from '../../app/api-client/FormBuilderApi';

export const fetchCountSuccess = (formsCount) => {
  return {
    type: actionTypes.FORMS_GET_COUNT_SUCCESS,
    formsCount: formsCount,
  };
};

// Fetch Data Forms
export const fetchFormsSuccess = (formsData) => {
  return {
    type: actionTypes.FORMS_GET_SUCCESS,
    formsData: formsData,
  };
};
export const fetchFormsFail = (error) => {
  return {
    type: actionTypes.FORMS_GET_FAIL,
    error: error,
  };
};
export const fetchFormsStart = () => {
  return {
    type: actionTypes.FORMS_GET_START,
  };
};

export const fetchForms = (status, dateFrom, dateTo) => {
  return (dispatch) => {
    dispatch(fetchFormsStart());
    return FormBuilderApi.getAllForms(status, dateFrom, dateTo)
      .then((response) => {
        dispatch(fetchFormsSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchFormsFail(error));
      });
  };
};

// Fetch Data Forms (Dynamic)
export const fetchFormsDynamicSuccess = (formsData) => {
  return {
    type: actionTypes.FORMS_DYNAMIC_GET_SUCCESS,
    formsData: formsData,
  };
};
export const fetchFormsDynamicPartialSuccess = (formsData) => {
  return {
    type: actionTypes.FORMS_DYNAMIC_GET_PARTIAL_SUCCESS,
    formsData: formsData,
  };
};
export const fetchFormsDynamicFail = (error) => {
  return {
    type: actionTypes.FORMS_DYNAMIC_GET_FAIL,
    error: error,
  };
};
export const fetchFormsDynamicStart = () => {
  return {
    type: actionTypes.FORMS_DYNAMIC_GET_START,
  };
};
export const fetchFormsDynamicStop = () => {
  return {
    type: actionTypes.FORMS_DYNAMIC_GET_FORCE_STOP,
  };
};

export const stopFetchFormsDynamic = () => {
  return (dispatch) => {
    return dispatch(fetchFormsDynamicStop());
  };
};

export const fetchFormsDynamic = (status, dateFrom, dateTo, textSearch, skip, limit) => {
  return (dispatch) => {
    dispatch(fetchFormsDynamicStart());
    return FormBuilderApi.getAllFormsDynamic(status, dateFrom, dateTo, textSearch, skip, limit)
      .then((response) => {
        if (response !== null && response !== undefined && response !== '' && response.length > 0) {
          //dispatch(fetchFormsDynamicPartialSuccess(response));
          //the line below has been used to turn the pagination off. In case to turn the pagination on, delete the line below and uncomment line above
          dispatch(fetchFormsDynamicSuccess(response));
          FormBuilderApi.getAllFormsDynamicCount(status, dateFrom, dateTo, textSearch).then((formsCount) => {
            dispatch(fetchCountSuccess(formsCount));
          });
        } else {
          dispatch(fetchFormsDynamicSuccess(response));
          dispatch(fetchCountSuccess(0));
        }
      })
      .catch((error) => {
        dispatch(fetchFormsDynamicFail(error));
      });
  };
};

export const turnOnPagination = () => {
  return {
    type: actionTypes.FORMS_PAGINATION_ON,
  };
};

export const turnOffPagination = () => {
  return {
    type: actionTypes.FORMS_PAGINATION_OFF,
  };
};

// Fetch Data Forms by text search
export const fetchFormsTextSearchSuccess = (formsData) => {
  return {
    type: actionTypes.FORMS_FULLTEXTSEARCH_GET_SUCCESS,
    formsData: formsData,
  };
};
export const fetchFormsTextSearchFail = (error) => {
  return {
    type: actionTypes.FORMS_FULLTEXTSEARCH_GET_FAIL,
    error: error,
  };
};
export const fetchFormsTextSearchStart = () => {
  return {
    type: actionTypes.FORMS_FULLTEXTSEARCH_GET_START,
  };
};

/*
 * Get Forms by using the full text search feature
 */
export const fetchFormsByTextSearch = (skip, limit, textSearch) => {
  return (dispatch) => {
    dispatch(fetchFormsTextSearchStart());
    return FormBuilderApi.getFormsByTextSearch(skip, limit, textSearch)
      .then((response) => {
        dispatch(fetchFormsTextSearchSuccess(response));
        FormBuilderApi.getFormsByTextSearchCount(textSearch).then((formsCount) => {
          dispatch(fetchCountSuccess(formsCount));
        });
      })
      .catch((error) => {
        dispatch(fetchFormsTextSearchFail(error));
      });
  };
};

// Get Form Detail
export const getFormDetailSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_GET_DETAIL_SUCCESS,
    formData: formData,
  };
};
export const getFormDetailFail = (error) => {
  return {
    type: actionTypes.FORMS_GET_DETAIL_FAIL,
    error: error,
  };
};
export const getFormDetailStart = () => {
  return {
    type: actionTypes.FORMS_GET_DETAIL_START,
  };
};

export const getDetailForm = (formUuid, separateFormDataImages) => {
  if (separateFormDataImages !== undefined && separateFormDataImages !== '') {
    return (dispatch) => {
      dispatch(getFormDetailStart());
      return FormBuilderApi.getForm(formUuid, separateFormDataImages)
        .then((response) => {
          dispatch(getFormDetailSuccess(response));
        })
        .catch((error) => {
          dispatch(getFormDetailFail(error));
        });
    };
  } else {
    return (dispatch) => {
      dispatch(getFormDetailStart());
      return FormBuilderApi.getForm(formUuid, true)
        .then((response) => {
          dispatch(getFormDetailSuccess(response));
        })
        .catch((error) => {
          dispatch(getFormDetailFail(error));
        });
    };
  }
};

// Get Form Number
export const getFormNumberSuccess = (formNumber) => {
  return {
    type: actionTypes.FORMS_GET_FORM_NUMBER_SUCCESS,
    formNumber: formNumber.data,
  };
};
export const getFormNumberFail = (error) => {
  return {
    type: actionTypes.FORMS_GET_FORM_NUMBER_FAIL,
    error: error,
  };
};
export const getFormNumberStart = () => {
  return {
    type: actionTypes.FORMS_GET_FORM_NUMBER_START,
  };
};

export const getFormNumber = (tenantId) => {
  return (dispatch) => {
    dispatch(getFormNumberStart());
    return FormBuilderApi.getFormsNumber(tenantId)
      .then((response) => {
        dispatch(getFormNumberSuccess(response));
      })
      .catch((error) => {
        dispatch(getFormNumberFail(error));
      });
  };
};

// Create Form
export const createFormSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_ADD_NEW_SUCCESS,
    formData: formData,
  };
};
export const createFormFail = (error) => {
  return {
    type: actionTypes.FORMS_ADD_NEW_FAIL,
    error: error,
  };
};
export const createFormStart = () => {
  return {
    type: actionTypes.FORMS_ADD_NEW_START,
  };
};

export const createForm = (data) => {
  return (dispatch) => {
    dispatch(createFormStart());
    return FormBuilderApi.createForm(data)
      .then((response) => {
        dispatch(createFormSuccess(response));
      })
      .catch((error) => {
        dispatch(createFormFail(error));
      });
  };
};

// Uploading Form Image
export const uploadFormImageSuccess = (formuUuid, formDataId) => {
  return {
    type: actionTypes.FORMS_IMAGE_UPLOAD_SUCCESS,
    formuUuid: formuUuid,
    formDataId: formDataId,
  };
};
export const uploadFormImageFail = (formuUuid, formDataId, error) => {
  return {
    type: actionTypes.FORMS_IMAGE_UPLOAD_FAIL,
    error: error,
    formuUuid: formuUuid,
    formDataId: formDataId,
  };
};
export const uploadFormImageStart = (formuUuid, formDataId) => {
  return {
    type: actionTypes.FORMS_IMAGE_UPLOAD_START,
    formuUuid: formuUuid,
    formDataId: formDataId,
  };
};

export const uploadFormImage = (formuUuid, formId, uploadedImage) => {
  return (dispatch) => {
    dispatch(uploadFormImageStart(formuUuid, uploadedImage.formDataId));
    return FormBuilderApi.uploadFormImage(formuUuid, formId, uploadedImage.formDataId, uploadedImage.srcData)
      .then((response) => {
        dispatch(uploadFormImageSuccess(formuUuid, uploadedImage.formDataId, response));
      })
      .catch((error) => {
        dispatch(uploadFormImageFail(formuUuid, uploadedImage.formDataId, error));
      });
  };
};

export const uploadFormImageClear = (formuUuid, formDataId) => {
  return {
    type: actionTypes.FORMS_IMAGE_UPLOAD_CLEAR,
    formuUuid: formuUuid,
    formDataId: formDataId,
  };
};

export const doUploadFormImageClear = (formuUuid, formDataId) => {
  return (dispatch) => {
    dispatch(uploadFormImageClear(formuUuid, formDataId));
  };
};

// Uploading Form Images
export const uploadFormImagesSuccess = () => {
  return {
    type: actionTypes.FORMS_IMAGES_UPLOAD_SUCCESS,
  };
};
export const uploadFormImagesFail = (error) => {
  return {
    type: actionTypes.FORMS_IMAGES_UPLOAD_FAIL,
    error: error,
  };
};
export const uploadFormImagesStart = (uploadedImages) => {
  return {
    type: actionTypes.FORMS_IMAGES_UPLOAD_START,
    uploadedImages: uploadedImages,
  };
};

export const uploadFormImages = (formuUuid, formId, uploadedImages) => {
  return (dispatch) => {
    dispatch(uploadFormImagesStart(uploadedImages));
    var postImages = [];
    uploadedImages.forEach((item) => {
      postImages.push(FormBuilderApi.uploadFormImage(formuUuid, formId, item.formDataId, item.srcData));
    });
    return Promise.all(postImages)
      .then(() => {
        dispatch(uploadFormImagesSuccess());
      })
      .catch((error) => {
        dispatch(uploadFormImagesFail(error));
      });
  };
};

// Edit Form
export const editFormSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_EDIT_SUCCESS,
    formData: formData,
  };
};
export const editFormFail = (error) => {
  return {
    type: actionTypes.FORMS_EDIT_FAIL,
    error: error,
  };
};
export const editFormStart = () => {
  return {
    type: actionTypes.FORMS_EDIT_START,
  };
};

export const editForm = (id, data) => {
  return (dispatch) => {
    dispatch(editFormStart());
    return FormBuilderApi.editForm(id, data)
      .then((response) => {
        dispatch(editFormSuccess(response));
      })
      .catch((error) => {
        dispatch(editFormFail(error));
      });
  };
};

// Clone Form
export const cloneFormSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_CLONE_SUCCESS,
    formData: formData,
  };
};
export const cloneFormFail = (error) => {
  return {
    type: actionTypes.FORMS_CLONE_FAIL,
    error: error,
  };
};
export const cloneFormStart = () => {
  return {
    type: actionTypes.FORMS_CLONE_START,
  };
};

export const cloneForm = (data) => {
  return (dispatch) => {
    dispatch(cloneFormStart());
    return FormBuilderApi.cloneForm(data)
      .then((response) => {
        dispatch(cloneFormSuccess(response));
      })
      .catch((error) => {
        dispatch(cloneFormFail(error));
      });
  };
};

// Publish Form
export const publishFormSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_PUBLISH_SUCCESS,
    formData: formData,
  };
};
export const publishFormFail = (error) => {
  return {
    type: actionTypes.FORMS_PUBLISH_FAIL,
    error: error,
  };
};
export const publishFormStart = () => {
  return {
    type: actionTypes.FORMS_PUBLISH_START,
  };
};

export const publishForm = (formUuid, version) => {
  return (dispatch) => {
    dispatch(publishFormStart());
    return FormBuilderApi.publishForm(formUuid, version)
      .then((response) => {
        dispatch(publishFormSuccess(response));
      })
      .catch((error) => {
        dispatch(publishFormFail(error));
      });
  };
};

// activate Form
export const activateFormSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_ACTIVATE_SUCCESS,
    formData: formData,
  };
};
export const activateFormFail = (error) => {
  return {
    type: actionTypes.FORMS_ACTIVATE_FAIL,
    error: error,
  };
};
export const activateFormStart = () => {
  return {
    type: actionTypes.FORMS_ACTIVATE_START,
  };
};

export const activateForm = (formUuid, version, active) => {
  return (dispatch) => {
    dispatch(activateFormStart());
    return FormBuilderApi.activateForm(formUuid, version, active)
      .then((response) => {
        dispatch(activateFormSuccess(response));
      })
      .catch((error) => {
        dispatch(activateFormFail(error));
      });
  };
};

// delete Form
export const deleteFormSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_DELETE_SUCCESS,
    formData: formData,
  };
};
export const deleteFormFail = (error) => {
  return {
    type: actionTypes.FORMS_DELETE_FAIL,
    error: error,
  };
};
export const deleteFormStart = () => {
  return {
    type: actionTypes.FORMS_DELETE_START,
  };
};

export const deleteForm = (formUuid) => {
  return (dispatch) => {
    dispatch(deleteFormStart());
    return FormBuilderApi.deleteForm(formUuid)
      .then((response) => {
        dispatch(deleteFormSuccess(response));
      })
      .catch((error) => {
        dispatch(deleteFormFail(error));
      });
  };
};

// locked status Form
export const lockedFormSuccess = (lockedStatus) => {
  return {
    type: actionTypes.FORMS_LOCKED_SUCCESS,
    lockedStatus: lockedStatus,
  };
};
export const lockedFormFail = (error) => {
  return {
    type: actionTypes.FORMS_LOCKED_FAIL,
    error: error,
  };
};
export const lockedFormStart = () => {
  return {
    type: actionTypes.FORMS_LOCKED_START,
  };
};

export const lockedForm = (formuUuid) => {
  return (dispatch) => {
    dispatch(lockedFormStart());
    return FormBuilderApi.getLockedStatus(formuUuid)
      .then((response) => {
        dispatch(lockedFormSuccess(response));
      })
      .catch((error) => {
        dispatch(lockedFormFail(error));
      });
  };
};

// Update locked status Form
export const updateLockedFormSuccess = (lockedStatus) => {
  return {
    type: actionTypes.FORMS_UPDATE_LOCKED_SUCCESS,
    lockedStatus: lockedStatus,
  };
};
export const updateLockedFormFail = (error) => {
  return {
    type: actionTypes.FORMS_UPDATE_LOCKED_FAIL,
    error: error,
  };
};
export const updateLockedFormStart = () => {
  return {
    type: actionTypes.FORMS_UPDATE_LOCKED_START,
  };
};

export const updateLockedForm = (formuUuid) => {
  return (dispatch) => {
    dispatch(updateLockedFormStart());
    return FormBuilderApi.updateLockedStatus(formuUuid)
      .then((response) => {
        dispatch(updateLockedFormSuccess(response));
      })
      .catch((error) => {
        dispatch(updateLockedFormFail(error));
      });
  };
};

// Delete locked status Form
export const deleteLockedFormSuccess = (lockedStatus) => {
  return {
    type: actionTypes.FORMS_DELETE_LOCKED_SUCCESS,
    lockedStatus: lockedStatus,
  };
};
export const deleteLockedFormFail = (error) => {
  return {
    type: actionTypes.FORMS_DELETE_LOCKED_FAIL,
    error: error,
  };
};
export const deleteLockedFormStart = () => {
  return {
    type: actionTypes.FORMS_DELETE_LOCKED_START,
  };
};

export const deleteLockedForm = (formuUuid) => {
  return (dispatch) => {
    dispatch(deleteLockedFormStart());
    return FormBuilderApi.deleteLockedStatus(formuUuid)
      .then((response) => {
        dispatch(deleteLockedFormSuccess(response));
      })
      .catch((error) => {
        dispatch(deleteLockedFormFail(error));
      });
  };
};

export const forceUnlockForm = (formuUuid) => {
  return (dispatch) => {
    dispatch(deleteLockedFormStart());
    return FormBuilderApi.forceUnlockStatus(formuUuid)
      .then((response) => {
        dispatch(deleteLockedFormSuccess(response));
      })
      .catch((error) => {
        dispatch(deleteLockedFormFail(error));
      });
  };
};

// Get form's form data
export const getFormDataSuccess = (formData) => {
  return {
    type: actionTypes.FORMS_GET_FORM_DATA_SUCCESS,
    formData: formData,
  };
};
export const getFormDataFail = (error) => {
  return {
    type: actionTypes.FORMS_GET_FORM_DATA_FAIL,
    error: error,
  };
};
export const getFormDataStart = () => {
  return {
    type: actionTypes.FORMS_GET_FORM_DATA_START,
  };
};

export const getFormsFormData = (formUuid) => {
  return (dispatch) => {
    dispatch(getFormDataStart());
    return FormBuilderApi.getFormsFormData(formUuid)
      .then((response) => {
        dispatch(getFormDataSuccess(response));
      })
      .catch((error) => {
        dispatch(getFormDataFail(error));
      });
  };
};

// Get competency list
export const getCompetencyListSuccess = (competencyList) => {
  return {
    type: actionTypes.COMPETENCY_GET_ALL_SUCCESS,
    competencyList: competencyList,
  };
};
export const getCompetencyListFail = (error) => {
  return {
    type: actionTypes.COMPETENCY_GET_ALL_FAIL,
    error: error,
  };
};
export const getCompetencyListStart = () => {
  return {
    type: actionTypes.COMPETENCY_GET_ALL_START,
  };
};

export const getCompetencyList = (formuUuid) => {
  return (dispatch) => {
    dispatch(getCompetencyListStart());
    return FormBuilderApi.getCompetencyList(formuUuid)
      .then((response) => {
        dispatch(getCompetencyListSuccess(response));
      })
      .catch((error) => {
        dispatch(getCompetencyListFail(error));
      });
  };
};

// insert competency list
export const insertCompetencyListSuccess = (competencyList) => {
  return {
    type: actionTypes.COMPETENCY_BULK_INSERT_SUCCESS,
    competencyList: competencyList,
  };
};
export const insertCompetencyListFail = (error) => {
  return {
    type: actionTypes.COMPETENCY_BULK_INSERT_FAIL,
    error: error,
  };
};
export const insertCompetencyListStart = () => {
  return {
    type: actionTypes.COMPETENCY_BULK_INSERT_START,
  };
};

export const insertCompetencyList = (data) => {
  return (dispatch) => {
    dispatch(insertCompetencyListStart());
    return FormBuilderApi.bulkInsertCompetency(data)
      .then((response) => {
        dispatch(insertCompetencyListSuccess(response));
      })
      .catch((error) => {
        dispatch(insertCompetencyListFail(error));
      });
  };
};

// Form builder on dashboard
export const dashboardFormSuccess = (formDashboard) => {
  return {
    type: actionTypes.DASHBOARD_FORM_SUCCESS,
    formDashboard: formDashboard,
  };
};
export const dashboardFormFail = (error) => {
  return {
    type: actionTypes.DASHBOARD_FORM_FAIL,
    error: error,
  };
};
export const dashboardFormStart = () => {
  return {
    type: actionTypes.DASHBOARD_FORM_START,
  };
};

export const dashboardFormAction = (tenantId) => {
  return (dispatch) => {
    dispatch(dashboardFormStart());
    return FormBuilderApi.getFormDashboard(tenantId)
      .then((response) => {
        dispatch(dashboardFormSuccess(response));
      })
      .catch((error) => {
        dispatch(dashboardFormFail(error));
      });
  };
};

import { Messages } from 'primereact/messages';
import React, { useEffect } from 'react';

const showReleaseMessage = false;

const ReleaseMessage: React.FC = () => {
  const isNaRegion = window.location.hostname.endsWith('.ca');
  const scheduledTime = isNaRegion ?  '5:00PM CT on Tuesday, November 7th' : '6:00AM AWST on Tuesday, November 7th';
  
  const messages = React.useRef<any>();
  
  useEffect(() => {
    if (showReleaseMessage) {
      messages.current?.replace({
        severity: 'info',
        sticky: true,
        content: <>
          <div className="p-message-detail">
            <p className="p-message-summary">
              We are excited to announce INX Assessor product release version 3.0.2. The product release is scheduled for {scheduledTime} . There will be a short period of downtime, and you may have intermitted interruptions during this period.
            </p>
        
            <p className="p-message-summary">
              We recommend syncing all mobile assessments before the release date to ensure a smooth transition to the upgrade. The current version of the INX Assessor mobile application will no longer be compatible after the release date, and the latest version of the mobile applications must be installed (available from the Android and iOS app stores) on or after the 7th of November.
            </p>
        
            <p className="p-message-summary">
              Refer to the <a href="https://www.inxsoftware.com/release-note/comment-using-our-new-speech-to-text-option-new-improvements-and-fixes/" target="_blank" rel="noopener noreferrer"> INX Assessor release notes</a> for information on the changes introduced in this release.
            </p>
          </div>
        </>
      });
    }
  }, [messages, scheduledTime]);
  
  return (
    <Messages ref={messages} />
  )
};

export default ReleaseMessage;
import * as actionTypes from '../../app/store/actionTypes';
import { updateState } from '../../util/updateState';
import _ from 'lodash';

const initialState = {
  trainees: [],
  trainee: {},
  loading: true,
  loadingBackground: false,
  forceStop: false,
  error: null,
  lastFetchTime: null,
};

// Fetch List Trainee state
const fetchTraineesStart = (state, action) => {
  return updateState(state, { loading: true });
};
const fetchTraineesSuccess = (state, action) => {
  return updateState(state, {
    trainees: action.traineesData,
    loading: false,
  });
};
const fetchTraineesFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Get details Trainee state
const fetchTraineeStart = (state, action) => {
  return updateState(state, { loading: true, trainee: {} });
};
const fetchTraineeSuccess = (state, action) => {
  return updateState(state, {
    trainee: action.traineeData,
    loading: false,
  });
};
const fetchTraineeFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Create new Trainee
const createTraineeStart = (state, action) => {
  return updateState(state, { loading: true });
};
const createTraineeSuccess = (state, action) => {
  let newTrainees = state.trainees.slice();
  newTrainees.unshift(action.traineeData);

  return updateState(state, {
    trainee: action.traineeData,
    trainees: newTrainees,
    loading: false,
  });
};
const createTraineeFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Insert Trainee
const insertTraineeStart = (state, action) => {
  return updateState(state, { loading: true });
};
const insertTraineeSuccess = (state, action) => {
  var newTrainees = _.concat(action.traineeData, state.trainees);

  return updateState(state, {
    trainees: newTrainees,
    loading: false,
  });
};
const insertTraineeFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Update Trainee
const updateTraineeStart = (state, action) => {
  return updateState(state, { loading: true });
};
const updateTraineeSuccess = (state, action) => {
  var newTrainees = state.trainees.filter((item, index) => item.traineeId !== action.traineeData.traineeId);
  newTrainees.unshift(action.traineeData);
  return updateState(state, {
    loading: false,
    trainees: newTrainees,
  });
};
const updateTraineeFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Delete Trainee
const deleteTraineeStart = (state, action) => {
  return updateState(state, { loading: true });
};
const deleteTraineeSuccess = (state, action) => {
  var newTrainees = state.trainees.filter((item, index) => item.traineeId !== action.deletedTrainee.traineeId);

  return updateState(state, {
    loading: false,
    trainees: newTrainees,
  });
};
const deleteTraineeFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

//dynamic get trainees
const fetchTraineesDynamicStart = (state, action) => {
  var result = [];
  if (state.loadingBackground && !state.forceStop) {
    //continue with previous fetching
    result = state.trainees;
    return updateState(state, { trainees: result, loadingBackground: true });
  }
  if (state.loadingBackground && state.forceStop) {
    return updateState(state, {
      trainees: [],
      loadingBackground: false,
      forceStop: false,
      error: null,
    });
  } else {
    result = [];
    return updateState(state, { trainees: result, loadingBackground: true });
  }
};
const fetchTraineesDynamicSuccess = (state, action) => {
  var updatedList = _.concat(state.trainees, action.traineesData);
  return updateState(state, {
    trainees: updatedList,
    lastFetchTime: Date.now(),
    loadingBackground: false,
    forceStop: false,
    error: null,
  });
};
const fetchTraineesDynamicForceStop = (state, action) => {
  return updateState(state, {
    trainees: [],
    forceStop: true,
  });
};
const fetchTraineesDynamicPartialSuccess = (state, action) => {
  var updatedList = _.concat(state.trainees, action.traineesData);
  return updateState(state, {
    trainees: updatedList,
    lastFetchTime: Date.now(),
    error: null,
  });
};
const fetchTraineesDynamicFail = (state, action) => {
  return updateState(state, { error: action.error, loadingBackground: false });
};

const resetTraineeList = (state, action) => {
  return updateState(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRAINEE_FETCH_START:
      return fetchTraineesStart(state, action);
    case actionTypes.TRAINEE_FETCH_SUCCESS:
      return fetchTraineesSuccess(state, action);
    case actionTypes.TRAINEE_FETCH_FAIL:
      return fetchTraineesFail(state, action);
    case actionTypes.TRAINEE_GET_DETAIL_START:
      return fetchTraineeStart(state, action);
    case actionTypes.TRAINEE_GET_DETAIL_SUCCESS:
      return fetchTraineeSuccess(state, action);
    case actionTypes.TRAINEE_GET_DETAIL_FAIL:
      return fetchTraineeFail(state, action);
    case actionTypes.TRAINEE_ADD_NEW_START:
      return createTraineeStart(state, action);
    case actionTypes.TRAINEE_ADD_NEW_SUCCESS:
      return createTraineeSuccess(state, action);
    case actionTypes.TRAINEE_ADD_NEW_FAIL:
      return createTraineeFail(state, action);
    case actionTypes.TRAINEE_INSERT_START:
      return insertTraineeStart(state, action);
    case actionTypes.TRAINEE_INSERT_SUCCESS:
      return insertTraineeSuccess(state, action);
    case actionTypes.TRAINEE_INSERT_FAIL:
      return insertTraineeFail(state, action);
    case actionTypes.TRAINEE_EDIT_START:
      return updateTraineeStart(state, action);
    case actionTypes.TRAINEE_EDIT_SUCCESS:
      return updateTraineeSuccess(state, action);
    case actionTypes.TRAINEE_EDIT_FAIL:
      return updateTraineeFail(state, action);
    case actionTypes.TRAINEE_DELETE_START:
      return deleteTraineeStart(state, action);
    case actionTypes.TRAINEE_DELETE_SUCCESS:
      return deleteTraineeSuccess(state, action);
    case actionTypes.TRAINEE_DELETE_FAIL:
      return deleteTraineeFail(state, action);
    case actionTypes.TRAINEES_DYNAMIC_GET_START:
      return fetchTraineesDynamicStart(state, action);
    case actionTypes.TRAINEES_DYNAMIC_GET_SUCCESS:
      return fetchTraineesDynamicSuccess(state, action);
    case actionTypes.TRAINEES_DYNAMIC_GET_FORCE_STOP:
      return fetchTraineesDynamicForceStop(state, action);
    case actionTypes.TRAINEES_DYNAMIC_GET_PARTIAL_SUCCESS:
      return fetchTraineesDynamicPartialSuccess(state, action);
    case actionTypes.TRAINEES_DYNAMIC_GET_FAIL:
      return fetchTraineesDynamicFail(state, action);
    case actionTypes.TRAINEES_RESET:
      return resetTraineeList(state, action);
    default:
      return state;
  }
};

export default reducer;

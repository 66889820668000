import React, { Component } from 'react';
import classNames from 'classnames';
import AsyncLocalStorage from '../../util/asyncLocalStorage';
import defaultProfile from './../../media/profile.png';
import { Navigate, Link } from 'react-router-dom';

export class AppProfile extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
      username: '',
      tenant: '',
      goLogout: false,
      userId: '',
    };
    this.onClick = this.onClick.bind(this);
    this.goToLogout = this.goToLogout.bind(this);
  }

  componentDidMount() {
    AsyncLocalStorage.getItem('username').then((username) => {
      AsyncLocalStorage.getItem('tenant').then((tenant) => {
        AsyncLocalStorage.getItem('userId').then((userId) => {
          this.setState({ userId: userId, tenant: tenant, username: username });
        });
      });
    });
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  goToLogout() {
    this.setState({
      goLogout: true,
    });
  }

  profileClick() {
    let alink = document.getElementById('linkToProfile');
    console.log(alink);
  }

  shortenUsername() {
    let temp = this.state.username.substr(0, 20);
    let newText = '';
    if (temp.length > 19) {
      newText = temp + '...';
    } else {
      newText = temp;
    }
    return <span>{newText}</span>;
  }

  render() {
    const profileFontStyle = {
      color: '#232428',
    };
    if (this.state.goLogout) {
      return <Navigate to="/logout" />;
    }
    return (
      <div className="layout-profile">
        <div>
          <label>{this.state.tenant}</label>
        </div>
        <button className="p-link layout-profile-link" onClick={this.onClick}>
          {this.shortenUsername()}
          <i className="pi pi-fw pi-cog" />
        </button>
        <ul className={classNames({ 'layout-profile-expanded': this.state.expanded })}>
          <li>
            <Link
              id="linkToProfile"
              to={'/users/profileview/' + this.state.userId}
              className="p-link"
              style={profileFontStyle}
            >
              <i className="pi pi-fw pi-user" />
              <span>Account</span>
            </Link>
          </li>
          <li>
            <button className="p-link" onClick={this.goToLogout}>
              <i className="pi pi-fw pi-power-off" />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

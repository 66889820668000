import * as actionTypes from '../../app/store/actionTypes';

import { updateState } from '../../util/updateState';

const initialState = {
  token: null,
  username: null,
  tenant: null,
  tenantId: null,
  error: null,
  loading: false,
  authRedirectPath: '/login',
  roles: null,
  errorForgotPassword: null,
  isLogout: false,
};

const authStart = (state, action) => {
  return updateState(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateState(state, {
    token: action.token,
    username: action.username,
    tenant: action.tenant,
    tenantId: action.tenantId,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateState(state, {
    error: action.error,
    loading: false,
  });
};

const authLogoutStart = (state, action) => {
  return updateState(state, { error: null, loading: true, isLogout: true });
};

const authLogoutSuccess = (state, action) => {
  return updateState(state, {
    token: null,
    username: null,
    tenant: null,
    tenantId: null,
    loading: false,
    isLogout: true,
  });
};

const authLogoutFail = (state, action) => {
  return updateState(state, {
    error: action.error,
    loading: false,
    isLogout: false,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateState(state, { authRedirectPath: action.path });
};

const setRoles = (state, action) => {
  return updateState(state, { roles: action.roles });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT_START:
      return authLogoutStart(state, action);
    case actionTypes.AUTH_LOGOUT_SUCCESS:
      return authLogoutSuccess(state, action);
    case actionTypes.AUTH_LOGOUT_FAIL:
      return authLogoutFail(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.SET_ROLES:
      return setRoles(state, action);
    default:
      return state;
  }
};

export default reducer;

// Auth Actions
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

// Reset Password Request
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD_REQUEST_FETCH_START = 'RESET_PASSWORD_REQUEST_FETCH_START';
export const RESET_PASSWORD_REQUEST_FETCH_SUCCESS = 'RESET_PASSWORD_REQUEST_FETCH_SUCCESS';
export const RESET_PASSWORD_REQUEST_FETCH_FAIL = 'RESET_PASSWORD_REQUEST_FETCH_FAIL';
export const RESET_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

// User Management Actions
export const USER_FETCH_START = 'USER_FETCH_START';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAIL = 'USER_FETCH_FAIL';
export const USER_ADD_NEW_START = 'USER_ADD_NEW_START';
export const USER_ADD_NEW_SUCCESS = 'USER_ADD_NEW_SUCCESS';
export const USER_ADD_NEW_FAIL = 'USER_ADD_NEW_FAIL';
export const USER_GET_DETAIL_START = 'USER_GET_DETAIL_START';
export const USER_GET_DETAIL_SUCCESS = 'USER_GET_DETAIL_SUCCESS';
export const USER_GET_DETAIL_FAIL = 'USER_GET_DETAIL_FAIL';
export const USER_EDIT_START = 'USER_EDIT_START';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL';
export const USER_BULKINSERT_START = 'USER_BULKINSERT_START';
export const USER_BULKINSERT_SUCCESS = 'USER_BULKINSERT_SUCCESS';
export const USER_BULKINSERT_FAIL = 'USER_BULKINSERT_FAIL';
export const USER_WPM_FETCH_START = 'USER_WPM_FETCH_START';
export const USER_WPM_FETCH_SUCCESS = 'USER_WPM_FETCH_SUCCESS';
export const USER_WPM_FETCH_FAIL = 'USER_WPM_FETCH_FAIL';

// Trainee Management Actions
export const TRAINEE_FETCH_START = 'TRAINEE_FETCH_START';
export const TRAINEE_FETCH_SUCCESS = 'TRAINEE_FETCH_SUCCESS';
export const TRAINEE_FETCH_FAIL = 'TRAINEE_FETCH_FAIL';
export const TRAINEE_ADD_NEW_START = 'TRAINEE_ADD_NEW_START';
export const TRAINEE_ADD_NEW_SUCCESS = 'TRAINEE_ADD_NEW_SUCCESS';
export const TRAINEE_ADD_NEW_FAIL = 'TRAINEE_ADD_NEW_FAIL';
export const TRAINEE_GET_DETAIL_START = 'TRAINEE_GET_DETAIL_START';
export const TRAINEE_GET_DETAIL_SUCCESS = 'TRAINEE_GET_DETAIL_SUCCESS';
export const TRAINEE_GET_DETAIL_FAIL = 'TRAINEE_GET_DETAIL_FAIL';
export const TRAINEE_EDIT_START = 'TRAINEE_EDIT_START';
export const TRAINEE_EDIT_SUCCESS = 'TRAINEE_EDIT_SUCCESS';
export const TRAINEE_EDIT_FAIL = 'TRAINEE_EDIT_FAIL';
export const TRAINEE_DELETE_START = 'TRAINEE_DELETE_START';
export const TRAINEE_DELETE_SUCCESS = 'TRAINEE_DELETE_SUCCESS';
export const TRAINEE_DELETE_FAIL = 'TRAINEE_DELETE_FAIL';
export const TRAINEE_INSERT_START = 'TRAINEE_INSERT_START';
export const TRAINEE_INSERT_SUCCESS = 'TRAINEE_INSERT_SUCCESS';
export const TRAINEE_INSERT_FAIL = 'TRAINEE_INSERT_FAIL';
export const TRAINEES_DYNAMIC_GET_START = 'TRAINEES_DYNAMIC_GET_START';
export const TRAINEES_DYNAMIC_GET_SUCCESS = 'TRAINEES_DYNAMIC_GET_SUCCESS';
export const TRAINEES_DYNAMIC_GET_FORCE_STOP = 'TRAINEES_DYNAMIC_GET_FORCE_STOP';
export const TRAINEES_DYNAMIC_GET_PARTIAL_SUCCESS = 'TRAINEES_DYNAMIC_GET_PARTIAL_SUCCESS';
export const TRAINEES_DYNAMIC_GET_FAIL = 'TRAINEES_DYNAMIC_GET_FAIL';
export const TRAINEES_RESET = 'TRAINEES_RESET';

// Tenant Management Actions
export const TENANT_FETCH_START = 'TENANT_FETCH_START';
export const TENANT_FETCH_SUCCESS = 'TENANT_FETCH_SUCCESS';
export const TENANT_FETCH_FAIL = 'TENANT_FETCH_FAIL';
export const TENANT_ADD_NEW_START = 'TENANT_ADD_NEW_START';
export const TENANT_ADD_NEW_SUCCESS = 'TENANT_ADD_NEW_SUCCESS';
export const TENANT_ADD_NEW_FAIL = 'TENANT_ADD_NEW_FAIL';
export const TENANT_KEY_ADD_NEW_START = 'TENANT_KEY_ADD_NEW_START';
export const TENANT_KEY_ADD_NEW_SUCCESS = 'TENANT_KEY_ADD_NEW_SUCCESS';
export const TENANT_KEY_ADD_NEW_FAIL = 'TENANT_KEY_ADD_NEW_FAIL';
export const TENANT_KEY_DISABLE_START = 'TENANT_KEY_DISABLE_START';
export const TENANT_KEY_DISABLE_SUCCESS = 'TENANT_KEY_DISABLE_SUCCESS';
export const TENANT_KEY_DISABLE_FAIL = 'TENANT_KEY_DISABLE_FAIL';
export const TENANT_KEY_DELETE_START = 'TENANT_KEY_DELETE_START';
export const TENANT_KEY_DELETE_SUCCESS = 'TENANT_KEY_DELETE_SUCCESS';
export const TENANT_KEY_DELETE_FAIL = 'TENANT_KEY_DELETE_FAIL';
export const TENANT_GET_DETAIL_START = 'TENANT_GET_DETAIL_START';
export const TENANT_GET_DETAIL_SUCCESS = 'TENANT_GET_DETAIL_SUCCESS';
export const TENANT_GET_DETAIL_FAIL = 'TENANT_GET_DETAIL_FAIL';
export const TENANT_GET_KEYS_START = 'TENANT_GET_KEYS_START';
export const TENANT_GET_KEYS_SUCCESS = 'TENANT_GET_KEYS_SUCCESS';
export const TENANT_GET_KEYS_FAIL = 'TENANT_GET_KEYS_FAIL';
export const TENANT_GET_ACTIVE_ASSESSOR_START = 'TENANT_GET_ACTIVE_ASSESSOR_START';
export const TENANT_GET_ACTIVE_ASSESSOR_SUCCESS = 'TENANT_GET_ACTIVE_ASSESSOR_SUCCESS';
export const TENANT_GET_ACTIVE_ASSESSOR_FAIL = 'TENANT_GET_ACTIVE_ASSESSOR_FAIL';
export const TENANT_EDIT_START = 'TENANT_EDIT_START';
export const TENANT_EDIT_SUCCESS = 'TENANT_EDIT_SUCCESS';
export const TENANT_EDIT_FAIL = 'TENANT_EDIT_FAIL';
export const TENANT_VERIFY_START = 'TENANT_VERIFY_START';
export const TENANT_VERIFY_SUCCESS = 'TENANT_VERIFY_SUCCESS';
export const TENANT_VERIFY_FAIL = 'TENANT_VERIFY_FAIL';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_START = 'TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_START';
export const TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_SUCCESS = 'TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_SUCCESS';
export const TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_FAIL = 'TENANT_PERSON_SYNC_FAILED_HISTORY_FETCH_FAIL';

// Forms Builder Maganagement Actions
export const FORMS_GET_COUNT_SUCCESS = 'FORMS_GET_COUNT_SUCCESS';
export const FORMS_PAGINATION_ON = 'FORMS_PAGINATION_ON';
export const FORMS_PAGINATION_OFF = 'FORMS_PAGINATION_FF';
export const FORMS_GET_START = 'FORMS_GET_START';
export const FORMS_GET_SUCCESS = 'FORMS_GET_SUCCESS';
export const FORMS_GET_FAIL = 'FORMS_GET_FAIL';
export const FORMS_DYNAMIC_GET_START = 'FORMS_DYNAMIC_GET_START';
export const FORMS_DYNAMIC_GET_SUCCESS = 'FORMS_DYNAMIC_GET_SUCCESS';
export const FORMS_DYNAMIC_GET_FORCE_STOP = 'FORMS_DYNAMIC_GET_FORCE_STOP';
export const FORMS_DYNAMIC_GET_PARTIAL_SUCCESS = 'FORMS_DYNAMIC_GET_PARTIAL_SUCCESS';
export const FORMS_DYNAMIC_GET_FAIL = 'FORMS_DYNAMIC_GET_FAIL';
export const FORMS_FULLTEXTSEARCH_GET_START = 'FORMS_FULLTEXTSEARCH_GET_START';
export const FORMS_FULLTEXTSEARCH_GET_SUCCESS = 'FORMS_FULLTEXTSEARCH_GET_SUCCESS';
export const FORMS_FULLTEXTSEARCH_GET_FAIL = 'FORMS_FULLTEXTSEARCH_GET_FAIL';
export const FORMS_ADD_NEW_START = 'FORMS_ADD_NEW_START';
export const FORMS_ADD_NEW_SUCCESS = 'FORMS_ADD_NEW_SUCCESS';
export const FORMS_ADD_NEW_FAIL = 'FORMS_ADD_NEW_FAIL';
export const FORMS_GET_DETAIL_START = 'FORMS_GET_DETAIL_START';
export const FORMS_GET_DETAIL_SUCCESS = 'FORMS_GET_DETAIL_SUCCESS';
export const FORMS_GET_DETAIL_FAIL = 'FORMS_GET_DETAIL_FAIL';
export const FORMS_EDIT_START = 'FORMS_EDIT_START';
export const FORMS_EDIT_SUCCESS = 'FORMS_EDIT_SUCCESS';
export const FORMS_EDIT_FAIL = 'FORMS_EDIT_FAIL';
export const FORMS_CLONE_START = 'FORMS_CLONE_START';
export const FORMS_CLONE_SUCCESS = 'FORMS_CLONE_SUCCESS';
export const FORMS_CLONE_FAIL = 'FORMS_CLONE_FAIL';
export const FORMS_PUBLISH_START = 'FORMS_PUBLISH_START';
export const FORMS_PUBLISH_SUCCESS = 'FORMS_PUBLISH_SUCCESS';
export const FORMS_PUBLISH_FAIL = 'FORMS_PUBLISH_FAIL';
export const FORMS_ACTIVATE_START = 'FORMS_ACTIVATE_START';
export const FORMS_ACTIVATE_SUCCESS = 'FORMS_ACTIVATE_SUCCESS';
export const FORMS_ACTIVATE_FAIL = 'FORMS_ACTIVATE_FAIL';
export const FORMS_DELETE_START = 'FORMS_DELETE_START';
export const FORMS_DELETE_SUCCESS = 'FORMS_DELETE_SUCCESS';
export const FORMS_DELETE_FAIL = 'FORMS_DELETE_FAIL';
export const FORMS_GET_FORM_NUMBER_START = 'FORMS_GET_FORM_NUMBER_START';
export const FORMS_GET_FORM_NUMBER_SUCCESS = 'FORMS_GET_FORM_NUMBER_SUCCESS';
export const FORMS_GET_FORM_NUMBER_FAIL = 'FORMS_GET_FORM_NUMBER_FAIL';
export const FORMS_LOCKED_START = 'FORMS_LOCKED_START';
export const FORMS_LOCKED_SUCCESS = 'FORMS_LOCKED_SUCCESS';
export const FORMS_LOCKED_FAIL = 'FORMS_LOCKED_FAIL';
export const FORMS_UPDATE_LOCKED_START = 'FORMS_UPDATE_LOCKED_START';
export const FORMS_UPDATE_LOCKED_SUCCESS = 'FORMS_UPDATE_LOCKED_SUCCESS';
export const FORMS_UPDATE_LOCKED_FAIL = 'FORMS_UPDATE_LOCKED_FAIL';
export const FORMS_DELETE_LOCKED_START = 'FORMS_DELETE_LOCKED_START';
export const FORMS_DELETE_LOCKED_SUCCESS = 'FORMS_DELETE_LOCKED_SUCCESS';
export const FORMS_DELETE_LOCKED_FAIL = 'FORMS_DELETE_LOCKED_FAIL';
export const FORMS_GET_FORM_DATA_START = 'FORMS_GET_FORM_DATA_START';
export const FORMS_GET_FORM_DATA_SUCCESS = 'FORMS_GET_FORM_DATA_SUCCESS';
export const FORMS_GET_FORM_DATA_FAIL = 'FORMS_GET_FORM_DATA_FAIL';
export const COMPETENCY_GET_ALL_START = 'COMPETENCY_GET_ALL_START';
export const COMPETENCY_GET_ALL_SUCCESS = 'COMPETENCY_GET_ALL_SUCCESS';
export const COMPETENCY_GET_ALL_FAIL = 'COMPETENCY_GET_ALL_FAIL';
export const COMPETENCY_BULK_INSERT_START = 'COMPETENCY_BULK_INSERT_START';
export const COMPETENCY_BULK_INSERT_SUCCESS = 'COMPETENCY_BULK_INSERT_SUCCESS';
export const COMPETENCY_BULK_INSERT_FAIL = 'COMPETENCY_BULK_INSERT_FAIL';
export const FORMS_IMAGE_UPLOAD_START = 'FORMS_IMAGE_UPLOAD_START';
export const FORMS_IMAGE_UPLOAD_SUCCESS = 'FORMS_IMAGE_UPLOAD_SUCCESS';
export const FORMS_IMAGE_UPLOAD_FAIL = 'FORMS_IMAGE_UPLOAD_FAIL';
export const FORMS_IMAGE_UPLOAD_CLEAR = 'FORMS_IMAGE_UPLOAD_CLEAR';
export const FORMS_IMAGES_UPLOAD_START = 'FORMS_IMAGES_UPLOAD_START';
export const FORMS_IMAGES_UPLOAD_SUCCESS = 'FORMS_IMAGES_UPLOAD_SUCCESS';
export const FORMS_IMAGES_UPLOAD_FAIL = 'FORMS_IMAGES_UPLOAD_FAIL';

// Assessment Maganagement Actions
export const ASSESSMENTS_GET_COUNT_SUCCESS = 'ASSESSMENTS_GET_COUNT_SUCCESS';
export const ASSESSMENT_PAGINATION_ON = 'ASSESSMENT_PAGINATION_ON';
export const ASSESSMENT_PAGINATION_OFF = 'ASSESSMENT_PAGINATION_OFF';
export const ASSESSMENT_GET_START = 'ASSESSMENT_GET_START';
export const ASSESSMENT_GET_SUCCESS = 'ASSESSMENT_GET_SUCCESS';
export const ASSESSMENT_GET_FAIL = 'ASSESSMENT_GET_FAIL';
export const ASSESSMENTS_DYNAMIC_GET_START = 'ASSESSMENTS_DYNAMIC_GET_START';
export const ASSESSMENTS_DYNAMIC_GET_SUCCESS = 'ASSESSMENTS_DYNAMIC_GET_SUCCESS';
export const ASSESSMENTS_DYNAMIC_GET_FORCE_STOP = 'ASSESSMENTS_DYNAMIC_GET_FORCE_STOP';
export const ASSESSMENTS_DYNAMIC_GET_PARTIAL_SUCCESS = 'ASSESSMENTS_DYNAMIC_GET_PARTIAL_SUCCESS';
export const ASSESSMENTS_DYNAMIC_GET_FAIL = 'ASSESSMENTS_DYNAMIC_GET_FAIL';
export const ASSESSMENTS_FULLTEXTSEARCH_GET_START = 'ASSESSMENTS_FULLTEXTSEARCH_GET_START';
export const ASSESSMENTS_FULLTEXTSEARCH_GET_SUCCESS = 'ASSESSMENTS_FULLTEXTSEARCH_GET_SUCCESS';
export const ASSESSMENTS_FULLTEXTSEARCH_GET_FAIL = 'ASSESSMENTS_FULLTEXTSEARCH_GET_FAIL';
export const ASSESSMENT_ADD_NEW_START = 'ASSESSMENT_ADD_NEW_START';
export const ASSESSMENT_ADD_NEW_SUCCESS = 'ASSESSMENT_ADD_NEW_SUCCESS';
export const ASSESSMENT_ADD_NEW_FAIL = 'ASSESSMENT_ADD_NEW_FAIL';
export const ASSESSMENT_GET_DETAIL_START = 'ASSESSMENT_GET_DETAIL_START';
export const ASSESSMENT_GET_DETAIL_SUCCESS = 'ASSESSMENT_GET_DETAIL_SUCCESS';
export const ASSESSMENT_GET_DETAIL_FAIL = 'ASSESSMENT_GET_DETAIL_FAIL';
export const ASSESSMENT_EDIT_START = 'ASSESSMENT_EDIT_START';
export const ASSESSMENT_EDIT_SUCCESS = 'ASSESSMENT_EDIT_SUCCESS';
export const ASSESSMENT_EDIT_FAIL = 'ASSESSMENT_EDIT_FAIL';
export const ASSESSMENT_REVISE_START = 'ASSESSMENT_REVISE_START';
export const ASSESSMENT_REVISE_SUCCESS = 'ASSESSMENT_REVISE_SUCCESS';
export const ASSESSMENT_REVISE_FAIL = 'ASSESSMENT_REVISE_FAIL';
export const ASSESSMENT_DELETE_START = 'ASSESSMENT_DELETE_START';
export const ASSESSMENT_DELETE_SUCCESS = 'ASSESSMENT_DELETE_SUCCESS';
export const ASSESSMENT_DELETE_FAIL = 'ASSESSMENT_DELETE_FAIL';
export const ASSESSMENT_CANCEL_START = 'ASSESSMENT_CANCEL_START';
export const ASSESSMENT_CANCEL_SUCCESS = 'ASSESSMENT_CANCEL_SUCCESS';
export const ASSESSMENT_CANCEL_FAIL = 'ASSESSMENT_CANCEL_FAIL';
export const ASSESSMENT_APPROVE_START = 'ASSESSMENT_APPROVE_START';
export const ASSESSMENT_APPROVE_SUCCESS = 'ASSESSMENT_APPROVE_SUCCESS';
export const ASSESSMENT_APPROVE_FAIL = 'ASSESSMENT_APPROVE_FAIL';
export const ASSESSMENT_SEND_START = 'ASSESSMENT_SEND_START';
export const ASSESSMENT_SEND_SUCCESS = 'ASSESSMENT_SEND_SUCCESS';
export const ASSESSMENT_SEND_FAIL = 'ASSESSMENT_SEND_FAIL';
export const ASSESSMENT_REJECT_START = 'ASSESSMENT_REJECT_START';
export const ASSESSMENT_REJECT_SUCCESS = 'ASSESSMENT_REJECT_SUCCESS';
export const ASSESSMENT_REJECT_FAIL = 'ASSESSMENT_REJECT_FAIL';
export const ASSESSMENT_CHANGE_WPM_START = 'ASSESSMENT_CHANGE_WPM_START';
export const ASSESSMENT_CHANGE_WPM_SUCCESS = 'ASSESSMENT_CHANGE_WPM_SUCCESS';
export const ASSESSMENT_CHANGE_WPM_FAIL = 'ASSESSMENT_CHANGE_WPM_FAIL';
export const ASSESSMENT_GET_ALL_COMMENT_START = 'ASSESSMENT_GET_ALL_COMMENT_START';
export const ASSESSMENT_GET_ALL_COMMENT_SUCCESS = 'ASSESSMENT_GET_ALL_COMMENT_SUCCESS';
export const ASSESSMENT_GET_ALL_COMMENT_FAIL = 'ASSESSMENT_GET_ALL_COMMENT_FAIL';
export const ASSESSMENT_ADD_COMMENT_START = 'ASSESSMENT_ADD_COMMENT_START';
export const ASSESSMENT_ADD_COMMENT_SUCCESS = 'ASSESSMENT_ADD_COMMENT_SUCCESS';
export const ASSESSMENT_ADD_COMMENT_FAIL = 'ASSESSMENT_ADD_COMMENT_FAIL';
export const ASSESSMENT_EDIT_COMMENT_START = 'ASSESSMENT_EDIT_COMMENT_START';
export const ASSESSMENT_EDIT_COMMENT_SUCCESS = 'ASSESSMENT_EDIT_COMMENT_SUCCESS';
export const ASSESSMENT_EDIT_COMMENT_FAIL = 'ASSESSMENT_EDIT_COMMENT_FAIL';
export const ASSESSMENT_DELETE_COMMENT_START = 'ASSESSMENT_DELETE_COMMENT_START';
export const ASSESSMENT_DELETE_COMMENT_SUCCESS = 'ASSESSMENT_DELETE_COMMENT_SUCCESS';
export const ASSESSMENT_DELETE_COMMENT_FAIL = 'ASSESSMENT_DELETE_COMMENT_FAIL';
export const ASSESSMENT_PRINT_PDF_START = 'ASSESSMENT_PRINT_PDF_START';
export const ASSESSMENT_PRINT_PDF_SUCCESS = 'ASSESSMENT_PRINT_PDF_SUCCESS';
export const ASSESSMENT_PRINT_PDF_FAIL = 'ASSESSMENT_PRINT_PDF_FAIL';
export const ASSESSMENT_RESEND_START = 'ASSESSMENT_RESEND_START';
export const ASSESSMENT_RESEND_SUCCESS = 'ASSESSMENT_RESEND_SUCCESS';
export const ASSESSMENT_RESEND_FAIL = 'ASSESSMENT_RESEND_FAIL';
export const ASSESSMENT_SYNC_ERRORS_FETCH_START = 'ASSESSMENT_SYNC_ERRORS_FETCH_START';
export const ASSESSMENT_SYNC_ERRORS_FETCH_PARTIAL_SUCCESS = 'ASSESSMENT_SYNC_ERRORS_FETCH_PARTIAL_SUCCESS';
export const ASSESSMENT_SYNC_ERRORS_FETCH_SUCCESS = 'ASSESSMENT_SYNC_ERRORS_FETCH_SUCCESS';
export const ASSESSMENT_SYNC_ERRORS_FETCH_FAIL = 'ASSESSMENT_SYNC_ERRORS_FETCH_FAIL';
export const ASSESSMENT_SYNC_ERRORS_GET_FORCE_STOP = 'ASSESSMENT_SYNC_ERRORS_GET_FORCE_STOP';

// Tenant Configuration Actions
export const TENANT_CONFIGURATION_FETCH_START = 'TENANT_CONFIGURATION_FETCH_START';
export const TENANT_CONFIGURATION_FETCH_SUCCESS = 'TENANT_CONFIGURATION_FETCH_SUCCESS';
export const TENANT_CONFIGURATION_FETCH_FAIL = 'TENANT_CONFIGURATION_FETCH_FAIL';
export const TENANT_CONFIGURATION_UPDATE_START = 'TENANT_CONFIGURATION_UPDATE_START';
export const TENANT_CONFIGURATION_UPDATE_SUCCESS = 'TENANT_CONFIGURATION_UPDATE_SUCCESS';
export const TENANT_CONFIGURATION_UPDATE_FAIL = 'TENANT_CONFIGURATION_UPDATE_FAIL';
export const TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_START = 'TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_START';
export const TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_SUCCESS = 'TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_SUCCESS';
export const TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_FAIL = 'TENANT_WPMDAILYEMAIL_CONFIGURATION_UPDATE_FAIL';
export const TENANT_SYNC_START = 'TENANT_SYNC_START';
export const TENANT_SYNC_STATUS_START = 'TENANT_SYNC_STATUS_START';
export const TENANT_SYNC_ASSESSOR_SUCCESS = 'TENANT_SYNC_ASSESSOR_SUCCESS';
export const TENANT_SYNC_TRAINEE_SUCCESS = 'TENANT_SYNC_TRAINEE_SUCCESS';
export const TENANT_SYNC_COMPETENCY_SUCCESS = 'TENANT_SYNC_COMPETENCY_SUCCESS';
export const TENANT_SYNC_STATUS_ASSESSOR_SUCCESS = 'TENANT_SYNC_STATUS_ASSESSOR_SUCCESS';
export const TENANT_SYNC_STATUS_TRAINEE_SUCCESS = 'TENANT_SYNC_STATUS_TRAINEE_SUCCESS';
export const TENANT_SYNC_STATUS_COMPETENCY_SUCCESS = 'TENANT_SYNC_STATUS_COMPETENCY_SUCCESS';
export const TENANT_SYNC_FAIL = 'TENANT_SYNC_FAIL';
export const TENANT_SYNC_STATUS_FAIL = 'TENANT_SYNC_STATUS_FAIL';
export const SET_ROLES = 'SET_ROLES';

// Send Email Actions
export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

// Dashboard
export const DASHBOARD_ASSESSMENT_START = 'DASHBOARD_ASSESSMENT_NEED_APPROVAL_START';
export const DASHBOARD_ASSESSMENT_SUCCESS = 'DASHBOARD_ASSESSMENT_NEED_APPROVAL_SUCCESS';
export const DASHBOARD_ASSESSMENT_FAIL = 'DASHBOARD_ASSESSMENT_NEED_APPROVAL_FAIL';
export const DASHBOARD_ASSESSMENT_COMPLETED_START = 'DASHBOARD_ASSESSMENT_COMPLETED_START';
export const DASHBOARD_ASSESSMENT_COMPLETED_SUCCESS = 'DASHBOARD_ASSESSMENT_COMPLETED_SUCCESS';
export const DASHBOARD_ASSESSMENT_COMPLETED_FAIL = 'DASHBOARD_ASSESSMENT_COMPLETED_FAIL';
export const DASHBOARD_ASSESSMENT_REJECTED_START = 'DASHBOARD_ASSESSMENT_REJECTED_START';
export const DASHBOARD_ASSESSMENT_REJECTED_SUCCESS = 'DASHBOARD_ASSESSMENT_REJECTED_SUCCESS';
export const DASHBOARD_ASSESSMENT_REJECTED_FAIL = 'DASHBOARD_ASSESSMENT_REJECTED_FAIL';
export const DASHBOARD_FORM_START = 'DASHBOARD_FORM_START';
export const DASHBOARD_FORM_SUCCESS = 'DASHBOARD_FORM_SUCCESS';
export const DASHBOARD_FORM_FAIL = 'DASHBOARD_FORM_FAIL';

import assessmentReducer from '../../features/forms/reducer';
import authReducer from '../../features/auth/reducer';
import { combineReducers } from 'redux';
import emailReducer from '../../features/email/reducer';
import formBuilderReducer from '../../features/templates/reducer';
import resetPasswordRequestReducer from '../../features/auth-password/reducer';
import tenantReducer from '../../features/tenants/reducer';
import traineeReducer from '../../features/trainees/reducer';
import userReducer from '../../features/users/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  formBuilder: formBuilderReducer,
  tenant: tenantReducer,
  assessment: assessmentReducer,
  trainee: traineeReducer,
  email: emailReducer,
  resetPasswordRequest: resetPasswordRequestReducer,
});

export default rootReducer;

import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Roles from 'domain/roles';

class AppSubmenu extends Component {
  static defaultProps = {
    className: null,
    items: null,
    userRoles: null,
    onMenuItemClick: null,
    root: false,
  };

  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    userRoles: PropTypes.string,
    onMenuItemClick: PropTypes.func,
    root: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null,
    };
  }

  onMenuItemClick(event, item, index) {
    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (!item.items) {
      if (index === this.state.activeIndex) this.setState({ activeIndex: null });
      else this.setState({ activeIndex: index });
    }

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }

    return null;
  }

  renderLinkContent(item, i) {
    let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

    if (item.items) {
      return (
        <>
          <NavLink to={item.to} activeClassName={"selected"}>
            <i className={item.icon} onClick={(e) => this.onMenuItemClick(e, item, i)}></i>
            <span onClick={(e) => this.onMenuItemClick(e, item, i)}>{item.label}</span>
          </NavLink>
          {badge}
        </>
      );
    } else {
      return (
        <>
          <i className={item.icon}></i>
          <span>{item.label}</span>
          {badge}
        </>
      );
    }
  }

  renderLink(item, i) {
    var isTenantIntegrated = localStorage.getItem('IsIntegrationTenant');
    let content = this.renderLinkContent(item, i);

    if (isTenantIntegrated === 'False' && item.label === 'Form Sync Alerts') {
      return <span></span>;
    }

    if (item.to && !(item.items && item.items.length > 0)) {
      return (
        <NavLink
          activeClassName={"active-route"}
          to={item.to}
          onClick={(e) => this.onMenuItemClick(e, item, i)}
          exact
          target={item.target}
        >
          {content}
        </NavLink>
      );
    } else {
      return <span>{content}</span>;
    }
  }

  filterSubItems(items) {
    const roles = this.props.userRoles.split(',');
    let filteredItems = [];

    if (items) {
      filteredItems = items.filter((item) => {
        return item.roles.some((itemRole) => itemRole === Roles.All || roles.some((userRole) => itemRole === userRole));
      });
    }

    return filteredItems;
  }

  getActiveItem(filteredItems) {
    let exactPath = false;
    let activeItem = null;
    let path = window.location.pathname;
    path.indexOf(1);
    path.toLowerCase();
    path = path.split('/')[1];
    path = '/' + path;

    activeItem = filteredItems.find(
      (it) => it.items && it.items.length > 0 && it.items.some((item) => item.to === path)
    );
    if (activeItem === null || activeItem === undefined) {
      activeItem = filteredItems.find((it) => it.to === path);
      if (activeItem) {
        exactPath = true;
      }
    }

    // if (this.state.activeIndex != null && this.state.activeIndex >= 0) {
    //   activeItem = filteredItems[this.state.activeIndex];
    //   exactPath = true;
    // } else {
    //   let path = window.location.pathname;
    //   path.indexOf(1);
    //   path.toLowerCase();
    //   path = path.split('/')[1];
    //   path = '/' + path.charAt(0).toUpperCase();

    //   activeItem = filteredItems.find(
    //     (it) => it.items && it.items.length > 0 && it.items.some((item) => item.to === path)
    //   );
    //   if (activeItem === null || activeItem === undefined) {
    //     activeItem = filteredItems.find((it) => it.to === path);
    //     if (activeItem) {
    //       exactPath = true;
    //     }
    //   }
    // }

    return { activeItem, exactPath };
  }

  render() {
    const filteredItems = this.filterSubItems(this.props.items);

    const { activeItem, exactPath } = this.getActiveItem(filteredItems);

    let items =
      filteredItems &&
      filteredItems.map((item, i) => {
        //let active = this.state.activeIndex === i || (activeItem && activeItem.label === item.label);
        let active = activeItem?.to === item.to || (activeItem && activeItem.label === item.label);

        let styleClass = classNames({
          'active-menuitem': active && exactPath,
        });

        return (
          <li className={styleClass} key={i}>
            {this.renderLink(item, i)}
            {item.items && (
              <AppSubmenu
                items={item.items}
                userRoles={this.props.userRoles}
                onMenuItemClick={this.props.onMenuItemClick}
              />
            )}
          </li>
        );
      });

    return items && items.length > 0 && <ul className={this.props.className}>{items}</ul>;
  }
}

export const AppMenu = ({ model, userRoles, onMenuItemClick }) => {
  return (
    <div className="layout-menu-container">
      <AppSubmenu
        items={model}
        userRoles={userRoles}
        className="layout-menu"
        onMenuItemClick={onMenuItemClick}
        root={true}
      />
    </div>
  );
};

AppMenu.defaultProps = {
  model: null,
  userRoles: null,
  onMenuItemClick: null,
};

AppMenu.propTypes = {
  model: PropTypes.array,
  userRoles: PropTypes.string,
  onMenuItemClick: PropTypes.func,
};

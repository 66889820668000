import * as actionTypes from '../../app/store/actionTypes';

import { updateState } from '../../util/updateState';

const initialState = {
  users: [],
  userWpms: [],
  user: {},
  loading: false,
  error: null,
};

// Fetch List User state
const fetchUsersStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const fetchUsersSuccess = (state, action) => {
  return updateState(state, {
    users: action.usersData,
    user: {},
    loading: false,
    error: null,
  });
};
const fetchUsersFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Fetch List User WPM state
const fetchUserWPMsStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const fetchUserWPMsSuccess = (state, action) => {
  return updateState(state, {
    userWpms: action.usersData,
    loading: false,
    error: null,
  });
};
const fetchUserWPMsFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Get details user state
const fetchUserStart = (state, action) => {
  return updateState(state, { loading: true, user: {}, error: null });
};
const fetchUserSuccess = (state, action) => {
  return updateState(state, {
    user: action.userData,
    loading: false,
    error: null,
  });
};
const fetchUserFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Create new User
const createUserStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const createUserSuccess = (state, action) => {
  return updateState(state, {
    user: action.userData,
    loading: false,
    error: null,
  });
};
const createUserFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Insert bulk User
const insertBulkUserStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const insertBulkUserSuccess = (state, action) => {
  return updateState(state, {
    users: action.users,
    loading: false,
    error: null,
  });
};
const insertBulkUserFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Update User
const updateUserStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const updateUserSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    error: null,
  });
};
const updateUserFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_FETCH_START:
      return fetchUsersStart(state, action);
    case actionTypes.USER_FETCH_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.USER_FETCH_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.USER_WPM_FETCH_START:
      return fetchUserWPMsStart(state, action);
    case actionTypes.USER_WPM_FETCH_SUCCESS:
      return fetchUserWPMsSuccess(state, action);
    case actionTypes.USER_WPM_FETCH_FAIL:
      return fetchUserWPMsFail(state, action);
    case actionTypes.USER_GET_DETAIL_START:
      return fetchUserStart(state, action);
    case actionTypes.USER_GET_DETAIL_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.USER_GET_DETAIL_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.USER_ADD_NEW_START:
      return createUserStart(state, action);
    case actionTypes.USER_ADD_NEW_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.USER_ADD_NEW_FAIL:
      return createUserFail(state, action);
    case actionTypes.USER_EDIT_START:
      return updateUserStart(state, action);
    case actionTypes.USER_EDIT_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.USER_EDIT_FAIL:
      return updateUserFail(state, action);
    case actionTypes.USER_BULKINSERT_START:
      return insertBulkUserStart(state, action);
    case actionTypes.USER_BULKINSERT_SUCCESS:
      return insertBulkUserSuccess(state, action);
    case actionTypes.USER_BULKINSERT_FAIL:
      return insertBulkUserFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import asyncComponent from '../AsyncComponent';

// Lazy load Trainee management
export const AsyncTraineeManagementPage = asyncComponent(() => {
  return import('../../../features/trainees/TraineeManagementPage');
});
export const AsyncTraineeCreatePage = asyncComponent(() => {
  return import('../../../features/trainees/TraineeCreatePage');
});
export const AsyncTraineeEditPage = asyncComponent(() => {
  return import('../../../features/trainees/TraineeEditPage');
});
export const AsyncTraineeDetailPage = asyncComponent(() => {
  return import('../../../features/trainees/TraineeDetailPage');
});
export const AsyncTraineeInsertPage = asyncComponent(() => {
  return import('../../../features/trainees/TraineeInsertPage');
});

export const USER_ROLES = [
  { label: 'Training Admin', value: 'TrainingAdmin' },
  { label: 'Assessor', value: 'Assessor' },
  { label: 'Subject Matter Expert', value: 'SME' },
  { label: 'Workplace Manager', value: 'WPM' },
];
export const USER_ROLES_TENANT_ADMIN = [
  { label: 'Training Admin', value: 'TrainingAdmin' },
  { label: 'Assessor', value: 'Assessor' },
  { label: 'Subject Matter Expert', value: 'SME' },
  { label: 'Workplace Manager', value: 'WPM' },
];
export const USER_ROLES_SUPER_ADMIN = [
  { label: 'Super Admin', value: 'SuperAdmin' },
  { label: 'Tenant Admin', value: 'TenantAdmin' },
  { label: 'Training Admin', value: 'TrainingAdmin' },
  { label: 'Assessor', value: 'Assessor' },
  { label: 'Subject Matter Expert', value: 'SME' },
  { label: 'Workplace Manager', value: 'WPM' },
];
export const FORM_STATUS = ['All', 'Draft', 'Published', 'Deactivated'];
export const TENANT_STATUS = ['All', 'Active', 'Inactive'];
export const USER_STATUS = ['All', 'Active', 'Inactive'];
export const TRAINEE_STATUS = ['All', 'Active', 'Inactive'];
export const ASSESSMENT_STATUS = ['All', 'Approved', 'Cancelled', 'Completed', 'Expired', 'In Progress', 'Rejected'];
export const DATE_FILTER_ALL = [
  'Last Modified Date',
  'Created Date',
  'Completed Date',
  'Approved Date',
  'Cancelled Date',
  'Expiry Date',
];
export const DATE_FILTER_INPROGRESS = ['Last Modified Date', 'Created Date', 'Expiry Date'];
export const DATE_FILTER_COMPLETED = ['Last Modified Date', 'Created Date', 'Completed Date'];
export const DATE_FILTER_APPROVED = ['Last Modified Date', 'Created Date', 'Completed Date', 'Approved Date'];
export const DATE_FILTER_CANCELLED = ['Last Modified Date', 'Created Date', 'Cancelled Date'];
export const DATE_FILTER_EXPIRED = ['Last Modified Date', 'Created Date', 'Expiry Date'];
export const IMG_COMPRESS_OPTIONS = {
  maxSizeMB: 1,
  maxWidthOrHeight: 630,
  useWebWorker: true,
};
export const OPTIONS_COMPETENCY = [
  {
    Id: 1,
    Name: 'Machinery Fires',
    Elements: [
      { Id: 215, Name: 'Familiarisation', IsMandatory: true, IsActive: true },
      { Id: 425, Name: 'Formal Training', IsMandatory: true, IsActive: true },
      { Id: 635, Name: 'Practical Experience', IsMandatory: true, IsActive: true },
      { Id: 845, Name: 'Assessment', IsMandatory: true, IsActive: true },
    ],
    IsActive: true,
  },
  {
    Id: 2,
    Name: 'Truck Dozer',
    Elements: [
      { Id: 225, Name: 'Familiarisation Dozer', IsMandatory: true, IsActive: true },
      { Id: 435, Name: 'Formal Training Dozer', IsMandatory: true, IsActive: true },
      { Id: 645, Name: 'Dozer Experience', IsMandatory: true, IsActive: true },
      { Id: 855, Name: 'Dozer Assessment', IsMandatory: true, IsActive: true },
    ],
    IsActive: true,
  },
  {
    Id: 3,
    Name: 'Dump Truck',
    Elements: [],
    IsActive: true,
  },
];

export const LABEL_FORM_SETTING = {
  SME_SIGNATURE: 'Display Subject Matter Expert signature section',
  WPM_APPROVAL: 'Workplace Manager approval is required',
};
export const INVITATION_STATUS = [
  'Draft',
  'Sent',
  'Expired',
  'Expiring Soon',
  'Accepted and In Progress',
  'Require Approval',
  'Declined',
  'Rejected',
  'Approved',
];

export const DOCUMENT_ALLOWED_FILE_TYPES = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'gif'];
export const DOCUMENT_ALLOWED_MAX_FILE_SIZE = 20000000;

export const CUSTOM_FIELD_TYPE = {
  DROPDOWN: 'Dropdown',
  TEXT: 'Text',
  DATE: 'Date',
  TIME: 'Time',
};

export const TENANT_CODE_REGEX_PATTERN = /^(?=(.*\d){2})(?=.*[a-z])[0-9a-z]{8,20}$/;

export const WMP_DAILY_EMAIL_DEFAULT_SUBJECT =
  'As a reminder, you have [[X]] assessments outstanding which require your approval.';
export const WMP_DAILY_EMAIL_DEFAULT_BODY =
  "<p><span style='font-size: 14px;'>Good Day [[First Name]],</span></p>" +
  '<p></p>' +
  "<p><span style='font-size: 14px;'>As a reminder, you have [[X]] assessments outstanding which you have been assigned as the Workplace Manager (WPM) and require your approval.</span></p>" +
  '<p></p>' +
  "<p><span style='font-size: 14px;'>In order to review and approve the outstanding assessment(s), please perform the following steps:</span></p>" +
  '<ol>' +
  "<li><span style='font-size: 14px;'>Login to the INX Assessor Web Application ([[Assessor Portal URL]])</span></li>" +
  "<li><span style='font-size: 14px;'>Click on the Needs Approval tile from the Dashboard menu;</span></li>" +
  "<li><span style='font-size: 14px;'>View the assessments that are assigned to you in your Assessment List;</span></li>" +
  "<li><span style='font-size: 14px;'>Once the assessment has been reviewed (and you are happy to approve), click on the Approve Assessment button (green tick) in the Action column.</span></li>" +
  '</ol>' +
  "<p><span style='font-size: 14px;'>If you believe this assessment is not completed as required - click on the Reject Assessment button and this will revert back to the assessor for re-assignment.</span></p>" +
  "<p><span style='font-size: 14px;'>If you are incorrectly assigned an assessment – click on the Reject Assessment button and this will revert back to the Assessor for re-assignment.</span></p>";

export const REACT_APP_MESSAGE_GROWL_DEFAULT_LIFE = 5000;
export const PUBLIC_API_KEY = 'X-PublicApi-Key';

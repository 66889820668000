import * as actionTypes from '../../app/store/actionTypes';

import _ from 'lodash';
import { updateState } from '../../util/updateState';

const initialState = {
  assessments: [],
  assessment: {},
  comments: [],
  comment: {},
  printData: '',
  assessmentsCount: 0,
  loading: false,
  loadingBackground: false,
  assessmentDashboard: {},
  assessmentDashboardCompletedCount: 0,
  assessmentDashboardRejectedCount: 0,
  assessmentSyncErrors: [],
  totalAssessmentSyncErrors: 0,
  forceStop: false,
  fullTextSearchShowResult: true,
  error: null,
  pagination: false,
};

const turnOnPagination = (state, action) => {
  return updateState(state, { pagination: true });
};

const turnOffPagination = (state, action) => {
  return updateState(state, { pagination: false });
};

const fetchAssessmentsStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};

const fetchAssessmentsSuccess = (state, action) => {
  return updateState(state, {
    assessments: action.assessmentsData,
    loading: false,
    error: null,
  });
};

const fetchAssessmentsFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

//dynamic get assessments
const fetchAssessmentsDynamicStart = (state, action) => {
  var result = [];
  if (state.pagination) {
    //continue with previous fetching
    result = state.assessments;
    return updateState(state, {
      assessments: result,
      loadingBackground: false,
      fullTextSearchShowResult: false,
      loading: true,
      error: null,
      forceStop: false,
    });
  }
  return updateState(state, {
    assessments: result,
    loadingBackground: false,
    fullTextSearchShowResult: false,
    error: null,
    loading: true,
    forceStop: false,
  });
};

const fetchAssessmentsDynamicSuccess = (state, action) => {
  var updatedList = [];
  if (!state.forceStop) {
    updatedList = _.concat(state.assessments, action.assessmentsData);
    updatedList = _.uniqBy(updatedList, (e) => {
      return e.assessmentUuid;
    });
  }

  return updateState(state, {
    assessments: updatedList,
    loadingBackground: false,
    forceStop: false,
    error: null,
    loading: false,
    pagination: false,
  });
};

const fetchAssessmentsCountSuccess = (state, action) => {
  return updateState(state, {
    assessmentsCount: action.assessmentsCount,
  });
};

const fetchAssessmentsDynamicForceStop = (state, action) => {
  return updateState(state, {
    forceStop: true,
  });
};
const fetchAssessmentsDynamicPartialSuccess = (state, action) => {
  var updatedList = _.concat(state.assessments, action.assessmentsData);
  return updateState(state, {
    assessments: updatedList,
    error: null,
  });
};

const fetchAssessmentsDynamicFail = (state, action) => {
  return updateState(state, { error: action.error, loadingBackground: false });
};

//full text get assessments
const fetchAssessmentsFullTextSearchStart = (state, action) => {
  var result = [];
  if (state.pagination) {
    result = state.assessments;
  }

  return updateState(state, {
    assessments: result,
    loading: true,
    loadingBackground: false,
    fullTextSearchShowResult: true,
  });
};

const fetchAssessmentsFullTextSearchSuccess = (state, action) => {
  var updatedList = _.concat(state.assessments, action.assessmentsData);
  updatedList = _.uniqBy(updatedList, (e) => {
    return e.assessmentUuid;
  });
  if (!state.fullTextSearchShowResult) {
    return updateState(state, {
      assessments: [],
      loading: false,
      loadingBackground: false,
      error: null,
      pagination: false,
      forceStop: false,
    });
  }
  return updateState(state, {
    assessments: updatedList,
    loading: false,
    loadingBackground: false,
    error: null,
    pagination: false,
    forceStop: true,
  });
};

const fetchAssessmentsFullTextSearchFail = (state, action) => {
  return updateState(state, { error: action.error, loadingBackground: false });
};

const getAssessmentDetailStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const getAssessmentDetailSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
    error: null,
  });
};
const getAssessmentDetailFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const createAssessmentStart = (state, action) => {
  return updateState(state, { loading: true });
};
const createAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
  });
};
const createAssessmentFail = (state, action) => {
  return updateState(state, { loading: false });
};

const editAssessmentStart = (state, action) => {
  return updateState(state, { loading: true });
};
const editAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
  });
};
const editAssessmentFail = (state, action) => {
  return updateState(state, { loading: false });
};

const deleteAssessmentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const deleteAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
    error: null,
  });
};
const deleteAssessmentFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

const cancelAssessmentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const cancelAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
    error: null,
  });
};
const cancelAssessmentFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

const approveAssessmentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const approveAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
    error: null,
  });
};
const approveAssessmentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const sendAssessmentToINXStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const sendAssessmentToINXSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
    error: null,
  });
};
const sendAssessmentToINXFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const rejectAssessmentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const rejectAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
    error: null,
  });
};
const rejectAssessmentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const reviseAssessmentStart = (state, action) => {
  return updateState(state, { loading: true });
};
const reviseAssessmentSuccess = (state, action) => {
  return updateState(state, {
    assessment: action.assessmentData,
    loading: false,
  });
};
const reviseAssessmentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const changeAssessmentWPMStart = (state, action) => {
  return updateState(state, { error: null, loading: true });
};
const changeAssessmentWPMSuccess = (state, action) => {
  return updateState(state, {
    error: null,
    loading: false,
  });
};
const changeAssessmentWPMFail = (state, action) => {
  return updateState(state, { loading: false, error: action.error });
};

// ---COMMENT---
const getAllAssessmentCommentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const getAllAssessmentCommentSuccess = (state, action) => {
  return updateState(state, {
    comments: action.commentsData,
    loading: false,
    error: null,
  });
};
const getAllAssessmentCommentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const addAssessmentCommentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const addAssessmentCommentSuccess = (state, action) => {
  return updateState(state, {
    comment: action.commentData,
    loading: false,
    error: null,
  });
};
const addAssessmentCommentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const editAssessmentCommentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const editAssessmentCommentSuccess = (state, action) => {
  return updateState(state, {
    comment: action.commentData,
    loading: false,
    error: null,
  });
};
const editAssessmentCommentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const deleteAssessmentCommentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const deleteAssessmentCommentSuccess = (state, action) => {
  return updateState(state, {
    comment: action.commentData,
    loading: false,
    error: null,
  });
};
const deleteAssessmentCommentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const printPDFAssessmentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const printPDFAssessmentSuccess = (state, action) => {
  return updateState(state, {
    printData: action.printData,
    loading: false,
    error: null,
  });
};
const printPDFAssessmentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// assessment dashboard
const dashboardAssessmentsActionStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const dashboardAssessmentsActionSuccess = (state, action) => {
  return updateState(state, {
    assessmentDashboard: action.assessmentDashboard,
    loading: false,
    error: null,
  });
};
const dashboardAssessmentsActionFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const dashboardAssessmentsCompletedActionStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const dashboardAssessmentsCompletedActionSuccess = (state, action) => {
  return updateState(state, {
    assessmentDashboardCompletedCount: action.count,
    loading: false,
    error: null,
  });
};
const dashboardAssessmentsCompletedActionFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

const dashboardAssessmentsRejectedActionStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const dashboardAssessmentsRejectedActionSuccess = (state, action) => {
  return updateState(state, {
    assessmentDashboardRejectedCount: action.count,
    loading: false,
    error: null,
  });
};
const dashboardAssessmentsRejectedActionFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// resend assessment
const resendAssessmentStart = (state, action) => {
  return updateState(state, { loading: true, error: null });
};
const resendAssessmentSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    error: null,
  });
};
const resendAssessmentFail = (state, action) => {
  return updateState(state, { error: action.error, loading: false });
};

// Assessment Sync Errors History
const fetchAssessmentSyncErrorsStart = (state, action) => {
  return updateState(state, { loading: true, assessmentSyncErrors: [] });
};
const fetchAssessmentSyncErrorsSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    assessmentSyncErrors: action.historyData && action.historyData.result ? action.historyData.result : [],
    totalAssessmentSyncErrors:
      action.historyData && action.historyData.totalRecord ? action.historyData.totalRecord : [],
  });
};
const fetchAssessmentSyncErrorsFail = (state, action) => {
  return updateState(state, { loading: false, assessmentSyncErrors: [] });
};

// eslint-disable-next-line complexity
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ASSESSMENTS_GET_COUNT_SUCCESS:
      return fetchAssessmentsCountSuccess(state, action);

    case actionTypes.ASSESSMENT_PAGINATION_ON:
      return turnOnPagination(state, action);
    case actionTypes.ASSESSMENT_PAGINATION_OFF:
      return turnOffPagination(state, action);
    case actionTypes.ASSESSMENT_GET_START:
      return fetchAssessmentsStart(state, action);
    case actionTypes.ASSESSMENT_GET_SUCCESS:
      return fetchAssessmentsSuccess(state, action);
    case actionTypes.ASSESSMENT_GET_FAIL:
      return fetchAssessmentsFail(state, action);
    case actionTypes.ASSESSMENTS_DYNAMIC_GET_START:
      return fetchAssessmentsDynamicStart(state, action);
    case actionTypes.ASSESSMENTS_DYNAMIC_GET_SUCCESS:
      return fetchAssessmentsDynamicSuccess(state, action);

    case actionTypes.ASSESSMENTS_DYNAMIC_GET_FORCE_STOP:
      return fetchAssessmentsDynamicForceStop(state, action);
    case actionTypes.ASSESSMENTS_DYNAMIC_GET_PARTIAL_SUCCESS:
      return fetchAssessmentsDynamicPartialSuccess(state, action);
    case actionTypes.ASSESSMENTS_DYNAMIC_GET_FAIL:
      return fetchAssessmentsDynamicFail(state, action);

    case actionTypes.ASSESSMENTS_FULLTEXTSEARCH_GET_START:
      return fetchAssessmentsFullTextSearchStart(state, action);
    case actionTypes.ASSESSMENTS_FULLTEXTSEARCH_GET_SUCCESS:
      return fetchAssessmentsFullTextSearchSuccess(state, action);
    case actionTypes.ASSESSMENTS_FULLTEXTSEARCH_GET_FAIL:
      return fetchAssessmentsFullTextSearchFail(state, action);

    case actionTypes.ASSESSMENT_GET_DETAIL_START:
      return getAssessmentDetailStart(state, action);
    case actionTypes.ASSESSMENT_GET_DETAIL_SUCCESS:
      return getAssessmentDetailSuccess(state, action);
    case actionTypes.ASSESSMENT_GET_DETAIL_FAIL:
      return getAssessmentDetailFail(state, action);
    case actionTypes.ASSESSMENT_REVISE_START:
      return reviseAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_REVISE_SUCCESS:
      return reviseAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_REVISE_FAIL:
      return reviseAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_ADD_NEW_START:
      return createAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_ADD_NEW_SUCCESS:
      return createAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_ADD_NEW_FAIL:
      return createAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_EDIT_START:
      return editAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_EDIT_SUCCESS:
      return editAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_EDIT_FAIL:
      return editAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_DELETE_START:
      return deleteAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_DELETE_SUCCESS:
      return deleteAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_DELETE_FAIL:
      return deleteAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_CANCEL_START:
      return cancelAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_CANCEL_SUCCESS:
      return cancelAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_CANCEL_FAIL:
      return cancelAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_APPROVE_START:
      return approveAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_APPROVE_SUCCESS:
      return approveAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_APPROVE_FAIL:
      return approveAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_SEND_START:
      return sendAssessmentToINXStart(state, action);
    case actionTypes.ASSESSMENT_SEND_SUCCESS:
      return sendAssessmentToINXSuccess(state, action);
    case actionTypes.ASSESSMENT_SEND_FAIL:
      return sendAssessmentToINXFail(state, action);
    case actionTypes.ASSESSMENT_REJECT_START:
      return rejectAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_REJECT_SUCCESS:
      return rejectAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_REJECT_FAIL:
      return rejectAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_CHANGE_WPM_START:
      return changeAssessmentWPMStart(state, action);
    case actionTypes.ASSESSMENT_CHANGE_WPM_SUCCESS:
      return changeAssessmentWPMSuccess(state, action);
    case actionTypes.ASSESSMENT_CHANGE_WPM_FAIL:
      return changeAssessmentWPMFail(state, action);
    case actionTypes.ASSESSMENT_GET_ALL_COMMENT_START:
      return getAllAssessmentCommentStart(state, action);
    case actionTypes.ASSESSMENT_GET_ALL_COMMENT_SUCCESS:
      return getAllAssessmentCommentSuccess(state, action);
    case actionTypes.ASSESSMENT_GET_ALL_COMMENT_FAIL:
      return getAllAssessmentCommentFail(state, action);
    case actionTypes.ASSESSMENT_ADD_COMMENT_START:
      return addAssessmentCommentStart(state, action);
    case actionTypes.ASSESSMENT_ADD_COMMENT_SUCCESS:
      return addAssessmentCommentSuccess(state, action);
    case actionTypes.ASSESSMENT_ADD_COMMENT_FAIL:
      return addAssessmentCommentFail(state, action);
    case actionTypes.ASSESSMENT_EDIT_COMMENT_START:
      return editAssessmentCommentStart(state, action);
    case actionTypes.ASSESSMENT_EDIT_COMMENT_SUCCESS:
      return editAssessmentCommentSuccess(state, action);
    case actionTypes.ASSESSMENT_EDIT_COMMENT_FAIL:
      return editAssessmentCommentFail(state, action);
    case actionTypes.ASSESSMENT_DELETE_COMMENT_START:
      return deleteAssessmentCommentStart(state, action);
    case actionTypes.ASSESSMENT_DELETE_COMMENT_SUCCESS:
      return deleteAssessmentCommentSuccess(state, action);
    case actionTypes.ASSESSMENT_DELETE_COMMENT_FAIL:
      return deleteAssessmentCommentFail(state, action);
    case actionTypes.ASSESSMENT_PRINT_PDF_START:
      return printPDFAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_PRINT_PDF_SUCCESS:
      return printPDFAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_PRINT_PDF_FAIL:
      return printPDFAssessmentFail(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_START:
      return dashboardAssessmentsActionStart(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_SUCCESS:
      return dashboardAssessmentsActionSuccess(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_FAIL:
      return dashboardAssessmentsActionFail(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_COMPLETED_START:
      return dashboardAssessmentsCompletedActionStart(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_COMPLETED_SUCCESS:
      return dashboardAssessmentsCompletedActionSuccess(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_COMPLETED_FAIL:
      return dashboardAssessmentsCompletedActionFail(state, action);

    case actionTypes.DASHBOARD_ASSESSMENT_REJECTED_START:
      return dashboardAssessmentsRejectedActionStart(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_REJECTED_SUCCESS:
      return dashboardAssessmentsRejectedActionSuccess(state, action);
    case actionTypes.DASHBOARD_ASSESSMENT_REJECTED_FAIL:
      return dashboardAssessmentsRejectedActionFail(state, action);

    case actionTypes.ASSESSMENT_RESEND_START:
      return resendAssessmentStart(state, action);
    case actionTypes.ASSESSMENT_RESEND_SUCCESS:
      return resendAssessmentSuccess(state, action);
    case actionTypes.ASSESSMENT_RESEND_FAIL:
      return resendAssessmentFail(state, action);
    case actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_START:
      return fetchAssessmentSyncErrorsStart(state, action);
    case actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_SUCCESS:
      return fetchAssessmentSyncErrorsSuccess(state, action);
    case actionTypes.ASSESSMENT_SYNC_ERRORS_FETCH_FAIL:
      return fetchAssessmentSyncErrorsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

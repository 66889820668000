import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from './actions';

class Logout extends Component {
  componentDidMount() {
    this.props.onLogout.authLogout();
  }

  render() {
    return <Navigate to="/login" />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Logout);

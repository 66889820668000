import ErrorHandler from './errorResponseHandler';
import Instance from './AxiosInstance';
import { jwtDecode as jwt_decode } from 'jwt-decode';

export const axios = Instance.assessmentInstance();

class AuthApi {
  static login(tenant, userid, password) {
    const body = {
      tenant: tenant,
      userName: userid,
      password: password,
    };

    return axios
      .post(`/Authentication/signin`, body, { withCredentials: true })
      .then((res) => {
        const response = res.data;
        const tokenData = jwt_decode(response.token);
        const refreshTokenData = jwt_decode(response.refreshToken);
        return {
          expiresIn: tokenData.exp,
          token: response.token,
          refreshToken: response.refreshToken,
          refreshTokenExpiresIn: refreshTokenData.exp,
          name: tokenData.Name,
          roles: tokenData.Roles,
          tenant: tokenData.Tenant,
          tenantId: tokenData.TenantId,
          firstName: tokenData.FirstName,
          lastName: tokenData.LastName,
          userId: tokenData.UserId,
          IsIntegrationTenant: tokenData.IsIntegrationTenant,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static refreshToken() {
    return axios
      .post(`/Authentication/refreshtoken`, '', { withCredentials: true })
      .then((res) => {
        const response = res.data;
        const tokenData = jwt_decode(response.token);
        return {
          expiresIn: tokenData.exp,
          token: response.token,
          name: tokenData.Name,
          roles: tokenData.Roles,
          tenant: tokenData.Tenant,
          tenantId: tokenData.TenantId,
          firstName: tokenData.FirstName,
          lastName: tokenData.LastName,
          userId: tokenData.UserId,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static logOut() {
    return axios
      .post(`/Authentication/logout`, '', { withCredentials: true })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
}

export default AuthApi;

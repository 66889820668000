import ErrorHandler from './errorResponseHandler';
import Instance from './AxiosInstance';
import moment from 'moment';

export const axios = Instance.assessmentInstance();

class AssessmentApi {
  static getAllAssessments() {
    return axios
      .get('/Assessment')
      .then((res) => {
        const response = res.data;
        let listItem = response.map((assessment) => {
          return this.mappingResponse(assessment);
        });
        let sortedList = listItem.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAllCompletedAssessments() {
    return axios
      .get('/Assessment/completed')
      .then((res) => {
        const response = res.data;
        let listItem = response.map((assessment) => {
          return this.mappingResponse(assessment);
        });
        let sortedList = listItem.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getFilteredAllAssessments(dateFilter, startDate, endDate, status) {
    return axios
      .get(
        `/Assessment/filterPortal?dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}&status=${status}`
      )
      .then((res) => {
        const response = res.data;
        let listItem = response.map((assessment) => {
          return this.mappingResponse(assessment);
        });
        let sortedList = listItem.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAllAssessmentsDynamic(
    dateFilter,
    startDate,
    endDate,
    status,
    textSearch,
    skip,
    limit,
    showAllCompletedData,
    needWpmAssigned
  ) {
    startDate = startDate && moment(startDate).format('YYYY-MM-DD');
    endDate = endDate && moment(endDate).format('YYYY-MM-DD');
    return axios
      .get(
        `/Assessment/dynamicfilter?status=${status}&dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}&skip=${skip}&limit=${limit}&showAllCompletedData=${showAllCompletedData}&needWpmAssigned=${needWpmAssigned}&textSearch=${textSearch}`
      )
      .then((res) => {
        const response = res.data;
        let listItem = response.map((assessment) => {
          return this.mappingResponse(assessment);
        });
        let sortedList = listItem.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAllAssessmentsDynamicCount(
    dateFilter,
    startDate,
    endDate,
    status,
    textSearch,
    showAllCompletedData,
    needWpmAssigned
  ) {
    startDate = startDate && moment(startDate).format('YYYY-MM-DD');
    endDate = endDate && moment(endDate).format('YYYY-MM-DD');
    return axios
      .get(
        `/Assessment/dynamicfiltercount?status=${status}&dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}&skip=${0}&limit=${0}&showAllCompletedData=${showAllCompletedData}&needWpmAssigned=${needWpmAssigned}&textSearch=${textSearch}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /*
   * Get Assessments by using the full text search feature
   */
  static getAssessmentsByTextSearch(textSearch, skip, limit) {
    return axios
      .get(`/Assessment/search?skip=${skip}&limit=${limit}&textSearch=${textSearch}`)
      .then((res) => {
        const response = res.data;
        let listItem = response.map((assessment) => {
          return this.mappingResponse(assessment);
        });
        let sortedList = listItem.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAssessmentsByTextSearchCount(textSearch) {
    return axios
      .get(`/Assessment/searchcount?textSearch=${textSearch}`)
      .then((res) => {
        return res.data.assessmentsCount;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static exportAssessments(dateFilter, startDate, endDate, status, showAllCompletedData, isSentToInx) {
    return axios
      .get(
        `/Assessment/export?status=${status}&dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}&showAllCompletedData=${showAllCompletedData}&isSentToInx=${isSentToInx}`,
        {
          headers: { Accept: 'text/csv' },
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static mappingResponse(assessment) {
    let item = {};
    let traineeInitial = '';
    if (assessment.traineeName) {
      let myStr = assessment.traineeName;
      traineeInitial = myStr.match(/\b(\w)/g);
    }
    item.assessmentUuid = assessment.assessmentUuid;
    item.formId = assessment.formId;
    item.assessmentId = assessment.assessmentId;
    item.assessmentName = assessment.assessmentName;
    item.assessmentData = assessment.assessmentData;
    item.status = assessment.status;
    item.description = assessment.description;
    item.createdAt = assessment.createdAt;
    item.createdBy = assessment.createdBy;
    item.expiryDate = assessment.expiryDate;
    item.version = assessment.version;
    item.assessorId = assessment.assessorId;
    item.assessorName = assessment.assessorName;
    item.traineeName = assessment.traineeName;
    item.approvalName = assessment.approvalName;
    item.approvalDate = assessment.approvalDate;
    item.modifiedDate = assessment.modifiedDate;
    item.completedDate = assessment.completedDate;
    item.traineeInitial = traineeInitial;
    item.isSentToINX = assessment.isSentToINX;
    item.wpmId = assessment.wpmId;
    item.wpmName = assessment.wpmName;
    item.isCompetent = assessment.isCompetent;
    item.isCompetencyAllowed = assessment.isCompetencyAllowed;
    return item;
  }

  static getAssessment(assessmentUuid, separateAssessmentDataImages) {
    return axios
      .get(
        `/Assessment/${assessmentUuid}?detailed=true&separateAssessmentDataImages=${
          separateAssessmentDataImages ? 'true' : 'false'
        }`
      )
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static createAssessment(data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.formDescription,
      assessmentData: JSON.stringify(data.assessmentData),
    };

    return axios
      .post(`/Assessment`, body)
      .then((res) => {
        const response = res.data;
        return {
          assessmentName: response.assessmentName,
          assessmentData: response.assessmentData,
          formId: response.formId,
          description: response.description,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static editAssessment(assessmentId, data) {
    const body = {
      assessmentName: data.assessmentName,
      assessmentData: JSON.stringify(data.assessmentData),
      description: data.formDescription,
    };

    return axios
      .put(`/Assessment/${assessmentId}`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static deleteAssessment(assessmentUuid, data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.formDescription,
      assessmentData: data.assessmentData,
      createdAt: data.createdAt,
      expiryDate: data.expiryDate,
      version: data.version,
      assessorName: data.assessorName,
      traineeName: data.traineeName,
      approvalName: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
      approvalDate: new Date(),
    };

    return axios
      .post(`/Assessment/${assessmentUuid}/delete`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static cancelAssessment(assessmentUuid, data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.formDescription,
      assessmentData: data.assessmentData,
      createdAt: data.createdAt,
      expiryDate: data.expiryDate,
      version: data.version,
      assessorName: data.assessorName,
      traineeName: data.traineeName,
      approvalName: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
      approvalDate: new Date(),
    };

    return axios
      .post(`/Assessment/${assessmentUuid}/cancel`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static approveAssessment(assessmentUuid, data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.formDescription,
      assessmentData: data.assessmentData,
      createdAt: data.createdAt,
      expiryDate: data.expiryDate,
      version: data.version,
      assessorName: data.assessorName,
      traineeName: data.traineeName,
      approvalName: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
      approvalDate: new Date(),
    };

    return axios
      .post(`/Assessment/${assessmentUuid}/approve`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static sendAssessmentToINX(assessmentUuid) {
    return axios
      .get(`/Assessment/${assessmentUuid}/sendtoinx`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static reviseAssessment(assessmentId, data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.description,
      assessmentData: JSON.stringify(data.assessmentData),
      createdAt: data.createdAt,
      expiryDate: data.expiryDate,
      version: data.version,
    };

    return axios
      .post(`/Assessment/${assessmentId}/revise`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static rejectAssessment(assessmentUuid, data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.formDescription,
      assessmentData: data.assessmentData,
      createdAt: data.createdAt,
      expiryDate: data.expiryDate,
      version: data.version,
      assessorName: data.assessorName,
      traineeName: data.traineeName,
      approvalName: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
      approvalDate: new Date(),
    };

    return axios
      .post(`/Assessment/${assessmentUuid}/reject`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static changeAssessmentWPM(assessmentId, data) {
    const body = {
      formId: data.formId,
      assessmentName: data.assessmentName,
      description: data.description,
      createdAt: data.createdAt,
      expiryDate: data.expiryDate,
      version: data.version,
      assessorName: data.assessorName,
      traineeName: data.traineeName,
      approvalName: data.approvalName,
      approvalDate: data.approvalDate,
      wpmId: data.wpmId,
      wpmName: data.wpmName,
    };

    return axios
      .post(`/Assessment/${assessmentId}/changewpm`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  //------COMMENT SERVICE---------

  static getAllAssessmentComment(assessmentUuid) {
    return axios
      .get(`/Assessment/${assessmentUuid}/comment`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static addComment(data) {
    const body = {
      assessmentUuid: data.assessmentUuid,
      assessmentId: data.assessmentId,
      comment: data.comment,
    };

    return axios
      .post(`/Assessment/comment`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static editComment(data) {
    const body = {
      assessmentUuid: data.assessmentUuid,
      assessmentId: data.assessmentId,
      commentId: data.commentId,
      comment: data.comment,
    };

    return axios
      .put(`/Assessment/comment`, body)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static deleteComment(assessmentUuid, commentId) {
    return axios
      .delete(`/Assessment/${assessmentUuid}/comment/${commentId}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static printPDF(tenantId, assessmentUuid, locale) {
    return axios
      .get(`/Assessment/print/${tenantId}/${assessmentUuid}/${locale}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  //----------Dashboard services-----------
  static getAssessmentDashboard(tenantId) {
    return axios({
      method: 'get',
      url: `/Dashboard/assessment/${tenantId}`,
      timeout: 6000,
    })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAssessmentCompletedCount() {
    return axios
      .get(`/Assessment/dashboard/action/NeedApproval`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAssessmentRejectedCount() {
    return axios
      .get(`/Assessment/dashboard/action/rejected`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static resend() {
    return axios
      .post(`/Assessment/resend`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static resendBatch(batch) {
    return axios
      .post(`/Assessment/batch/resend`, { maxAssessmentResend: batch })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /**
   * Fetch assessment sync error data
   * @param Object filters
   *  - name string
   *  - syncType
   *  - status
   *  - dateFilterType
   *  - startDate
   *  - endDate
   * @returns {
   *   result: [], // assessment sync errors result
   *   totalRecord: x, // total number of record
   * }
   */
  static getAssessmentSyncErrors(filters = {}, skip = 0, limit = 10) {
    const queries = [];
    Object.keys(filters).forEach((key) => {
      queries.push(`${key}=${filters[key]}`);
    });

    const queryString = queries.join('&');

    return axios
      .get(`/Assessment/failed-sync?${queryString}&skip=${skip}&limit=${limit}`)
      .then((res) => res.data)
      .catch((err) => {
        return ErrorHandler(err);
      });
  }
}

export default AssessmentApi;

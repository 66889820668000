import ErrorHandler from './errorResponseHandler';
import Instance from './AxiosInstance';
import moment from 'moment';

export const axios = Instance.assessmentInstance();

class FormBuilderApi {
  static getAllForms(status, dateFrom, dateTo) {
    return axios
      .get(`/Form/filter?status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAllFormsDynamic(status, dateFrom, dateTo, textSearch, skip, limit) {
    dateFrom = dateFrom && moment(dateFrom).format('YYYY-MM-DD');
    dateTo = dateTo && moment(dateTo).format('YYYY-MM-DD');

    return axios
      .get(
        `/Form/dynamicfilter?status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&textSearch=${textSearch}`
      )
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getAllFormsDynamicCount(status, dateFrom, dateTo, textSearch) {
    dateFrom = dateFrom && moment(dateFrom).format('YYYY-MM-DD');
    dateTo = dateTo && moment(dateTo).format('YYYY-MM-DD');

    return axios
      .get(
        `/Form/dynamicfiltercount?status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${0}&limit=${0}&textSearch=${textSearch}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  /*
   * Get Forms by using the full text search feature
   */
  static getFormsByTextSearch(skip, limit, textSearch) {
    return axios
      .get(`/Form/search?skip=${skip}&limit=${limit}&textSearch=${textSearch}`)
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          // sort list from newest to oldest
          return a.modifiedDate < b.modifiedDate ? 1 : b.modifiedDate < a.modifiedDate ? -1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getFormsByTextSearchCount(textSearch) {
    return axios
      .get(`/Form/searchcount?textSearch=${textSearch}`)
      .then((res) => {
        return res.data.formsCount;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static exportForms(status, dateFrom, dateTo) {
    return axios
      .get(`/Form/export?status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {
        headers: { Accept: 'text/csv' },
      })
      .then((res) => res.data)
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getForm(formUuid, separateFormDataImages) {
    return axios
      .get(`/Form/${formUuid}?separateFormDataImages=${separateFormDataImages}`)
      .then((res) => {
        let response = res.data;
        const recheckFormData = this.checkDateLocale(response.formData);
        response.formData = recheckFormData;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static createForm(data) {
    const body = {
      documentNumber: data.documentNumber,
      formName: data.formName,
      description: data.formDescription,
      formData: JSON.stringify(data.formData),
      assessorDeclaration: data.assessorDeclaration,
      traineeDeclaration: data.traineeDeclaration,
      smeDeclaration: data.smeDeclaration,
      automaticallySaved: data.automaticallySaved,
      smeSignIsRequired: data.smeSignIsRequired,
      wpmSignIsRequired: data.wpmSignIsRequired,
      additionalCompetencyHeading: data.additionalCompetencyHeading,
      isCompetencyAllowed: data.isCompetencyAllowed,
      competencies: data.competencies,
      additionalCompetencies: data.additionalCompetencies,
      dataSchemaVersion: 2,
    };

    return axios
      .post(`/Form`, body)
      .then((res) => {
        const response = res.data;
        return {
          id: response.formId,
          formId: response.formId,
          formUuid: response.formUuid,
          documentNumber: response.documentNumber,
          formName: response.formName,
          formData: response.formData,
          status: response.status,
          description: response.description,
          assessorDeclaration: response.assessorDeclaration,
          traineeDeclaration: response.traineeDeclaration,
          smeDeclaration: response.smeDeclaration,
          smeSignIsRequired: response.smeSignIsRequired,
          wpmSignIsRequired: response.wpmSignIsRequired,
          automaticallySaved: response.automaticallySaved,
          additionalCompetencyHeading: response.additionalCompetencyHeading,
          isCompetencyAllowed: response.isCompetencyAllowed,
          competencies: response.competencies,
          additionalCompetencies: response.additionalCompetencies,
          dataSchemaVersion: response.dataSchemaVersion,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static async uploadFormImage(formuUuid, formId, formDataId, srcData) {
    if (srcData && srcData.toLowerCase().includes('data:image')) {
      const formData = new FormData();
      formData.append('image64Base', srcData);
      formData.append('formId', formId);

      return axios
        .post(`/Form/${formuUuid}/image/${formDataId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw ErrorHandler(err);
        });
    }
  }

  static async uploadFormImages(formuUuid, formId, uploadedImages) {
    return Promise.all(
      uploadedImages.forEach((item) => {
        this.uploadFormImage(formuUuid, formId, item.formDataId, item.srcData);
      })
    ).then(() => {
      console.log('All file has been uploaded');
    });
  }

  static editForm(formuUuid, data) {
    const body = {
      documentNumber: data.documentNumber,
      formName: data.formName,
      formUuid: data.formUuid,
      formData: JSON.stringify(data.formData),
      description: data.formDescription,
      version: data.version,
      assessorDeclaration: data.assessorDeclaration,
      traineeDeclaration: data.traineeDeclaration,
      smeDeclaration: data.smeDeclaration,
      automaticallySaved: data.automaticallySaved,
      smeSignIsRequired: data.smeSignIsRequired,
      wpmSignIsRequired: data.wpmSignIsRequired,
      additionalCompetencyHeading: data.additionalCompetencyHeading,
      isCompetencyAllowed: data.isCompetencyAllowed,
      competencies: data.competencies,
      additionalCompetencies: data.additionalCompetencies,
      dataSchemaVersion: 2,
    };

    return axios
      .put(`/Form/${body.formUuid}`, body)
      .then((res) => {
        const response = res.data;

        return {
          formId: response.formId,
          formUuid: response.formUuid,
          documentNumber: response.documentNumber,
          formName: response.formName,
          formData: response.formData,
          status: response.status,
          description: response.description,
          version: response.version,
          assessorDeclaration: response.assessorDeclaration,
          traineeDeclaration: response.traineeDeclaration,
          smeDeclaration: response.smeDeclaration,
          wpmSignIsRequired: response.wpmSignIsRequired,
          automaticallySaved: response.automaticallySaved,
          additionalCompetencyHeading: response.additionalCompetencyHeading,
          isCompetencyAllowed: response.isCompetencyAllowed,
          competencies: response.competencies,
          additionalCompetencies: response.additionalCompetencies,
          dataSchemaVersion: response.dataSchemaVersion,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static cloneForm(data) {
    const body = {
      id: data.formId,
      formUuid: data.formUuid,
      newName: data.formName,
      newDocumentNumber: data.documentNumber,
    };

    return axios
      .post(`/Form/clone`, body)
      .then((res) => {
        const response = res.data;

        return {
          documentNumber: response.documentNumber,
          formId: response.formId,
          formUuid: response.formUuid,
          formName: response.formName,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static publishForm(formUuid, version) {
    return axios
      .post(`/Form/${formUuid}/publish/${version}`, null)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static activateForm(formUuid, version, active) {
    return axios
      .post(`/Form/${formUuid}/${active ? 'deactivate' : 'reactivate'}/${version}`, null)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static deleteForm(formUuid) {
    return axios
      .delete(`/Form/${formUuid}`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getFormsNumber(tenantId) {
    return axios
      .get(`/Form/numbers/${tenantId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getLockedStatus(formuUuid) {
    return axios
      .post(`/Form/${formuUuid}/lock`, null)
      .then((res) => {
        const response = res.data;
        const firstName = response.firstName !== '-' ? response.firstName : '';
        const lastName = response.lastName !== '-' ? response.lastName : '';
        return {
          userName: response.userName,
          startLock: response.startLock,
          userFullName: firstName + ' ' + lastName,
        };
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static updateLockedStatus(formuUuid) {
    return axios
      .put(`/Form/${formuUuid}/relock`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static deleteLockedStatus(formuUuid) {
    return axios
      .delete(`/Form/${formuUuid}/unlock`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static forceUnlockStatus(formuUuid) {
    return axios
      .delete(`/Form/${formuUuid}/forceUnlock`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getFormsFormData(formUuid) {
    return axios
      .get(`/Form/${formUuid}/formData`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  // change datepicker format based on browser locale settings
  static checkDateLocale(formData) {
    let formDataJson = JSON.parse(formData);
    for (let item of formDataJson) {
      if (item && item.element === 'DatePicker') {
        item.dateFormat = this.getLocalFormat();
      }
    }
    return JSON.stringify(formDataJson);
  }

  static getLocalFormat() {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    var localeData = moment.localeData(locale);
    var tempFormat = localeData.longDateFormat('L').toLowerCase();
    var format = tempFormat.replace('mm', 'MM');
    return format;
  }

  //competency
  static getCompetencyList() {
    return axios
      .get(`/Competency`)
      .then((res) => {
        const response = res.data;
        let sortedList = response.sort((a, b) => {
          return a.elementName < b.elementName ? -1 : b.elementName < a.elementName ? 1 : 0;
        });
        sortedList = sortedList.sort((a, b) => {
          return a.competencyName < b.competencyName ? -1 : b.competencyName < a.competencyName ? 1 : 0;
        });
        return sortedList;
      })
      .catch((err) => {
        return ErrorHandler(err);
      });
  }

  static exportCompetencyList() {
    return axios
      .get(`/Competency`, {
        headers: {
          Accept: 'text/csv',
        },
      })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static bulkInsertCompetency(data) {
    const body = [];

    data.forEach((element) => {
      let reqData = {};
      reqData.active = element.active;
      reqData.competencyCode = element.competencyCode;
      reqData.competencyName = element.competencyName;
      body.push(reqData);
    });

    return axios
      .post(`/Competency/bulkinsert`, body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static syncCompetency() {
    return axios
      .post(`/ManualSync/competency`, {})
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static getSyncCompetencyStatus() {
    return axios
      .get(`/ManualSync/competency`)
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  //----------Dashboard services-----------
  static getFormDashboard(tenantId) {
    return axios({
      method: 'get',
      url: `/Dashboard/form/${tenantId}`,
      timeout: 6000,
    })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }

  static isDocumentNumberExisted(documentNumber) {
    return axios({
      method: 'get',
      url: `/Form/IsDocumentNumberExisted?documentNumber=${documentNumber}`,
    })
      .then((res) => {
        const response = res.data;
        return response;
      })
      .catch((err) => {
        throw ErrorHandler(err);
      });
  }
}

export default FormBuilderApi;
